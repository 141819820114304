import React from 'react';
import db from '../db.js';
import l from '../language.js';
import Loader from '../Browse/loader.js';
import './interactionsPage.css';
import InteractionContainer from './interactionContainer.jsx';

// Chat data is routed from InteractionsPage

export default class InteractionsPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            newConversationSuggestions: [],
            existingConversations: [],
            nicknames: props.nicknames,
            f_createBlankChatForUser: props.f_createBlankChatForUser,
            chats: props.chats,
            previousData: [], // Dictionary of previous data, because changes can't be detecte with prevProps or prevState.
        }
    }

    componentDidMount(){
        this.getLikesWithNoChats();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        // console.log(prevProps.chats, prevState.chats, snapshot, this.props.chats);
        // console.log('data coming in');
        // this.props.chats.forEach(chat => {console.log(1, chat.id, chat.messages.length)});
        const previousData = [];
        for(var chat of this.props.chats){
            previousData.push(chat.messages.length);
        }
        // this.state.chats.forEach(chat => {console.log(2, chat.id, chat.messages.length)});
        // prevProps.chats.forEach(chat => {console.log(3, chat.id, chat.messages.length)});
        // prevState.chats.forEach(chat => {console.log(4, chat.id, chat.messages.length)});
        var b = false;// = this.checkMsgDifference();
        
        if(this.state.previousData.length !== previousData.length){
            // console.log('1', this.state.previousData.length, previousData.length);
            b = true;
        } else if(this.arrayIsDifferent(previousData)){
            // console.log('2');
            b = true;
        }
        
        // console.log(b);
        // if(prevProps.chats !== this.props.chats || b) {
        if(b && previousData.length){

            // if(this.state.chats[0] && this.state.chats[0].messages){
            //     console.log('updating chats: ', this.state.chats[0].messages.length);
            // }
            this.setState({
                chats: this.props.chats,
                previousData: previousData,
            }, async ()=>{
                //await 
                this.getLikesWithNoChats();
            });
        }
    }

    arrayIsDifferent = (previousData) => {
        for(var i=0;i<previousData.length;i++){
            if(previousData[i] !== this.state.previousData[i]){
                return true;
            }
        }
        return false;
    }

    checkMsgDifference = () => {
        if(this.state.chats.length !== this.props.chats.length)
            return true;
        // console.log(this.state.chats);
        for(var i=0;i<this.state.chats.length;i++){
            // console.log(this.state.chats[i].messages.length, this.props.chats[i].messages.length);
            if(this.state.chats[i].messages.length !== this.props.chats[i].messages.length){
                return true;
            }
        }
        return false;
    }

    // Identify which likes are not linked to chats, and suggest them as conversation-starters
    //async 
    getLikesWithNoChats(){
        this.setState({
            loading: true,
        });
        const participants = {};
        // Convert chats to array of local participants
        // this.state.chats.forEach(chat => {console.log(chat.id, chat.messages.length)});
        for(var chat of this.state.chats){
            var currentUid = chat.participants.filter(p => {return p !== this.props.uid})[0];
            console.log('currentUid: ', currentUid, chat.messages.length);
            participants[
                currentUid
            ] = {...chat, currentUid: currentUid };
        }
        // Check if the chats exist here
        // console.log(this.props.myLikes);
        // console.log(Object.keys(participants));
        const newConversationSuggestions = [], existingConversations = [];
        const promises = [];
        // const concatenatedUniq = Array.from(new Set(Object.keys(this.props.myLikes).concat(Object.keys(participants))));
        // console.log(concatenatedUniq);
        for (var participant of Object.keys(participants)){
            // console.log(participant);
        // for (var like of concatenatedUniq){
        // for(var like of Object.keys(this.props.myLikes)){
            // Check if the other participants is the same
            // Make sure messages already exist

            // This doesn't work with likes, because the additional data is deleted, so just do this for now.
            /*if(this.props.myLikes[participant] && this.calcNumberOfChats(participants[participant]) === 0){
            // if(!participants[like] || this.calcNumberOfChats(participants[like]) === 0){
                // Add new chat id
                console.log(participants[participant]);
                promises.push(
                    db.getContactFieldsToShow(participant).then(personalDetails => {
                        const newChat = this.props.f_createBlankChatForUser(participant, personalDetails);
                        // console.log('adding to newConversationSuggestions')
                        newConversationSuggestions.push({...newChat, currentUid: participant });
                        // return;
                    })
                );
            } else {*/
                // console.log('adding to existingConversations');
                // Add to existing chats container
                existingConversations.push(participants[participant]);
            // }
        };

        if(existingConversations.length % 2 != 0){
            // Add in a filler div
            existingConversations.push({
                participants: [],
                messages: [],
                personalDetails: {},
                filler: true,
            });
        }

        Promise.all(promises).then(()=>{
            this.setState({
                newConversationSuggestions: newConversationSuggestions,
                existingConversations: existingConversations,
                loading: false,
            });
        })
        // console.log('newConversationSuggestions: ', newConversationSuggestions.length);
        // console.log('existingConversations: ', existingConversations.length);
        // console.log('state.existingConversations: ', this.state.existingConversations.length);
        // console.log('state.newConversationSuggestions: ', this.state.newConversationSuggestions.length);
        // if(newConversationSuggestions.length !== this.state.newConversationSuggestions.length
        //     || existingConversations.length !== this.state.existingConversations.length
        //     || (this.state.newConversationSuggestions.length === 0
        //     && this.state.existingConversations.length === 0)
        // ){
            
            // return {
            //     newConversationSuggestions: newConversationSuggestions,
            //     existingConversations: existingConversations,
            // }
        // }
    }

    calcNumberOfChats(chat){
        var totalMessages = 0;
        for(var messageDay of chat.messages){
            totalMessages += messageDay.length;
        }
        console.log("totalMessages: ", chat.currentUid, totalMessages);
        return totalMessages;
    }

    render(){
        // console.log("this.props.nicknames: ", this.props.nicknames);
        // console.log('this.props.match.params.userId: ', this.props.match.params.userId);
        // console.log(this.props.nicknames[this.props.match.params.userId]);
        // console.log(this.state.newConversationSuggestions.length, this.state.existingConversations.length);
        return(
            
            <div className="ip-page">
                {
                    this.state.loading 
                    ? <Loader />
                    : <div>
                        { this.state.newConversationSuggestions.length 
                            ? <div className="g-mg-8">
                                <h2>{l.get('IP_NEW_CONVERSATIONS')}</h2>
                            </div>
                            : <div></div>
                        }
                        <div className="interactionContainerHolder" /* Interaction Container Holder */>
                        {
                            //Object.values(this.state.newConversationSuggestions).map((chat, i) => (
                            this.state.newConversationSuggestions.map((chat, i) => (
                                <div key={i}>
                                    {/* {console.log(chat)} */}
                                    <InteractionContainer 
                                        chat={chat} 
                                        uid={this.props.uid}
                                        nickname={this.props.nicknames[chat.currentUid]}
                                        unreadIndexes={this.props.unreadIndexes}
                                    />
                                </div>
                            ))
                        }
                        </div>
                        {/* Liked profiles with no chats */}
                        { this.state.existingConversations.length 
                            ? <div className="g-mg-8">
                                <h2>{l.get('IP_CONTINUE_CHAT')}</h2>
                            </div>
                            : <div></div>
                        }
                        {/* Profiles with ongoing chats */}
                        <div className="interactionContainerHolder" /* Interaction Container Holder */>
                        {
                            this.state.existingConversations.map((chat,i) => (
                                <div key={i}>
                                    <InteractionContainer 
                                        chat={chat} 
                                        uid={this.props.uid}
                                        currentUid={chat.currentUid}
                                        nickname={this.props.nicknames[chat.currentUid]}
                                        unreadIndexes={this.props.unreadIndexes}
                                    />
                                </div>
                            ))
                        }
                        </div>
                        {/* Suggest profiles that may be good */}
                    </div>
                }
            </div>
        );
    }
}
