import React from 'react';
import EditBtn from './editBtn.jsx';
import AddBtn from './addBtn.jsx';
import './references.css';
import l from '../language.js';

export default class References extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            references: props.references,
        }
    }

    getMonthNameFromNumber(number){
        switch(parseInt(number)){
            case 0: return "Jan";
            case 1: return "Feb";
            case 2: return "Mar";
            case 3: return "Apr";
            case 4: return "May";
            case 5: return "Jun";
            case 6: return "Jul";
            case 7: return "Aug";
            case 8: return "Sep";
            case 9: return "Oct";
            case 10: return "Nov";
            case 11: return "Dec";
            default: return "";
        }
    }

    timestampToDate(timestamp){
        //console.log("timestamp: ", timestamp);
        var d = new Date(parseInt(timestamp));
        //console.log("new date: ", d);   
        return d.getDate() + " " + this.getMonthNameFromNumber(d.getMonth()) + " " + d.getFullYear();
    }

    addCallback = () => {
		this.props.fn_showModal(true, "Reference", "Ask a friend to give you a reference");
    }

    editCallback = (data) => {
        this.props.fn_showEditModal('References', undefined, undefined, data, {hideSaveBtn: true} );
    }

    render(){
        return(
            <div>
                {
                    this.props.ownPage && <AddBtn callback={this.addCallback} />
                }
                {
                    this.props.references.map((r, index) => (
                        <div key={"ref-" + index} className="btn-hoverable g-relative r-reference-container">
                            {
                                this.props.ownPage && <EditBtn callback={this.editCallback} data={r} />
                            }
                            <span className="r-timestamp r-grey">{this.timestampToDate(r.id)}</span>
                            {   this.props.ownPage 
                                ? <div>
                                {   r.pending && r.pending.length
                                    ? <span style={{}}>{l.get('REF_PENDING')}
                                            <span style={{color:"#0073b1"}}> {r.pending} </span>
                                        </span>
                                    : <p className="r-msg" style={{maxWidth:"92%"}}>{r.msg}</p>
                                }
                                {
                                    r.pending && r.pending.length 
                                    ? <p style={{color:"rgba(0,0,0,.5)",marginTop:"4px",fontStyle:"italic"}}>{l.get('REF_ONLY_VISIBLE_2_U')}</p>
                                    : ""
                                }
                                </div>
                                : r.pending && r.pending.length 
                                ? <div className="r-grey">Request Pending</div>
                                : <p className="r-msg" style={{maxWidth:"92%"}}>{r.msg}</p>
                            }
                            
                            
                            {/* <span className="r-grey"> {r.verified ? " " : ""} </span> */}
                            
                            {/* <span className={(r.verified ? "r-green " : "") + "r-grey r-verified"}>{r.verified ? "Verified" : ""}</span> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

