import React from 'react';
import './chatQuotes.css';
import l from '../language.js';
import helpers from '../helpers.js';
import DeleteBtn from './deleteBtn.jsx';
import db from '../db.js';

export default class ChatQuotes extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: props.uid,
            chatQuotes: props.chatQuotes
        }
    }

    deleteCallback = (data) => {
        db.deleteChatQuote(this.props.uid, data.id).then(()=>{
            this.props.f_removeChatQuote(data.id);
        })
    }

    render(){
        //console.log(this.props.chatQuotes)
        return(
            <div>
                {
                    !Object.keys(this.state.chatQuotes).length
                    ? <div className="cq-empty-txt">
                        {l.get('CHAT_QUOTES_EMPTY')}
                    </div>
                    : Object.keys(this.state.chatQuotes).map((cq, i) => (
                        <div key={"cq-"+i} className="cq-row g-relative btn-hoverable">
                            {
                                this.props.ownPage && <DeleteBtn callback={this.deleteCallback} data={this.props.chatQuotes[cq]} />
                            }
                            <div>
                                <span className="cq-row-txt">{this.state.chatQuotes[cq].value}</span>
                                <span className="cq-row-time"> {helpers.convertTimestampToDate(cq) + ', ' + helpers.convertTimestampToYear(cq)}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}