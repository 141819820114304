import React from 'react';
import '../Edits/editModal.css';
import close from '../Assets/close-black.svg';
import q from '../FormsManager/question.js';

export default class PersonalityScoreModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            answers: [],
            pleaseFillInAll: props.pleaseFillInAll
        }
    }

    save = (totalQuestionsNumber) => {
        this.props.f_savePersonalityAnswers(totalQuestionsNumber);
    }

    render(){
        return(
            <div className="m-modal-containers">
                <div className="m-modal-wormhole" style={{maxHeight:"calc(100vh - 120px)"}}>
                    <div className="m-modal-content" style={{maxHeight:"calc(100vh - 120px)"}}>
                        <div className="m-modal-title">
                            Personality Type
                            <img src={close} alt="" onClick={()=>this.props.f_closePersonalityModal()} className="m-modal-close" />
                        </div>
                        <div id="m-modal-body-to-read" className="m-modal-body">
                            <p className="g-red"><b>NOTE:</b> There are over <b>100</b> questions to answer, your progress cannot be saved halfway through and <b>every</b> question must be answered. Please put aside approximately <b>15 minutes</b> to get your unique personality profile</p>
                            <p className="g-red"><b>NOTE:</b> Completing & saving will override your current personality profile</p>
                            <PersonalityQuestions q={q.personality}/>
                        </div>
                        <div className="m-modal-end">
                            <div></div>
                            <div>
                                {
                                    this.props.pleaseFillInAll 
                                    ? <label className="g-red">Please fill in all of the answers before proceeding</label>
                                    : ""
                                }   
                            </div>
                            <button onClick={()=>{this.save(q.personality.length)}}>Save</button>
                        </div>
                    </div>
                </div>
                <div className="m-modal-background"></div>
            </div>
        );
    }

}

class PersonalityQuestions extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    render(){
        return <div>
            {
                this.props.q.map((qq, index) => (
                    <div key={"qp-" + index} className="g-mg-b-20">
                        <PersonalityQuestion q={qq} />
                    </div>
                ))
            }
        </div>
    }
}

class PersonalityQuestion extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            q: props.q,
            options: ["False", "Somewhat false", "Neither true or false", "Somewhat true", "True"],
            currentSelectedValue: undefined 
        }
    }

    componentDidMount(){
        this.setState({
            currentSelectedValue: undefined, //TODO uncomment this
            //currentSelectedValue: "Neither true or false" //TODO comment this out, 
        })
    }

    onChange = (e) => {
        this.setState({
            currentSelectedValue: e.target.value
        })
    }

    render(){
        return(
            <div>
                <label>{this.state.q.question}</label>
                {
                    this.state.options.map((o, index) => (
                        <div key={"qp-" + this.state.q.question + "-" + index} className="g-inlineblock g-mg-l-20">
                            <input id={"qp-" + this.state.q.question + "-" + o}
                                type="radio" 
                                name={this.state.q.type + "|" + this.state.q.question} 
                                value={o}
                                className={(this.state.currentSelectedValue || "").includes(o) ? "m-modal-toRead" : ""}
                                //defaultChecked={index === 3} //TODO comment this out, only for debugging to quickly fill in everything  //No default to force everything to be filled in
                                onChange={this.onChange} 
                                />
                            <label htmlFor={"qp-" + this.state.q.question + "-" + o}>{o}</label>
                        </div>
                    ))
                }
            </div>
        );
    }
}