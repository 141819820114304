import React from 'react';
import close from '../Assets/close-black.svg';
import './deleteBtn.css';

export default class DeleteBtn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            src: props.src
        }
    }

    render(){ 
        return(
            <div onClick={()=>this.props.callback(this.props.data)}>
                <div className="deleteBtnContainer" >
                    <img src={this.props.src || close} alt="" />
                </div>
            </div>
        );
    }
}
