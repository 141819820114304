import React from 'react';
import EditBtnWhole from './editBtnWhole.jsx';
import AddBtn from './addBtn.jsx';
import './lookingFor.css';
import star from '../Assets/star.svg';
import edit from '../Assets/edit.svg';
import l from '../language.js';

export default class LookingFor extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            lookingFor: props.lookingFor
        }
    }

    componentDidUpdate(){
        if(this.state.lookingFor !== this.props.lookingFor){
            this.setState({
                lookingFor: this.props.lookingFor
            })
        }
    }

    addCallback = () => {
		this.props.fn_showModal(l.get('LF_TITLE'), undefined, undefined, this.props.lookingFor);
    }

    editCallback = (data) => {
        this.props.fn_showModal(l.get('LF_TITLE'), undefined, undefined, data);
    }

    render(){
        //console.log(this.props.lookingFor);

        return(
            <div>
                {
                    this.props.ownPage ?
                        this.props.lookingFor.length ?
                            <EditBtnWhole callback={this.addCallback} data={this.props.lookingFor[0]} src={edit} top={"0px"} />
                            : <AddBtn callback={this.addCallback} data={this.props.lookingFor[0]} />
                        : ""
                }
                {
                    Object.keys(this.props.lookingFor).map((lf, index) => (
                        <div key={"lf-" + index}>
                            { index > 0 && <hr /> }
                            <LookingForRows 
                                lf={this.props.lookingFor[lf]}
                                ownPage={this.ownPage} />
                        </div>
                    ))
                }
            </div>
        );
    }
}

function LookingForRows(props){
    //console.log(props.lf);
    return <div>
        {
            props.lf.gender &&
            <div className="lf-row">
                <span>{props.lf.gender}</span>
            </div>
        }
        { props.lf.Height && props.lf.heightType &&
            <div className="lf-row">
                <span>{props.lf.heightType} {props.lf.Height}cm</span>
            </div>
        }
        {
            props.lf["ageRange-to"] &&
            <div className="lf-row">
                <span>
                    {props.lf["ageRange-to"] ? "From " : ""}
                    {props.lf["ageRange-from"]}
                    {props.lf["ageRange-to"] ? " to " + props.lf["ageRange-to"] + " years old": ""}
                </span>
            </div>
        }
        {/* {
            props.lf.cultureType &&
            <div className="lf-row g-flex">
                <div 
                    className="lf-colorSquare-parent"
                    style={{
                        backgroundColor:`${props.lf.cultureType}`
                    }}>
                </div>
            </div>
        } */}
        {
            props.lf.rating && <div className="lf-row g-flex">
                <span style={{display:"inline-block"}} className="g-mg-r-10">Looks {props.lf.rating} / 10</span>
                <div style={{marginTop:"-1px"}}>{
                    [...Array(Number(props.lf.rating))].map((s, index) => (
                        <div key={index} className="g-inlineblock">
                            <img src={star} 
                                alt="" 
                                className={"editStarRating-img star-smaller"} />
                        </div>
                    ))
                }</div>
                {/* <span style={{display:"inline-block"}} className="g-mg-r-10"><b>/10</b></span> */}
            </div>
        }
        {
            props.lf.wealthType && 
            <div className="lf-row">
                <span>Has {props.lf.wealthType} Money</span>
            </div>
        }
        {
            props.lf["Special Requirements"] &&
            <div className="lf-row" style={{borderBottom:"none"}}>
                <span>
                    {/* <div className="lf-listSquare"></div> */}
                    {props.lf["Special Requirements"].substring(0, 1).toUpperCase() + props.lf["Special Requirements"].substring(1)}
                </span>
            </div>
        }
    </div>
}