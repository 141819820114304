import React from 'react';
import axios from 'axios';
import close from '../Assets/close-black.svg';
import '../Edits/editModal.css';
import Loader from '../Browse/loader.js';
import config from '../config.js';
import db from '../db';

export default class ReferenceModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
			title: props.title,
			headerText: props.headerText,
			f_saveCallback: props.f_saveCallback,
			fn_updateReferencePopup: props.fn_updateReferencePopup,
			uid: props.uid
        }
    }

    componentDidMount(){
        //document.getElementById("referenceModal").style.display = "flex!important";
	}
	
	sendEmail = () => {

		this.setState({
			loading: true
		});

		switch(this.state.title){
			case "Reference": {
				this.sendReferenceEmail();
				break;
			}
			case "ReferProfile2Friend":{
				this.sendReference2Friend();
				break;
			}
			default: break;
		}
	}

	sendReference2Friend = () => {
		const emailAddress = document.getElementById("referenceModalInput").value.toString();
		const displayName = localStorage.getItem("displayName") || global.authUser;
		const uid = window.location.href;
		const myUid = this.props.uid || localStorage.getItem("uid") || global.authUid;
		//global.print("Posting reference email");
		//console.log("Posting reference email");
		axios({
			method: 'POST', //'POST' // Not working due to CORS
			url: `${config.SERVER_ADDRESS}/referProfile2Friend`,//?email=${emailAddress}&name=${displayName}&uid=${uid}`,
			data: {
			  email: emailAddress,
			  myName: displayName,
			  uid: uid
			}
		})
		.then(res => {
			//console.log("Reference Request Response: ", res);
			
			db.createRefer2Friend(myUid, emailAddress, uid);

			this.setState({
				loading: false
			});
			this.state.fn_updateReferencePopup(false);
		}, (error) => {
			console.error("Error posting reference email: ", error);
		})
	}

	sendReferenceEmail = () => {
		const emailAddress = document.getElementById("referenceModalInput").value.toString();
		const myEmail = localStorage.getItem("email") || global.authEmail;
		const displayName = localStorage.getItem("displayName") || global.authUser;
		const uid = this.props.uid || localStorage.getItem("uid") || global.authUid;

		axios({
			method: 'POST', //'POST' // Not working due to CORS
			url: `${config.SERVER_ADDRESS}/referenceEmail/`,//?email=${emailAddress}&name=${displayName}&uid=${uid}`,
			data: {
			  email: emailAddress,
			  name: displayName,
			  uid: uid
			}
		})
		.then(res => {
			//console.log("Reference Request Response: ", res);
			//TODO put token generation serverside
			const referenceId = Date.now().toString();
			db.createReferenceToken(uid, myEmail, displayName, res.data.token, referenceId, emailAddress);
			this.state.f_saveCallback("References", { 
				str1: "Pending reference request sent to ", 
				email: emailAddress,
				referenceId: referenceId
			});
			this.setState({
				loading: false
			});
			this.state.fn_updateReferencePopup(false);
		}, (error) => {
			console.error("Error posting reference email");
		})
	}

    render(){
        return(
            <div ref={this.props.parentRef} id="referenceModal" className="m-modal-containers">
				<div className="m-modal-wormhole">
					{ 	this.state.loading 
						? <Loader marginTop={10} />
						: <div className={(this.props.showLoading ? "m-modal-opaque " : "") + "m-modal-content"}>
							<div className="m-modal-title">
								{this.state.headerText}
								<img src={close} alt="" onClick={()=>this.props.fn_updateReferencePopup(false)} className="m-modal-close" />
							</div>
							<div id="m-modal-body-to-read" className="m-modal-body">
								<input type="email" id="referenceModalInput" placeholder="friend@example.com"/>
							</div>
							<div className="m-modal-end">
								<div></div>
								<button onClick={()=>{this.sendEmail()}}>Send</button>
							</div>
						</div>
					}
				</div>
				<div className="m-modal-background"></div>
			</div>
        );
    }
}