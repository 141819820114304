import React from 'react';
import './searchFilters.css';
import l from '../language.js';
import AgeFilter from './Filters/ageFilter.jsx';
import GenderFilter from './Filters/genderFilter.jsx';
//import RatingFilter from './Filters/ratingFilter.jsx';
// import RequestsFilter from './Filters/requestsFilter.jsx';
// import AcceptedFilter from './Filters/acceptedFilter.jsx';
// import RejectedFilter from './Filters/rejectedFilter.jsx';
//import AgeFilter_Them from './Filters/ageFilter_them.jsx';
//import GenderFilter_Them from './Filters/genderFilter_them.jsx';

export default class SearchFilters extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showingFilterDiv: props.showingSearchFilters,
            f_searchWithFilters: props.f_searchWithFilters,
            default: props.default || {},
            showAgeFilter: (localStorage.getItem('sf-age-from') || localStorage.getItem('sf-age-to')) !== null,
            showGenderFilter: localStorage.getItem('sf-gender') !== null
        }
    }

    searchWithFilter = (searchType) => {
        switch(searchType){
            case 'Age_int': {
                this.setState({ showAgeFilter: true });
                break;
            }
            case 'Gender': {
                this.setState({ showGenderFilter: true });
                break;
            }
            case 'Rating': {
                this.setState({ showRatingFilter: true });
                break;
            }
            case 'Requests': {
                this.setState({ showRequestsFilter: true });
                break;
            }
            case 'Accepted': {
                this.setState({ showAcceptedFilter: true });
                break;
            }
            case 'Rejected': {
                this.setState({ showRejectedFilter: true });
                break;
            }
            case 'showAgeFilter_Them': {
                this.setState({ showAgeFilter_Them: true });
                break;
            }
            case 'showGenderFilter_Them': {
                this.setState({ showGenderFilter_Them: true });
                break;
            }
            default: break;
        }
        this.props.f_updateFilters(true);
    }

    f_closePressed = (type) => {
        console.log(`show${type}`);
        this.setState({
            [`show${type}`]: false//!this.state[`show${type}`]
        }, ()=>{
            //console.log(this.state[`show${type}`]);
            if(type === 'AgeFilter'){
                localStorage.removeItem('sf-age-to');
                localStorage.removeItem('sf-age-from');
            } else if(type === 'GenderFilter'){
                localStorage.removeItem('sf-gender');
            }
            this.props.f_searchWithFilters();
        })
    }

    render(){
        return(
            <div className="sf-no-close" //style={{borderBottom:"1px solid rgba(0,0,0,.1)"}}
            >
                <div className="searchfilters-active">
                    { this.state.showGenderFilter && <GenderFilter default={localStorage.getItem('sf-gender')} f_closePressed={this.f_closePressed} f_searchWithFilters={this.props.f_searchWithFilters} /> }
                    { this.state.showAgeFilter && <AgeFilter default={{to: localStorage.getItem('sf-age-to'), from: localStorage.getItem('sf-age-from')}} f_closePressed={this.f_closePressed} f_searchWithFilters={this.props.f_searchWithFilters} /> }
                    {/* { this.state.showRatingFilter && <RatingFilter default={this.state.default['Rating']} f_closePressed={this.f_closePressed} /> } */}
                    {/* { this.state.showRequestsFilter && <RequestsFilter default={this.state.default['Requests']} f_closePressed={this.f_closePressed} f_searchWithFilters={this.props.f_searchWithFilters} /> }
                    { this.state.showAcceptedFilter && <AcceptedFilter default={this.state.default['Accepted']} f_closePressed={this.f_closePressed} f_searchWithFilters={this.props.f_searchWithFilters} /> }
                    { this.state.showRejectedFilter && <RejectedFilter default={this.state.default['Rejected']} f_closePressed={this.f_closePressed} f_searchWithFilters={this.props.f_searchWithFilters} /> } */}
                    {/* {
                        (this.state.showAgeFilter_Them || this.state.showGenderFilter_Them) &&
                        <div className="sf-whatTheyAreLookingFor">
                            <p>What They Are Looking For</p>
                        </div>
                    } */}
                    {/* { this.state.showGenderFilter_Them && <GenderFilter_Them default={this.state.default['lf_gender']} f_closePressed={this.f_closePressed} /> } */}
                    {/* { this.state.showAgeFilter_Them && <AgeFilter_Them default={this.state.default['lf_age']} f_closePressed={this.f_closePressed} /> } */}
                </div>
                {
                    // TODO put this in order based on when it was selected (Use push mechanism and an array)
                    (!this.state.showGenderFilter || !this.state.showAgeFilter) &&                    
                    <div className="searchFilters-container sf-no-close">
                        { !this.state.showGenderFilter && 
                            <div onClick={()=>{this.searchWithFilter('Gender')}}>
                                <label>{l.get('SF_GENDER')}</label>
                            </div>
                        }
                        { !this.state.showAgeFilter && 
                            <div onClick={()=>{this.searchWithFilter('Age_int')}}>
                                <label>{l.get('SF_AGE')}</label>        
                            </div>
                        }
                        {/* <div onClick={()=>{this.searchWithFilter('lf_ethnicity')}}><label >Ethnicity</label></div> */}
                        {/* <div onClick={()=>{this.searchWithFilter('lf_height')}}><label>Height</label></div> */}
                        {/* <div onClick={()=>{this.searchWithFilter('Rating')}}>
                            { !this.state.showRatingFilter && <label>Rating</label> }
                        </div> */}
                        {/* <div onClick={()=>{this.searchWithFilter('lf_wealth')}}><label>Wealth</label></div> */}
                        {/* <div onClick={()=>{this.searchWithFilter('Requests')}}>
                            { !this.state.showRequestsFilter && <label>{l.get('SF_Requests')}</label> }
                        </div>
                        <div onClick={()=>{this.searchWithFilter('Accepted')}}>
                            { !this.state.showAcceptedFilter && <label>Accepted</label> } 
                        </div>
                        <div onClick={()=>{this.searchWithFilter('Rejected')}}>
                            {
                                !this.state.showRejectedFilter && <label>Rejected</label>
                            }
                        </div> */}
                        {/* <div onClick={()=>{this.searchWithFilter('showAgeFilter_Them')}}>
                            {
                                !this.state.showAgeFilter_Them && <label>{l.get('SF_lf_age')}</label>
                            }
                        </div>
                        <div onClick={()=>{this.searchWithFilter('showGenderFilter_Them')}}>
                            {
                                !this.state.showGenderFilter_Them && <label>{l.get('SF_lf_gender')}</label>
                            }
                        </div> */}
                    </div>
                }
            </div>
        );
    }
}


//value={Number(this.state.fromLimit) + Number(this.state.from) + i} 
