import React from 'react';
import SignIn from '../SignIn/signin.js';
import './header.css';
//import logo from '../Assets/fireworks.gif';
//import wc from '../Assets/wc.svg';
import chat from '../Assets/chat_white.svg';
//import noimage from '../Assets/noimage.svg';
import config from '../config';
import people from '../Assets/people-white.svg';
//import people_greyed from '../Assets/people-white-greyed.svg';
import CenterJSLogo from '../centerJS/centerJSLogo.jsx';
import SearchBar from './searchbar.jsx';
import { Link } from "react-router-dom";
import l from '../language.js';
//import {withRouter} from 'react-router'

export default
class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            authUser: props.authUser,
            authUid: props.authUid,
            allUids: props.allUids,
            f_setAuthenticated: props.f_setAuthenticated,
            f_loadNext: props.f_loadNext,
            f_updateCurrentUid: props.f_updateCurrentUid,
            showingSearchSuggestions: false,
            nextUid: '',
            //currentUid: ''
        }
        this.peopleButtonRef = React.createRef();
    }

    // componentDidMount(){
    //     this.loadNext();
    // }

    componentDidMount(){
        const component = this;
		this.unlisten = this.props.history.listen((location, action) => {
            //console.log("HEADER route changed: ", this.props.match.params.userId);
            component.loadNext();
		});
    }

    componentWillUnmount() {
		this.unlisten();
	}
    
    componentDidUpdate(){
        const component = this;
        if(this.state.allUids !== this.props.allUids){
            this.setState({
                allUids: this.props.allUids
            }, ()=>{
                component.loadNext();
            })
        }
    }

    f_updateSearchSuggestionsShowing = (b) => {
        this.setState({
            showingSearchSuggestions: b
        })
    }

    headerPressed = (e) => {
        //console.log('HEADER PRESSED: ', e.target.classList);
        if(e.target.nodeName === "INPUT" 
        || e.target.parentElement.parentElement.classList.contains('searchFilters-container')
        || e.target.classList.contains('searchFilters-container')
        || e.target.parentElement.classList.contains('searchFilters-container')
        || e.target.classList.contains('sf-no-close')){
            //console.log('header returning');
            return;
        }
        this.setState({
            showingSearchSuggestions:false
        })
    }

    extractUid = () => {
        const pattern = /\/[a-zA-Z1-9]+$/;
        const match = pattern.exec(window.location.pathname);
        if(match && match.length){
            const currentPageUid = match[0].substring(1);
            return currentPageUid;
        } else {
            //console.error("UID match not found in URL");
            return undefined;
        }
    }

    getRandomUid = () => {
        //console.log('this.props.allUids: ', this.props.allUids);
        var randVal = Math.floor(Math.random() * this.props.allUids.length);
        return this.props.allUids[randVal];
    }

    loadNext = () => {
        //console.log('this.state.nextUid: ', this.state.nextUid);
        //console.log('this.state.currentUid: ', this.state.currentUid);
        //console.log('window.location.pathname.includes(this.state.nextUid): ', window.location.pathname.includes(this.state.nextUid));
        //console.log('window.location.pathname: ', window.location.pathname);
        // if(!this.state.currentUid.length){
        //     console.log('extracted page uid: ', this.extractUid());
        //     this.setState({
        //         currentUid: this.extractUid()
        //     })
        // }
        if(!this.state.nextUid || !this.state.nextUid.length || window.location.pathname.includes(this.state.nextUid)){
            const nowUid = this.extractUid();
            //console.log('WE ARE ON: ', nowUid);
            var uid = '';
            var count = 0;
            while((nowUid === uid || uid === '' || global.authUid === uid) && count < 100){
                uid = this.getRandomUid();
                count++;
                //console.log('getting random uid: ', uid);
            }
            if(!uid) return;
            //console.log('NEXT WILL BE: ', uid);
            this.setState({
                //currentUid: nowUid,
                nextUid: uid
            }, ()=>{
                this.props.f_loadNextPage(uid);
            })
        }
    }

    render(){
        return(
            <div className="g-flex g-space-between" id="header" 
            onClick={(e)=>{this.headerPressed(e)}}
            >
                <div className="header-left-container">
                    {/*<img src={noimage} alt="" id="logoImg" style={{width:"48px"}} />*/}
                    <div className="g-text-left g-flex">
                    {/* PREVIOUS LOGO WITH TILTED TEXT */ }
                    {/* <label 
                            className="g-white g-text-left"
                            id="logo">
                        <label>{config.APP_TITLE1}</label>
                        <label><sup>{config.APP_TITLE2}</sup></label>
                        <label>{config.APP_TITLE3}</label>
                    </label> */}
                        <CenterJSLogo />
                    {/* <label style={{fontFamily: '',fontSize: '42px',fontWeight: 600,letterSpacing: '.125rem',margin: 'auto',marginLeft:'15px',marginTop:'-0px'}} 
                            className="g-white g-text-center">{config.APP_TITLE1}
                            <img className="logowc-female" src={wc} style={{width:"38px",marginTop:"-10px"}}/>
                            {config.APP_TITLE2}
                            <img className="logowc-male" src={wc} style={{width:"38px",marginTop:"-10px"}}/>
                            {config.APP_TITLE3}
                    </label> */}
                        <SearchBar 
                            f_updateSearchSuggestionsShowing={this.f_updateSearchSuggestionsShowing}
                            showingSearchSuggestions={this.state.showingSearchSuggestions}
                            uid={this.props.authUid} />

                        <label className="slogan">{config.APP_SLOGAN}</label>
                    </div>
                    
                </div>


                <div className="header-buttons-container g-flex">
                    {/* <Link to={"/spaces/"} //onClick={this.loadNext}
                        id="">
                        <div className="g-flex">
                            <span id="header-randomPage-txt" style={{lineHeight:"1.7"}}>
                                <span id="randomPerson-txt">{l.get('RC_TITLE').toUpperCase()}</span>
                            </span>
                        </div>
                    </Link> */}

                    <Link to={"/messages"}
                        id="">
                        <div className="g-flex">
                            <span id="header-randomPage-txt" style={{lineHeight:"1.7"}}>
                                <span id="randomPerson-txt">{l.get('HEADER_MESSAGES').toUpperCase()}</span>
                                <img className="loadRandomPage-img" alt="" src={chat} />
                            </span>
                        </div>
                    </Link>

                    <Link to={"/user/" + this.state.nextUid} //onClick={this.loadNext}
                        id="ANALYTICS_loadRandomPage">
                        <div className="g-flex">
                            <span id="header-randomPage-txt">
                                <span id="randomPerson-txt">{l.get('HEADER_RANDOM_PERSON')}</span>
                                <img ref={this.peopleButtonRef} 
                                    src={people} 
                                    alt="" 
                                    className="loadRandomPage-img" />
                            </span>
                        </div>
                        {/* <div>Random Page</div> */}
                    </Link>

                </div>
                <SignIn className="g-f1 g-mg-auto" 
                    authUser={this.props.authUser} 
                    authUid={this.props.authUid}
                    f_updateCurrentUid={this.props.f_updateCurrentUid}
                    f_setAuthenticated={this.props.f_setAuthenticated} />
            </div>
        )
    }
}

//export default withRouter(Header);



// loadRandomUser = () => {
    //     console.log('loading random user');
    //     if(!this.state.currentPageUid || !window.location.pathname.includes(this.state.currentPageUid)){
    //         const currentPageUid = this.extractUid();
    //         if(!currentPageUid) return;
    //         this.setState({
    //             currentPageUid: currentPageUid
    //         }, ()=>{
    //             return this.load();
    //         })
    //     }
    //     else return this.load();
        
    //     //console.log(this.state.currentPageUid);
        
    // }

    // load = () => {
    //     while(this.state.currentPageUid && this.state.currentPageUid.length > 0 && uid === this.state.currentPageUid){
    //         var randVal = Math.floor(Math.random() * this.props.allUids.length);
    //         var uid = this.props.allUids[randVal];
    //         console.log('loaded uid: ', uid);
    //         console.log('currentPageUid: ', this.state.currentPageUid);
    //         return this.load();
    //     }
    //     return uid;
    // }