import React from 'react';
import SelectStyledLikeText from './selectStyledLikeText.jsx';

export default class EditHeight extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            defaultType: props.defaultType,
            default: props.default
        }
    }

    onChange = (e) => {
        this.setState({
            currentHeight: e.target.value
        })
    }

    render(){
        return(
            <div className="">
                {/* <div> */}
                    <label className="editInputLabel g-inlineblock">Who is</label>
                {/* </div> */}
                <div className="editHeight g-mg-t-10 g-inlineblock">
                    <div className="editHeight-select-container g-inlineblock">
                        {/* <select name="heightType" className="g-mg-r-10 editHeight-select">
                            <option>Taller than</option>
                            <option>Shorter than</option>
                        </select> */}
                        <SelectStyledLikeText 
                            name="heightType"
                            //options={["Select a height", "Close to", "Taller than", "Shorter than"]}
                            options={["Select a height", "Less than", "More than"]}
                            default={this.props.defaultType} />
                    </div>
                    <input name="Height" 
                        type="number" 
                        placeholder="170"
                        defaultValue={this.state.default || ""}
                        onChange={this.onChange}
                        className="editAgeRange-input m-modal-toRead" style={{width:"50px"}} />
                    <label className="editInputLabel g-inlineblock g-mg-l-10">cm</label>
                </div>
            </div>
        )
    }
}
