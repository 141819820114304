import React from 'react';
import l from '../language.js';
import { Link } from "react-router-dom";
import './getStartedProfile.css';

export default class GetStartedProfile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: props.uid
        }
    }

    render(){
        return(
            <Link to={'/user/' + this.props.uid} id="gsp-a">
                <div className="g-mg-t-10 g-default-container g-791 gsp-container">
                    <span>{l.get('GSP_1')}</span>
                {/* <Link to={'/user/' + this.props.uid}>
                    <div>View Profile</div>
                </Link> */}
                </div>
            </Link>
        );
    }
}
