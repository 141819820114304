import React from 'react';
//import logo from './logo.svg';
import './App.css';
import './g.css';
import './fonts.css';
import './Analytics/analytics.js';
import './Analytics/tagManager.js';
import config from './config.js';
// import {LoadIntelliticksScript} from './scriptLoader.js';

import {BrowserRouter as Router} from 'react-router-dom';
//import HowItWorks from './HowItWorks/howItWorks.js';
import Switcher from './switcher';
// provider.setCustomParameters({
//   'display': 'popup'
// });

import './react-multistep-css/custom.css';
import './react-multistep-css/normalize.css';
import './react-multistep-css/prog-tracker.css';
import './react-multistep-css/skeleton.css';


global.STATES = {
  PROFILE: 'Profile',
  BROWSE: 'Browse'
};

global.log = (str) => {
  if(config.DEBUG) console.log(str);
}

global.authUser = "";
global.authPhoto = "";
global.authUid = ""
global.showEncouragementStatus = undefined;
global.showGetStartedStatus = undefined;

function App() {
  // LoadIntelliticksScript();
  
  return (
    <div className="App">
      <Router> 
        <Switcher state={global.state} className="g-f1"/>
      </Router>
    </div>
  );
}

export default App;
// export default withFirebaseAuth({
//   providers,
//   firebaseAppAuth,
// })(App);

      //{/*Meet people that share your values*/}
      //{/*<HowItWorks />*/}
