import React from 'react'
//import { Link } from "react-router-dom";
import HeaderPopup from './headerPopup.jsx';
//import uniqid from "uniqid";
//import db from '../db.js';
//import FacebookLogin from 'react-facebook-login';
//import GoogleLogin from 'react-google-login';
import './signin.css';

//import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app';
import 'firebase/auth';

import db from '../db.js';

import googleLogo from '../Assets/google-logo-512.png';
import fbLogo from '../Assets/facebook-logo-512.png';
import close from '../Assets/close-white.svg';
import avatar_f from '../Assets/undraw/avatar_f.svg';
import avatar_m from '../Assets/undraw/avatar_m.svg';
//import firebaseConfig from '../firebaseConfig';

//const firebaseApp = firebase.initializeApp(firebaseConfig);

//const firebaseAppAuth = firebaseApp.auth();

//var provider = new firebase.auth.FacebookAuthProvider();

// provider.addScope('user_age_range');
// provider.addScope('user_gender');
// provider.addScope('user_photos');
// provider.addScope('user_videos');
// provider.addScope('user_friends');
// provider.addScope('user_birthday');
// provider.addScope('user_likes');
// provider.addScope('user_events');
// provider.addScope('user_location');


export default class SignIn extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			fbProvider: new firebase.auth.FacebookAuthProvider(),
			googleProvider: new firebase.auth.GoogleAuthProvider(),
			authUser: props.authUser,
			authUid: props.authUid,
			f_setAuthenticated: props.f_setAuthenticated,
			f_updateCurrentUid: props.f_updateCurrentUid,
			showSignInMethodDiv: false,
			showingSignedInDiv: false,
			b_useAvatar: 0
		};
	}

	componentDidMount(){
		const component = this;
		firebase.auth().onAuthStateChanged(function(user) {
			//console.log("Firebase Authentication state changed: ", user)
			if (user) {
				//console.log('User is signed in: ', user);
				
				// User is signed in.
				global.authUser = user.displayName;
				/*var email = user.email;
				var emailVerified = user.emailVerified;
				var photoURL = user.photoURL;
				var isAnonymous = user.isAnonymous;*/

				//TODO this.getURLFromUid()
				//console.log(uniqid());
				global.authUid = user.uid;
				component.props.f_setAuthenticated(user.uid, user.displayName, user.email);
				//var providerData = user.providerData;

				// Use Avatar image if Mobile (no space to display name)
				if(window.innerWidth < 650){
					component.setState({
						b_useAvatar: Math.floor((Math.random() * 2) + 1) === 1 ? avatar_f : avatar_m
					}, ()=>{
						//console.log('avatar set: ', this.state.b_useAvatar)
					})
				}
				if(!global.encouragementStatus){
					db.getEncouragementStatus(user.uid).then((res)=>{
						//console.log("encouragementStatus: ", res);
						global.showEncouragementStatus = res || true;
						global.showGetStartedStatus = res === undefined ? true : res;
					})
				}
			} else {
				//console.log("User is not signed in");
				component.props.f_setAuthenticated("", "", "");
				global.showGetStartedStatus = undefined;
			}
		});
	}

	componentDidUpdate(){
		if(this.state.authUid !== this.props.authUid || this.state.authUser !== this.props.authUser){
			this.setState({
				authUid: this.props.authUid,
				authUser: this.props.authUser
			});
		}
	}

	getURLFromUid = () => {
		// Check if the UID exists, if it doesn't exist, then create a new one and return it.
		// If it exists, then return the UId.
	}

	responseFacebook = (response) => {
      //console.log(response);
    }

    _updateSignInDropdown = () => {
    	//console.log('showing dropdown');
    	this.setState({
    		showSignInMethodDiv: !this.state.showSignInMethodDiv
    	});
	}
	
	_signedInDropdown = () => {
		//console.log('Showing signed in dropdown');
		this.setState({
			showingSignedInDiv: !this.state.showingSignedInDiv
		})
	}

	// Using FIREBASE auth instead of third party auth by itself.
    _signIn(provider){
    	const component = this;
    	this.setState({
    		showSignInMethodDiv: false
    	})

    	firebase.auth().useDeviceLanguage();
    	firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    		.then(()=>{
    			firebase.auth().signInWithPopup(provider)
			    	.then(function(result) {
					  //var token = result.credential.accessToken;
					  localStorage.setItem("displayName", result.user.displayName);
					  localStorage.setItem("photoURL", result.user.photoURL);
					  localStorage.setItem("email", result.user.email);
					  localStorage.setItem("uid", result.user.uid);
					  //console.log('Signed In successfully: ', result);
					  //console.log('saved to localstorage: ', result.user.email);
					  component.props.f_setAuthenticated(result.user.uid, result.user.displayName, result.user.email);
					  global.authUser = result.user.displayName;
					  global.authPhoto = result.user.photoURL;
					  global.authEmail = result.user.email;
					  global.authUid = result.user.uid;
						// Use Avatar image if Mobile (no space to display name)
						if(window.innerWidth < 650){
							component.setState({
								b_useAvatar: Math.floor((Math.random() * 2) + 1) === 1 ? avatar_f : avatar_m
							})
						}
					  //React.useEffect(()=>{
					  	//localStorage.setItem("user", global.result.user);
					  //}, global.result.user);
					}).catch(function(error) {
					  //var errorCode = error.code;
					  //var errorMessage = error.message;
					  //var email = error.email;
					  //var credential = error.credential;
					  console.error("Error authenticating user: ", error);
					});
    		})
    		.catch(err=>{console.error("Error setting firebase auth persistence: ", err)});
    }

    _signOut = () => {
    	const component = this;
    	firebase.auth().signOut()
    		.then(()=>{
    			//console.log('Signed out successfully');
				component.props.f_setAuthenticated("", "", "");
				component.setState({
					showingSignedInDiv: false,
					b_useAvatar: 0
				});
				global.showGetStartedStatus = undefined;
    		})
    		.catch((err)=>{console.log('Error signing out')});
    }

	render(){
		//console.log('b_useAvatar: ', this.state.b_useAvatar);
		return(
		
			
			<div style={{margin: 'auto 0',marginRight:'10px'}}>
			{	//typeof this.props.authUid === 'undefined' || !this.props.authUid.length 
				!this.props.authUser.length
				? <div id="signin-container" onClick={()=>this._updateSignInDropdown()}>
					{
						this.state.showSignInMethodDiv
						? <img src={close} alt="" style={{margin:'auto'}}/>
						: <div id='signinBtn' className="ANALYTICS_signInPressed"><span style={{pointerEvents:"none"}}>Sign In</span></div>
					}
				</div>
				: <div className="g-pd-10">
				{
					this.state.showingSignedInDiv ?
					<div>
						<img alt="" src={close} onClick={()=>{this._signedInDropdown()}} 
							onMouseEnter={(e)=>{e.target.style.backgroundColor = "rgba(0,0,0,.25)"}} 
							onMouseLeave={(e)=>{e.target.style.backgroundColor = "transparent"}} />
					</div>
					:
					!this.state.b_useAvatar 
					? <label style={{whiteSpace:'nowrap'}}>Hi
						<span onClick={()=>{this._signedInDropdown()}} 
							onMouseEnter={(e)=>{e.target.style.backgroundColor = "#0073b1"}}
							onMouseLeave={(e)=>{e.target.style.backgroundColor = "transparent"}}
							style={{whiteSpace:'nowrap',transition:"background-color 100ms",textDecoration:"underline!important",cursor:"pointer",padding:"2px"}} 
							className="g-underline g-mg-l-5">
								{this.props.authUser.length > 30 
									? this.props.authUser.split(" ").length 
										? this.props.authUser.split(" ")[0].toUpperCase()
										: this.props.authUser.toUpperCase()
									: this.props.authUser.toUpperCase()	
								}
						</span>
					</label>
					: <img 
						onClick={()=>{this._signedInDropdown()}} 
						className="mobileAvatarLogo" 
						src={this.state.b_useAvatar} 
						alt="" />
					
				}
				{/*"rgba(0,0,0,.25)*/}
				</div>
			}

			{
				this.state.showSignInMethodDiv &&
				<div id="signInMethodDiv">
					<h2 className="inbetween">Sign In Method</h2>
					<div className="signInMethodContainer g-flex ANALYTICS_signInMethodGoogleSelected" onClick={()=>{this._signIn(this.state.googleProvider)}}>
						<img className="g-f1 logoImg" alt="" src={googleLogo} />
						<label className="g-f1 signInMethodTxt" >Google</label>
						
					</div>
					{/*<div><hr style={{margin:'0px',width:'95%'}}/></div>*/}
					<div className="signInMethodContainer g-flex ANALYTICS_signInMethodFacebookSelected" onClick={()=>{this._signIn(this.state.fbProvider)}}>
						<img className="g-f1 logoImg" alt="" src={fbLogo} />
						<label className="g-f1 signInMethodTxt">Facebook</label>
					</div>
				</div>
			}		
			{
				this.state.showingSignedInDiv &&
				<HeaderPopup 
					authUid={this.state.authUid} 
					name={this.props.authUser}
					f_signOut={this._signOut}
					f_updateCurrentUid={this.state.f_updateCurrentUid}
					f_close={this._signedInDropdown}
				/>
			}
			</div>
		);
	}
}

// {/*<FacebookLogin
// 			        appId="367436623936618"
// 			        fields="name,email,picture"
// 			        callback={this.responseFacebook}
// 			      />
// 			  */}
// 			  {/*redirect address = https://dating-globally.firebaseapp.com/__/auth/handler*/}
// 			      { /*
// 			      	<GoogleLogin
// 				        clientId="" //CLIENTID NOT CREATED YET
// 				        buttonText="LOGIN WITH GOOGLE"
// 				        onSuccess={responseGoogle}
// 				        onFailure={responseGoogle}
// 				      />
//			      }

			      
