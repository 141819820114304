// This is floating on the right

import React from 'react';
import db from '../db.js';
import l from '../language.js';
import './favourites.css';
import helpers from '../helpers.js';
import { Link } from "react-router-dom";
import date from '../Assets/undraw/date_night.svg';

export default class Favourites extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            authUid: props.authUid,
            favourites: {},
            iLiked: [],
            theyLiked: [],
            accepted: [],
            loading: true,
            favIds: [],
            reloadFavourites: props.reloadFavourites
        }
    }

    componentDidMount(){
        //console.log('favourites component mounted');
        this.load();
        //global.reloadFavourites = this.load;
        //this.listenForFavouriteChanges();
    }   

    componentDidUpdate(){
        if(this.state.authUid !== this.props.authUid){
            this.setState({
                authUid: this.props.authUid
            }, ()=>{
                this.load();
            })
        }
        if(this.state.reloadFavourites !== this.props.reloadFavourites){
            this.setState({
                reloadFavourites: this.props.reloadFavourites
            }, ()=>{
                this.load();
            })
        }
    }

    listenForFavouriteChanges = () => {
        // const cb = (favoriteIds)=>{
        //     console.log("checking if favorites are different");
        //     if(this.state.favIds.length !== favoriteIds.length){
        //         console.log('Different and reloading!');
        //         this.load();
        //     }
        // }
        // this.setState({
        //     unsubscribe: db.listenFavourites(this.state.authUid, cb)
        // });
    }

    componentWillUnmount(){
        //this.state.unsubscribe();
    }

    load(){
        const promises = [], iLiked = [], theyLiked = [], accepted = [], favIds = [];
        var favourites = {};
        const component = this;
        db.loadFavouritesForUser(this.state.authUid)
            .then(data => {
                favourites = data;
                data.forEach(favObj => {
                    // Rejected requests will be hidden from both parties
                    //console.log('favId: ', favObj.status === "accepted");
                    const to = favObj.to;
                    const from = favObj.from;
                    //console.log('2 favObj: ', favObj);
                    if(favObj.status === "pending"){
                        // I favourited someone else
                        if(from === this.state.authUid){
                            promises.push(
                                this.loadProfileForId(to)
                                    .then(personalDetails => {
                                        //iLiked[favObj.id] = { ...personalDetails, ...{uid: to} };
                                        iLiked.push({ ...personalDetails, ...{uid: to} });
                                        favIds.push({uid: to,status: "pending"});
                                        //console.log('iLiked: ', iLiked);
                                        return 'iLiked';
                                    })
                            );
                        // Someone else favourited me
                        } else if(to === this.state.authUid){
                            promises.push(
                                this.loadProfileForId(from)
                                    .then(personalDetails => {
                                        //theyLiked[favObj.id] = { ...personalDetails, ...{uid: from} } ;
                                        theyLiked.push({ ...personalDetails, ...{uid: from}, id: favObj.id });
                                        //console.log('theyLiked: ', theyLiked);
                                        favIds.push({uid: from,status:"awaiting"});
                                        return 'theyLiked';
                                    })
                            );
                        } else {
                            //console.log('to: ', to);
                            //console.log('from: ', from);
                            console.error("No profileId that matches logged in authUid: ", this.state.authUid);
                        }
                    } else if(favObj.status === "accepted"){
                        const otherUid = [to, from].filter((uid)=>{return uid !== this.state.authUid})[0];
                        promises.push(this.loadProfileForId(otherUid)
                                .then(personalDetails => {
                                    //accepted[`${favObj.id}`] = { ...personalDetails, ...{uid: otherUid } };
                                    accepted.push({ ...personalDetails, ...{uid: otherUid } });
                                    favIds.push({uid: otherUid, status: "accepted"});
                                    //console.log("LOADED accepted: ", accepted);
                                    return 'accepted';
                                })    
                        );
                    }
                })
                Promise.all(promises).then(()=>{
                    //console.log('SAVING PROMISES: ', promises);
                    component.setState({
                        favourites: favourites,
                        iLiked: iLiked,
                        theyLiked: theyLiked,
                        accepted: accepted,
                        loading: false,
                        favIds: favIds
                    }, ()=>{
                        //console.log('setting state: ', favIds)
                    });
                    this.props.f_myFavourites(favIds);
                });
            })
    }

    loadProfileForId = async (uid) => {
        //console.log('reading for uid: ', uid);
        return db.getContactFieldsToShow(uid).then(data => {
            //console.log("data: ", data)
            //TODO this is not loading the contact details
            return data;
        })
    }

    goToUid = (uid) => {
        //window.location.href = '/user/' + uid;        
    }

    ignoreFavourite = (favId) => {
        db.updateFavourite(favId, "rejected").then(()=>{
            this.load();
            this.props.f_updateDashboard();
        });
    }

    acceptFavourite = (favId) => {
        //console.log('Accepted Favourite');
        db.updateFavourite(favId, "accepted").then(()=>{
            this.load();
            this.props.f_updateDashboard();
        });
    }

    render(){
        //console.log("RENDERING this.state: ", this.state);
        return(
            <div id="favourites-main">
                {
                    this.state.theyLiked.length ? 
                    <div>
                        <h2 className="favourites-title">{l.get('F_NEW_REQUEST')}</h2>
                        <hr style={{margin:"5px"}}/>
                        {
                            this.state.theyLiked.map((k, index) => (
                                <div key={"theyLiked-" + index} className="favourites-container">
                                    <Link to={"/user/" + k.uid} className="ANALYTICS_favouritesTheyLikedClicked">
                                        <FavouriteItem k={k} index={index} b_actionRequired={true} />
                                    </Link>
                                    <div className="g-flex g-space-between g-mg-t-10">
                                        <div className="favourites-ignore" onClick={()=>this.ignoreFavourite(k.id)}>
                                            <span>{l.get('F_IGNORE')}</span>
                                        </div>
                                        <div className="g-mg-r-10 favourites-accept" onClick={()=>this.acceptFavourite(k.id)}>
                                            <span>{l.get('F_ACCEPT')}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    : ""
                }

                {
                    this.state.iLiked.length ?
                    <div>
                        <h2 className="favourites-title">People you liked</h2>
                        <hr style={{margin:"5px"}}/>
                        {
                            this.state.iLiked.map((k, index) => (
                                <div key={"iLiked-" + index} className="favourites-container">
                                    <Link to={"/user/" + k.uid} className="ANALYTICS_favouritesiLikedClicked">
                                        <FavouriteItem k={k} index={index} />
                                    </Link>                                    
                                </div>
                            ))
                        }
                    </div>
                    : ""
                }
                
                {
                    this.state.accepted.length ?
                    <div>
                        <h2 className="favourites-title">{l.get('F_UR_CONNECTIONS')}</h2>
                        <hr style={{margin:"5px"}}/>
                        {
                            this.state.accepted.map((k, index) => (
                                <div key={"accepted-" + index} className="favourites-container">
                                    <Link to={"/user/" + k.uid} className="ANALYTICS_favouritesAcceptedClicked">
                                        <FavouriteItem k={k} uid={k.uid} index={index} />
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                    : ""
                }
                {
                    this.state.favourites.length || !this.state.loading 
                    ? <div className="g-text-center">
                        <img src={date} alt="" className="favourites-promo-img" />
                        <label>{l.get('F_MORALE_LOWER')}</label>	
                    </div>
                    : ""
                }
            </div>
        );
    }
}

class FavouriteItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: props.uid,
            favId: props.k.id
        }
    }

    render(){
        const props = this.props;
        //console.log('this.props: ', this.props);
        return <div style={{pointerEvents:"none"}}>
            {/* {props.index > 0 && <hr style={{margin:"5px"}}/> } */}
            <span>{helpers.convertStringToDate(props.k['Date of birth'])}</span>
            <span>{props.k.Gender ? props.k.Gender.substring(0,1) : ""}</span>
            <span className="g-span-dot"></span>
            <span>{props.k['Your Location']}</span>
            <br/>
            <span>
                {   props.k['About Me'] 
                    ? props.k['About Me'].length < 75 
                    ? props.k['About Me']
                    : props.k['About Me'].substring(0, 75) + '...'
                    : ""
                }
            </span>
            {/* {
                props.b_actionRequired && <div className="g-flex g-space-between g-mg-t-10">
                    <div className="favourites-ignore" onClick={()=>this.ignoreFavourite()}>
                        <span>Ignore</span>
                    </div>
                    <div className="g-mg-r-10 favourites-accept" onClick={()=>this.acceptFavourite()}>
                        <span>Accept</span>
                    </div>
                </div>
            } */}
        </div>
    }
    
}
