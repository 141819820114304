import React from 'react';
//import dollar from '../Assets/money_white.svg';
import SelectStyledLikeText from './selectStyledLikeText.jsx';

export default class EditWealth extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentSelected: undefined,
            wealthType: props.default || "Select wealth"
        }
    }

    updateSelected = (newIndex, e) => {
        const el = document.getElementsByClassName("w-selected")[0];
        if(el){
            el.classList.remove("w-selected");
            el.classList.remove("m-modal-toRead");
        }
        this.setState({
            currentSelected: newIndex
        })
        e.target.classList.add("m-modal-toRead");
        e.target.classList.add("w-selected")
    }

    render(){
        return(
            <div>
                <label className="editInputLabel g-inlineblock">With </label>
                    <SelectStyledLikeText 
                        name="wealthType"
                        default={this.state.wealthType}
                        options={["Select wealth", "Some", "Enough", "A lot of "]} />
                    {/* <div className="g-inlineblock dollarCircle" onClick={(e)=>{this.updateSelected(0, e)}}>
                        <img src={dollar} alt="" />
                    </div>
                    <div className="g-inlineblock g-relative dollarCircle" id="dollarSign3" onClick={(e)=>{this.updateSelected(1, e)}}>
                        <img src={dollar} alt="" />
                        <img src={dollar} alt="" />    
                        <img src={dollar} alt="" />    
                    </div>
                    <div className="g-inlineblock dollarCircle" style={{lineHeight:"1.85"}} onClick={(e)=>{this.updateSelected(2, e)}}>
                        <label className="g-inlineblock g-mg-auto" style={{lineHeight:"1.42"}}>Don't care</label>
                    </div> */}
                <label className="editInputLabel g-inlineblock"> Money </label><br/>
            </div>
        )
    }
}
