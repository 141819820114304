import React from 'react';
import './editModal.css';
import close from '../Assets/close-black.svg';
import Loader from '../Browse/loader.js';
import l from '../language.js';
/*
 OPTIONS: {
	 hideSaveBtn: bool,
	 hideDeleteBtn: bool
 }
*/ 

export default class EditModal extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			title: props.title,
			body: props.body,
			updateBody: props.updateBody,
			f_saveCallback: props.f_saveCallback,
			f_deleteCallback: props.f_deleteCallback,
			showLoading: props.showLoading,
			options: props.options || {},
			currentData: props.currentData
		}
	}

	componentDidUpdate(){
		if(this.state.title !== this.props.title 
			|| (this.state.currentData !== this.props.currentData && Object.keys(this.state.currentData).length)){
			this.setState({ 
				body: this.state.updateBody(this.props.title, this.props.currentData),
				title: this.props.title,
				currentData: this.props.currentData || {},
			}, ()=>{
				//console.log('component updated: ', this.props.currentData);
			});
		}
		if(this.state.options !== this.props.options){
			this.setState({
				options: this.props.options
			})
		}
	}

	prettifyTitle = (title) => {
		switch(title){
			case 'FAQ_ANSWER':
				return l.get('FAQ_ANSWER_TITLE');
			case 'FAQ_ASK':
				return l.get('FAQ_ASK_TITLE');
			default:
				return title;
		}
	}

	render(){
		//console.log("this.state.currentData: ", this.state.currentData);
		//this.state.body.props.children[0].props.name = "TESTING";
		return(
			<div ref={this.props.parentRef} className="m-modal-container">
				<div className="m-modal-wormhole">
					{
						this.props.showLoading && 
						<div className="g-absolute" 
							style={{top: "35%", left:"45%",zIndex:"220"}}>
								<Loader />
						</div>
					}
					<div className={(this.props.showLoading ? "m-modal-opaque " : "") + "m-modal-content"}>
						<div className="m-modal-title">
							{this.prettifyTitle(this.state.title)}
							<img src={close} alt="" onClick={()=>this.props.fn_hideModal()} className="m-modal-close" />
							
						</div>
						<div id="m-modal-body-to-read" className="m-modal-body">
							{this.state.body}
						</div>
						<div className="m-modal-end">
							{
								!this.state.options.hideDeleteBtn
								? <button onClick={()=>{this.state.f_deleteCallback(this.state.title, this.state.currentData.id)}}>Delete</button>
								: <div></div>
							}
							{	!this.state.options.hideSaveBtn
								? <button onClick={()=>{this.state.f_saveCallback(this.state.title, this.state.currentData ? this.state.currentData.id : undefined)}}>Save</button>
								: <div></div>
							}
						</div>
					</div>
				</div>
				<div className="m-modal-background"></div>
			</div>
		);
	}
}
