import React from 'react';

export default class RoomList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            rooms: props.rooms,
            f_updateCurrentRoom: props.f_updateCurrentRoom
        }
    }

    render(){
        // console.log(this.props.rooms);
        return(
            <div>
                {
                    Object.keys(this.props.rooms).map((r,i) => (
                        <div key={"r-"+i} className="rl-room" onClick={()=>{this.props.f_updateCurrentRoom(r)}}>
                            <div><span>{this.props.rooms[r].roomName}</span></div>
                        </div>
                    ))
                }
            </div>
        );
    }
}