import React from 'react';

export default class EditAgeRange extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            label: props.label,
            default: props.default,
            from: props.default ? props.default.split("-")[0] : "",
            to: props.default ? props.default.split("-")[1] : ""
        }
    }

    componentDidUpdate(){
        if(this.state.default !== this.props.default){
            this.setState({
                default: this.props.default,
                from: this.props.default.split("-")[0],
                to: this.props.default.split("-")[1]
            })
        }
    }

    onChangeFrom = (e) => {
        const newValue = e.target.value;
        //console.log("on change from: ", newValue);
        this.setState({
            from: newValue
        });
    }

    onChangeTo = (e) => {
        const newValue = e.target.value;
        //console.log("on change to: ", newValue);
        this.setState({
            to: newValue
        });
    }
    
    //TODO default value on these fields
    render(){
        //console.log('this.props: ', this.props);
        return(
            <div className="editAgeRange g-mg-l-10">
                <div className="editAgeRange-container">
                    <div>
                        <label className="g-initial" style={{fontWeight:"400"}}>From</label>
                        <input type="number"
                            name="ageRange-from" 
                            className="editInputInput m-modal-toRead editAgeRange-input" 
                            defaultValue={this.state.from || ""} 
                            onChange={this.onChangeFrom}
                            placeholder={20}/>
                    </div>
                    <div>
                        <label className="g-initial" style={{fontWeight:"400"}}>to</label>
                        <input type="number" 
                            name="ageRange-to" 
                            className="editInputInput m-modal-toRead editAgeRange-input"  
                            defaultValue={this.state.to || ""} 
                            onChange={this.onChangeTo}
                            placeholder={40} />
                        <label className="g-inlineblock" style={{fontWeight:"400"}}>years old</label>
                    </div>
                </div>
            </div>
        );
    }
}