import React from 'react';
import './searchbar.css';
import search from '../Assets/search.svg';
import axios from 'axios';
import db from '../db.js';
import config from '../config.js';
import SearchResults from './searchResults.jsx';
import DotLoader from './dotLoader.jsx';
import l from '../language.js';
import SearchFilters from './searchFilters.jsx'; //TODO add this back in

export default class SearchBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: props.uid,
            showingSearchSuggestions: props.showingSearchSuggestions,
            showingSearchFilters: true,
            searchResults: [],
            currentSearch: l.get('SEARCH_BAR_PLACEHOLDER'),
            filters: {},
            loading: false
        }
    }

    componentDidMount(){
        if(!this.props.uid) return;
        db.loadSearchFilters(this.props.uid).then((filters) => {
            console.log('loaded filters: ', filters);
            this.setState({
                filters: filters
            });
        })
    }
    
    componentDidUpdate(){
        //console.log('showingSearchSuggestions UPDATED: ', this.props.showingSearchSuggestions);
        if(this.state.showingSearchSuggestions !== this.props.showingSearchSuggestions){
            this.setState({
                showingSearchSuggestions: this.props.showingSearchSuggestions
            })
        }
    }


    onFocus = (e) => {
        const el = e.target;
        if(!el.classList.contains("selected")){
            el.classList.add("selected");
        }
        this.setState({
            currentSearch: ""
        })
        this.props.f_updateSearchSuggestionsShowing(true);
        // If filters are selected, automatically query for those
        if(localStorage.getItem('sf-gender') || localStorage.getItem('sf-age-from') || localStorage.getItem('sf-age-to')){
            console.log('querying with filters');
            document.getElementById("searchbar-input").value = "";
            this.searchWithFilters();
        }
    }

    onBlur = (e) => {
        //console.log('onblur called: ', this.state.showingSearchFilters);
        const el = e.target;
        if(el.classList.contains("selected") && this.state.showingSearchSuggestions){
            el.classList.remove("selected");
        }
    }

    f_updateFilters = (b) => {
        //console.log('updating filters: ', b);
        this.setState({
            showingSearchFilters: b
        })
    }

    onChange = (e) => {
        var newValue = e.target.value;
        newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
        
        this.setState ({
            //showingSearchFilters: e.target.value.length === 0, //TODO modify this
            currentSearch: newValue
        })
        this.searchWithFilters();
    }

    overlayPressed = () => {
        this.props.f_updateSearchSuggestionsShowing(false)
    }

    // Accessing Node JS Search Server
    search = () => {
        const searchTerm = document.getElementById("searchbar-input").value;
        this.setState({
            loading: true
        })
        axios({
			method: 'POST',
			url: `${config.SEARCH_SERVER_ADDRESS}/search`,
			data: {
			  searchTerm: searchTerm
			}
        })
        /*axios({
            method: 'GET',
            url: `${config.SEARCH_SERVER_ADDRESS}/search?searchTerm=${searchTerm}`
        })*/
		.then(res => {
            //if(res.data.results.hits.hits.length){
			    //console.log("Search Request Response: ", res.data.results)
                this.setState({
                    searchResults: res.data.results.hits.hits,
                    autoComplete: res.data.autoComplete //TODO add search suggestions
                });
            //}
            this.setState({
                loading: false,
            })
		}, (error) => {
			console.error("Error posting search request: ", error);
		})
    }

    resetLocalStorageFilters(){
        ['sf-age-from','sf-age-to','sf-gender'].forEach(f => {
            localStorage.removeItem(f);
        })
        
    }

    searchWithFilters = () => {
        const searchTerm = document.getElementById("searchbar-input").value;
        // get all of the filters
        const filters = Array.from(document.getElementsByClassName('sf-read-me'));
        console.log('searching with filters: ', filters);
        if(!filters.length){
            if(!searchTerm.length) return;
            this.resetLocalStorageFilters();
            this.search();
        }
        else {
            const filterObj = {};
            filters.forEach((filter)=>{
                //console.log(filter.name);
                //console.log("filter: ", filter.value);  
                if(filter.name && filter.name.length){
                    // Crudely check that it is not a default value
                    if(filter.value.includes('Select ')){
                        filterObj[filter.name] = '';
                        localStorage.removeItem(filter.name);
                    } else {
                        filterObj[filter.name] = filter.value;
                        localStorage.setItem(filter.name, filter.value);
                    }
                }
            });
            this.setState({
                loading: true,
                filters: filterObj
            })
            if(this.props.uid)
                db.saveSearchFilters(this.props.uid, filterObj);

            axios({
                method: 'POST',
                url: `${config.SEARCH_SERVER_ADDRESS}/filterSearch`,
                data: {
                searchTerm: searchTerm,
                filters: filterObj
                }
            })
            .then(res => {
                //if(res.data.results.hits.hits.length){
                    //console.log("Search Request Response: ", res.data.results)
                    this.setState({
                        searchResults: res.data.results.hits.hits,
                        autoComplete: res.data.autoComplete //TODO add search suggestions
                    });
                //}
                this.setState({
                    loading: false,
                })
            }, (error) => {
                console.error("Error posting search request: ", error);
            })
        }
    }

    render(){
        return(
            <div className="g-mg-auto">
                <div id="searchbar-container">
                    <input id="searchbar-input" className="ANALYTICS_searchPressed" type="text" 
                        onFocus={(e)=>this.onFocus(e)}
                        onBlur={e => this.onBlur(e)}
                        onChange={this.onChange}
                        value={this.state.currentSearch}
                        placeholder={l.get('SEARCH_BAR_PLACEHOLDER')}
                        autoComplete="off" />
                    {
                        !this.state.showingSearchSuggestions && 
                        <img src={search} alt="" id="searchbar-icon" />
                    }    
                    {
                        this.state.showingSearchSuggestions &&
                        <div id="searchbar-icon-pressed-container">
                            <img src={search} alt="" id="searchbar-icon-pressed" />
                        </div>
                    }
                </div>
                {
                    this.state.showingSearchSuggestions && <div id="searchOverlay" onClick={()=>{this.overlayPressed()}}></div>
                }
                {
                    (this.state.showingSearchSuggestions || this.state.loading) && <div style={{marginLeft: "20px", marginTop:"0px"}}>
                        <SearchbarSuggestions 
                            loading={this.state.loading}
                            showingSearchFilters={this.state.showingSearchFilters}
                            f_updateFilters={this.f_updateFilters}
                            f_searchWithFilters={this.searchWithFilters}
                            searchResults={this.state.searchResults} />
                    </div>
                }
            </div>
        );
    }
}

// Get UX for this, and plan it in an intuitive way
//https://towardsdatascience.com/how-to-create-a-production-ready-recommender-system-3c932752f8ea

function SearchbarSuggestions(props){
    // return props.loading
    //     ? <div className=""><DotLoader /></div>
    //     : <div id="searchbar-suggestions">
    //         { props.showingSearchFilters && <SearchFilters f_updateFilters={props.f_updateFilters} f_searchWithFilters={props.f_searchWithFilters} /> }
    //         <SearchResults searchResults={props.searchResults} loading={props.loading} />        
    //     </div>
    return <div id="searchbar-suggestions">
            { props.showingSearchFilters && <SearchFilters f_updateFilters={props.f_updateFilters} f_searchWithFilters={props.f_searchWithFilters} /> }
            {
                props.loading 
                ? <div className=""><DotLoader /></div>
                : <SearchResults searchResults={props.searchResults} loading={props.loading} />        
            }
        </div>
}
//<hr style={{margin:"0"}}/>