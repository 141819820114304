import React from 'react';
import './room.css';
import RoomUsers from './roomUsers.jsx';

export default class Room extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            openingTime: props.r.openingTime,
            duration: props.r.duration,
            participants: props.r.participants,
            name: props.r.name,
        }
    }

    render(){
        return(
            <div>
                <div className="room-title">
                    <span>{this.props.r.roomName}</span>
                </div>
                <div className="room-container">
                    <div className="room-left">
                        <span>{}</span>
                        <span></span>
                    </div>
                    <div className="room-right">
                        <RoomUsers />
                    </div>
                </div>
            </div>
        );
    }
}
