import React from 'react';
import './searchResults.css';
import helpers from '../helpers.js';
import { Link } from "react-router-dom";


export default class SearchResults extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            searchResults: props.searchResults,
            matchingField: '',
        }
    }

    render(){
        //if(this.props.searchResults.length) console.log('sr.match[Object.keys(sr.match)[0]]: ', this.props.searchResults[0].match[Object.keys(this.props.searchResults[0].match)[0]]);
        //TODO don't display own profile?
        //<span>{" " + String.fromCharCode("0x25CF") + " "}</span>
        return(
            <div>
                {
                    this.props.searchResults.map((sr, index) => (
                        <Link key={"sr-" + index} to={'/user/' + sr._id} style={{textDecoration:"none"}} className="ANALYTICS_searchResultPressed">
                            <div className="sr-row">    
                                <SearchResult sr={sr._source} uid={sr._id} highlight={sr.highlight} />
                            </div>
                        </Link>
                    ))
                }
                <span></span>
            </div>
        );
    }
}

class SearchResult extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            sr: props.sr,
            matchesToDisplay: []
        }
    }

    getMatchingString = (k) => {
        return k.split(/<em>|<\/em>/);
    }
    

    rowClicked = () => {
        //console.log(this.props.sr);
        window.location.href = '/user/' + this.props.uid;
    }

    convertField2Display = (str) => {
        switch(str){
            case 'AboutMe': return 'About Me';
            case 'About you in 75 characters': return 'About Me';
            case 'lf_gender':
            case 'lf_height':
            case 'lf_ageRange_to':
            case 'lf_wealth':
            case 'lf_ethnicity':
            case 'lf_and':
            case 'lf_ageRange_from':
            case 'lf_rating':
                return 'Looking For';
            case 'endDateYear':
            case 'startDateMonth':
            case 'startDateYear':
                return 'Relationship history';
            case 'city': return 'City';
            case 'country': return 'Country';
            case 'Relationship history': return 'Relationship History';
            default: return str;
        }
    }
//onClick={()=>{this.rowClicked()}}
    render(){
        //console.log(this.props.sr)
        return <div>
            <span className="sr-firstRow">{helpers.convertStringToDate(this.props.sr["Date of birth"])}</span>
            <span className="sr-firstRow">{this.props.sr.Gender ? this.props.sr.Gender.substring(0, 1) : ""}</span>
            <span className="g-mg-l-5 sr-firstRow">{this.props.sr["Your Location"]}</span>
            {/* <span className="g-mg-l-5 sr-aboutMe">{this.props.sr.AboutMe}</span> */}
            <span className=""> 
                {
                    this.props.highlight && this.props.highlight[Object.keys(this.props.highlight)[0]].map((field, index) =>(
                        <span key={"match-"+index}>
                            <br />
                            <span className="sr-matchField">
                                {this.convertField2Display(Object.keys(this.props.highlight)[0])}
                            </span>
                            <span className="bullet g-mg-l-5 g-mg-r-5"></span>
                            <span>
                            {
                                this.getMatchingString(field)
                                .map((m, index) => (
                                    <span key={'sr-mm-'+index} className="sr-matching-field">
                                    {
                                        index === 1 
                                        ? <b>{m}</b>
                                        : m
                                    }
                                    </span>
                                ))
                            }
                            </span>
                        </span>
                    ))
                }
            </span>
        </div>
    }
}

/*
{
    (this.props.sr.match[Object.keys(this.props.sr.match)[0]]).map((field,index)=>(
        <span key={"match-" + index}>
            <br/>
            <span
            className="sr-matchField">
                {/* {index > 0 ? ", " : ""} *///}
                //   {this.convertField2Display(field)}
                // {/* Generate the text for the matching field */}
            /*
            </span>
            <span className="bullet g-mg-l-5"></span>
            <span>
            {
                this.getMatchingString(field, Object.keys(this.props.sr.match)[0])
                .map((m, index) => (
                    <span key={'sr-mm-'+index} className="sr-matching-field">
                    {
                        index === 1 
                        ? <b>{m}</b>
                        : m
                    }
                    </span>
                ))
            }
            </span>
        </span>
    ))
}
*/
/*
    DEPRECATED - MINISEARCH
    getMatchingString = (matchField, matchingOn) => {
        const TRUNC_WIDTH = 50;
        var matchingString = this.props.sr[`${matchField}`];
        const matchFieldLength = matchingOn.length;
        const reg = new RegExp(matchingOn,"i");
        const matchFieldPositionStart = matchingString.search(reg);
        //console.log('matchFieldPositionStart: ', matchFieldPositionStart)
        var matchFieldPositionEnd = matchFieldPositionStart + matchFieldLength;
        //console.log('Math.abs(matchingString.length - matchFieldPositionStart): ', Math.abs(matchingString.length - matchFieldPositionStart));
        // if(Math.abs(matchingString.length - matchFieldPositionStart) > TRUNC_WIDTH){
        //     matchingOn += '...';
        // }
        //console.log('matchFieldPositionEnd: ', matchFieldPositionEnd)
        var stringStart = ' ' + matchingString.substring(Math.max(0, matchFieldPositionStart - TRUNC_WIDTH - 1), matchFieldPositionStart);
        if(matchFieldPositionStart - TRUNC_WIDTH - 1 > 15)
            stringStart = stringStart.replace(/[a-zA-Z1-9]+[ ]/,'');
        const res = [
            stringStart,
            matchingOn,
            matchingString.substring(
                matchFieldPositionEnd, 
                matchFieldPositionEnd + TRUNC_WIDTH
            ).replace(/[ ]$/,'') + (Math.abs(matchingString.length - matchFieldPositionStart) > TRUNC_WIDTH ? '....' : '')
        ]
        //console.log('returning: ', res);
        // this.setState({
        //     matchesToDisplay: res
        // }) 
        return res;
    }*/