import React from 'react';
import add from '../Assets/add.svg';
import './editBtn.css';

export default class AddBtn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            src: props.src
        }
    }

    render(){ 
        return(
            <div onClick={()=>this.props.callback()}>
                <div className="addBtnContainer" >
                    <img src={this.props.src || add} alt="" />
                </div>
            </div>
        );
    }
}
