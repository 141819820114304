import {db} from '../db.js';

const ROOMS_COLLECTION = 'Rooms';

export default {
    loadActiveRooms: async () => {
        const res = [];
        return db.collection(ROOMS_COLLECTION)
            .get()
            .then(docs => {
                // console.log("docs: ", docs);
                docs.forEach(doc => {
                    res[doc.id] = doc.data();
                })
                return res;
            })
            .catch(err => console.error("Error loading active rooms: ", err));
    },
    addRoom: async (id, roomName, maxParticipants) => {
        db.collection(ROOMS_COLLECTION)
            .doc(id)
            .set({
                roomName: roomName,
                maxParticipants: maxParticipants,
                participants: [],
                startTime: Date.now(),
            }, {merge: true})
            .then(()=>{return;})
            .catch(err => console.error("Error adding/editing room: ", err));
    },
    initRoomListener: async (cbAdd, cbDelete) => {
        db.collection(ROOMS_COLLECTION)
            .onSnapshot(roomSnapshot => {
                roomSnapshot.docChanges().forEach(change => {
                    if(change.type === "added" || change.type === "modified"){
                        cbAdd(change.doc.id, change.doc.data());
                    }
                    else {
                        cbDelete(change.doc.id);
                    }
                })
            })
    }
}
