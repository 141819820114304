import React from 'react';
//import tick from '../Assets/tick.svg';

export default class EditRadio extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: props.name,
            label: props.label,
            classes: props.classes,
            currentSelected: props.currentSelected,
            width: props.width || '50%'
        }
    }

    onChange = (e) => {
        // console.log(e.target);
        this.setState({
            currentSelected: e.target.value
        })
    }

    render(){
        return(
            <div>
                <br/>
                {
                    this.state.label && this.state.label.length 
                    ? <label className="editInputLabel">{this.state.label}</label>
                    : <label className="editInputLabel" style={{marginBottom:"-5px"}}>{this.state.name}</label>
                }
                
                <div className="g-f1 g-flex g-space-between g-mg-r-30 g-mg-b-10" style={{width:this.state.width}}>
                {
                    this.state.classes.map((c, index)=>(
                        // Can't have duplicate 'Yes' 'No' fields without unique id.
                        <div key={index}>
                            <input id={c + "-" + this.state.name} 
                                type="radio" 
                                name={this.state.name} 
                                value={c} 
                                className={(this.state.currentSelected || "").includes(c) ? "m-modal-toRead" : ""}
                                defaultChecked={(this.state.currentSelected || "") === (c)}
                                onChange={this.onChange}/>
                            <label htmlFor={c + "-" + this.state.name}>{c}</label>
                        </div>
                    ))
                }
            </div>
            </div>
            
        );
    }
}