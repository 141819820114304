import axios from 'axios';
import config from './config.js';
import db from './db.js';

export default {
    convertStringToDate: (value) => {
		//var pattern =  /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
		//var arrayDate = this.props.bornDate.match(pattern);
		if(!value || !value.length || !value.replace(/\s+/g,'').length) return "";
		var arrayDate = value.split("-");
		var dt = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
		var today = new Date();
		var age = today.getFullYear() - dt.getFullYear();
		var m = today.getMonth() - dt.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < dt.getDate())){
			age -= 1;
		}
		return age;
		/* PREVIOUS
		var diff = (dt.getTime() - today.getTime()) / 1000;
		diff /= (60 * 60 * 24);
		diff = Math.abs(Math.round(diff/365.25))// - 1;
		return diff;
		*/
		//this.setState({ age: diff, bornDate: value})
	},
	convertStringToYear: (value) => {
		if(!value || !value.length) return "";
		var arrayDate = value.split("-");
		var dt = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
		var today = new Date();
		var diff = (dt.getTime() - today.getTime()) / 1000;
		diff /= (60 * 60 * 24);
		diff = Math.abs(Math.round(diff/365.25)) - 1;
		return diff;
	},
	convertTimestampToYear: (timestamp) => {
		const d = (new Date(parseInt(timestamp))).toString().split(" ");
		return d[3];
	},
	convertTimestampToDate: (timestamp) => {
		//console.log("timestamp: ", timestamp);
		const d = (new Date(parseInt(timestamp))).toString().split(" ");
		return d[1] + " " + d[2];
	},
	convertTimestampToTime: (timestamp) => {
		const d = (new Date(parseInt(timestamp))).toString().split(" ")[4].split(":");
		return d[0] + ":" + d[1];
	},
	// For dates saved in server as firebase.firestore.Timestamp.fromDate(new Date()),
	convertServerTimestampToDate: (obj) => {
		const d = (new Date(parseInt(obj.seconds) * 1000)).toString().split(" ");
		return d[1] + " " + d[2] + " " + d[3];
	},
	// Display like 26F etc.
	// f is typically helpers.convertStringToYear()
	convertToDisplay: (obj, f) => {
		return f(obj["Date of birth"]).toString() || " ? "
		 	+ (obj.Gender ? obj.Gender.substring(0, 1) : " ? ");
	},
	displayAboutMe: (obj) => {
		if(obj['About Me']){
			return obj['About Me'].length < 75 
				? obj['About Me']
				: obj['About Me'].substring(0, 75) + '...'
		}
		return "";
	},
	checkEmailStatusFromUID: async (uid) => {
		const b = await db.getEmailStatusFromUid(uid) === "Yes";
		// console.log('Checking email status for uid: ', uid, b);
		return b;
	},
	sendNewQuestionEmail: async (uid, faqObj) => {
		if(!await this.checkEmailStatusFromUID(uid)) return;

		// console.log('Sending new FAQ Object: ', faqObj);
		axios({
			method: 'POST',
			url: `${config.SERVER_ADDRESS}/faqAskReceived`,
			data: {
				uid: uid,
				question: faqObj.question
			}
		}).then(()=>{ return;})
		.catch(err => {
			console.error("Error sending new FAQ question: ", err, uid, faqObj);
			return;
		});
	},
}