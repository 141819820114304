import React from 'react';

export default class EditLabel extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			label: props.label,
			color: props.color || "rgba(0,0,0,.9)"
		}
	}

	render(){
		return(
			<div>
				<label className="editLabelLabel" style={{color:this.state.color}}>
					{this.props.label} 
					<b>
                        <label className="mandatoryInputLabel">{this.props.mandatory ? "*" : ""}</label>
                    </b>
				</label>
			</div>
		);
	}
}