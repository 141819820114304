// https://github.com/johnsorrentino/center.js

import React from 'react';
import c from './center.js';
//import config from '../config.js';
import './centerJS.css';

export default class CenterJSLogo extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){
        // WebFont.load({
        //     google: {
        //       families: ["Aladin"]
        //     },
        c.centerjs({
			canvas: document.getElementById("canvas"),
			width: 30,
			height: 30,
			shape: "rounded", //"rounded"//circle//square,
			fontColor: "white",
			backgroundColor: "#0073b1", //#ff7075
			// backgroundColor: "rgb(224, 36, 94)",
			text: "O",
			fontFamily: "Girassol",
			fontSize: 28
          });
          c.centerjs({
			canvas: document.getElementById("canvas2"),
			width: 30,
			height: 30,
			shape: "rounded", //"rounded"//circle//square,
			fontColor: "white",
			backgroundColor: "#0073b1", //#ff7075
			// backgroundColor: "rgb(224, 36, 94)",
			text: "M",
			fontFamily: "Girassol",
			fontSize: 28
          });
          c.centerjs({
			canvas: document.getElementById("canvas3"),
			width: 30,
			height: 30,
			shape: "rounded", //"rounded"//circle//square,
			fontColor: "white",
			backgroundColor: "#0073b1", //#ff7075
			// backgroundColor: "rgb(224, 36, 94)",
			text: "P",
			fontFamily: "Girassol",
			fontSize: 28
		  });
    }

    render(){
        return(
            <div className="g-mg-auto logo-container">
                <canvas id="canvas"></canvas>
                <canvas id="canvas2"></canvas>
                <canvas id="canvas3"></canvas>
            </div>
        );
    }
}