import React from 'react';
//import "react-responsive-carousel/lib/styles/carousel.min.css";
//import Carousel from 'react-bootstrap/Carousel';
//import { Carousel } from 'react-responsive-carousel';
//import MultiStepComponent from '../FormsManager/multistep.js';
//import OtherProfile from '../Browse/otherProfile.js';	
import db from '../db.js';
import helpers from '../helpers.js';
import l from '../language.js';
//import pplBig from '../Assets/person.svg';
//import favourite from '../Assets/favorite.svg';
import MainDetails from './mainDetails.jsx';
import RelationshipHistory from './relationshipHistory.jsx';
import References from './references.jsx';
//import LookingFor from './lookingFor.jsx';
import Aspirations from './aspirations.jsx';
import Skills from './skills.jsx';
import Loader from './loader.js';
import EmailModal from '../Modal/emailModal.jsx';
//import TopNavigator from './topNavigator.jsx';
import EditModal from '../Edits/editModal.jsx';
import EditInput from '../Edits/editInput.jsx';
import EditRadio from '../Edits/editRadio.jsx';
import EditDate from '../Edits/editDate.jsx';
import EditLabel from '../Edits/editLabel.jsx';
import EditCheckbox from '../Edits/editCheckbox.jsx';
import { EditCountry } from '../Edits/editCountry.jsx';
import EditTextArea from '../Edits/editTextArea.jsx';
import EditAgeRange from '../Edits/editAgeRange.jsx';
import EditStarRating from '../Edits/editStarRating.jsx';
import EditHeight from '../Edits/editHeight.jsx';
//import EditCultural from '../Edits/editCultural.jsx';
import EditWealth from '../Edits/editWealth.jsx';
import EditSpecialRequest from '../Edits/editSpecialRequest.jsx';
import QualitiesSelection from '../Edits/qualitiesSelection.jsx';
import AdditionalDetailsSelection from './additionalDetailsSelection.jsx';
import SelectStyledLikeText from '../Edits/selectStyledLikeText.jsx';
import Favourites2 from '../Favourites/favourites2.jsx';
import ProfileIsBlank from './profileIsBlank.jsx';
import EncourageFillInProfile from './encourageFillInProfile.jsx';
import HireForPosition from './hireForPosition.jsx';
//import LonelinessLevel from './lonelinessLevel.jsx';
//import MustNotHave from './mustNotHave.jsx';
import RequirementsCombined from './requirementsCombined.jsx';
import ChatQuotes from './chatQuotes.jsx';
import GetStartedProfile from './getStartedProfile.jsx';
import FAQ from './faq.jsx';
import SignUpPrompt from './signUpPrompt.jsx';
import LikeArea from './likeArea.jsx';
import placeholder from '../Assets/undraw/web_dev.svg';
import Nickname from './nickname.jsx';
// Renders each MultiStepComponent based on the current uid

export default class BrowseComponent extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			uid: props.match.params.userId,
			currentUid: props.currentUid,
			authUser: props.authUser,
			authUid: props.authUid,
			authEmail: props.authEmail,
			loading: true,
			contactFields: [],
			favourites: [],
			myFavourites: props.myFavourites,
			myLikes: props.myLikes,
			nicknames: props.nicknames,
			nickname: undefined,
			personalityCode: "",
			personalityValues: [],
			personalDetails: {},
			lookingFor: [],
			aspirations: [],
			relationshipHistory: {},
			additionalDetails: [],
			references: [],
			skills: [],
			hiringFor: [],
			lonelinessLevel: {},
			mustNotHave: {},
			faq: {},
			ownPage: false,
			currentEditTitle: l.get('PD_TITLE'),
			editModalData: {},
			editModalLoading: false,
			showEmailModal: false,
			emailModalHeaderText: "",
			currentEmailModalTitle: "",
			f_sendUserToChat: props.f_sendUserToChat,
			f_setChatCurrentPageUser: props.f_setChatCurrentPageUser,
			f_updateCurrentUid: props.f_updateCurrentUid,
			selectedAdditionalDetails: [],
			options: {hideSaveBtn: false},
			dbError: false,
			showEditModal: false,
			totalRequests: 0,
			accepted: 0,
			rejected: 0,
			reloadDashboard: props.reloadDashboard,
			efipPercentage: 0,
		}
		this.editModalRef = React.createRef();
	}

	componentWillUnmount() {
		this.unlisten();
	}

	componentDidMount(){
		if(this.state.uid) {
			this.loadData();
			db.saveViewedProfile(this.state.uid, this.state.authUid);
		}
		this.checkOnOwnPage();
		
		this.setState({
			nickname: this.props.nicknames[this.props.match.params.userId]
		})

		this.unlisten = this.props.history.listen((location, action) => {
			//console.log("route changed");
			this.setState({
				uid: this.props.match.params.userId,
				currentUid: this.props.match.params.userId,
				nickname: this.props.nicknames[this.props.match.params.userId]
			});
			// console.log(this.props.match.params.userId);
			// console.log(this.props.nicknames);
			// this.state.f_updateCurrentUid(this.props.match.params.userId);
		});
	}

	checkOnOwnPage = () => {
		this.setState({
			ownPage: this.state.authUid === this.props.match.params.userId
		}, ()=>{
			//console.log('this.state.ownPage: ', this.state.ownPage)
		})
	}

	loadData(){
		const component = this;
		const promises = [];
		this.setState({
			loading: true
		});

		promises.push(
			db.loadFavouritesForUser(this.state.uid)
				.then(data => {
					//console.log("Loaded Favourites: ", data);
					component.setState({
						favourites: data
					})
				})
		);

		promises.push(
			db.getContactFieldsToShow(this.state.uid)
				.then((data)=>{
					//console.log('got contact fields data: ', data);
					component.setState({ 
						personalDetails: data, 
						uid: component.state.uid,
					}, ()=>{
						this.state.f_setChatCurrentPageUser(this.state.uid, this.state.personalDetails);
					})
				})	
				.catch((err) => {
					console.error("Error retrieving hidden fields: ", err);
				})
		);

		promises.push(
			db.getEmailMeStatus(this.state.uid)
				.then(emailMeStatus => {
					// console.log("emailMeStatus: ", this.state.uid, emailMeStatus);
					// Add to both own property as well as property under personal details for now
					component.setState({
						emailMe: emailMeStatus,
						personalDetails: { ...this.state.personalDetails, EmailMe: emailMeStatus }
					});
				})
		);

		promises.push(
            db.getAdditionalDetails(this.state.uid)
                .then((data) => {
					//console.log('Retrieved additionalDetails: ', data);
                    component.setState({
                        additionalDetails: data
                    })
                })
                .catch(err => console.error("Error retrieving additionalDetails: ", err))
        )

		promises.push(
			db.loadLookingFor(this.state.uid)
				.then((data) => {
					//console.log("lookingFor: ", data);
					component.setState({
						lookingFor: data,
					})
				})
				.catch(err => {
					console.error("Error retrieving lookingFor: ", err);
				})
		)

		promises.push(
			db.loadAspirations(this.state.uid)
				.then(data => {
					//console.log("aspirations: ", data);
					component.setState({
						aspirations: data
					})
				})
				.catch(err => {
					console.error("Error retrieving aspirations: ", err);
				})
		)

		promises.push(
			db.loadPersonalityAnswer(this.state.uid)
				.then(data => {
					//console.log('got personality data: ', data);
					//if(data.length) {
						component.setState({
							personalityCode: data.personalityCode,
							personalityValues: data.personalityValues//[data.O, data.C, data.E, data.A, data.N]
						});
					//}
				})
				.catch(err => console.error("Error retrieving personality data: ", err))
		);
		promises.push(
			db.loadRelationshipHistory(this.state.uid)
				.then(data => {
					//console.log("got Relationship History data: ", data);
					component.setState({
						relationshipHistory: data
					})
				})
		);
		promises.push(
			db.loadReferences(this.state.uid)
				.then(data => {
					//console.log("Got Reference History data: ", data);
					component.setState({
						references: data
					})
				})
		);
		promises.push(
			db.getSkills(this.state.uid)
				.then(data => {
					//console.log('Got skills data: ', data);
					if(data && data.error) {
						this.setState({
							dbError: true
						})
					}
					else {
						component.setState({
							skills: data
						})
					}
				})
		);

		promises.push(
			db.loadHiringFor(this.state.uid)
				.then(data => {
					component.setState({
						hiringFor: data
					});
				})
		);

		promises.push(
			db.loadLonelinessLevel(this.state.uid)
				.then(data => {
					component.setState({
						lonelinessLevel: data
					});
				})
		);

		promises.push(
			db.loadMustNotHave(this.state.uid)
				.then(data => {
					//console.log('mustNotHave: ', data);
					component.setState({
						mustNotHave: data
					});
				})
		)

		promises.push(
			db.loadChatQuotes(this.state.uid)
				.then(data => {
					component.setState({
						chatQuotes: data
					});
				})
		)

		promises.push(
			db.loadFAQ(this.state.uid)
				.then(data => {
					component.setState({
						faq: data
					});
				})
		)

		Promise.all(promises).then(()=>{
			this.setState({
				loading: false
			})
		});
	}

	componentDidUpdate(){
		const component = this;
		if(this.state.currentUid !== this.props.currentUid || this.state.uid !== this.props.match.params.userId){
			this.setState({
				currentUid: this.props.currentUid,
				uid: this.props.match.params.userId
			}, ()=> {
				this.checkOnOwnPage();
				this.loadData();
			})
		}
		if(this.state.authUid !== this.props.authUid){
			this.setState({
				authUid: this.props.authUid
			}, ()=> {
				this.checkOnOwnPage();
				this.loadData();
			})
		}

		if(this.state.reloadDashboard !== this.props.reloadDashboard){
			this.setState({
				reloadDashboard: this.props.reloadDashboard
			}, ()=>{
				db.loadFavouritesForUser(this.state.uid)
					.then(data => {
					//console.log("Reloading Favourites: ", data);
					component.setState({
						favourites: data
					})
				})
			})
		}

		if(this.state.nicknames !== this.props.nicknames){
			this.setState({
				nicknames: this.props.nicknames,
				nickname: this.props.nicknames[this.props.match.params.userId],
			});
		}

		if(this.state.profiles !== this.props.profiles 
			//|| this.state.uid !== this.props.match.params.userId
			//|| this.state.currentUid !== this.props.currentUid){
		){
			const component = this;
			/*console.log("this.state.uid: ", this.state.uid);
			console.log("this.props.match.params.userId: ", this.props.match.params.userId);
			console.log("this.state.profiles: ", this.state.profiles);
			console.log("this.props.profiles", this.props.profiles);
			console.log("this.state.currentUid: ", this.state.currentUid);
			console.log("this.props.currentUid: ", this.props.currentUid);*/
			this.setState({
				profiles: this.props.profiles,
				uids: Object.keys(this.props.profiles),
				uid: this.props.match.params.userId,
				authUid: this.props.authUid
			}, ()=>{
				component.checkOnOwnPage();
				this.loadData();
			})
		}
		
	}
/*
	_contactRequest = (targetId) => {
		if(global.authUid.length) db.createContactRequest(global.authUid, targetId);
		else {
			alert("Sign in to Say Hi");
		}
	}
	*/

	_loadNextUser = () => {
		const uid = this.props.loadNextPage();
		this.props.history.location.pathname = "/";
		this.props.history.push("/user/" + uid);
	}

	fn_updateReferencePopup = (b, newTitle, headerText) => {
		this.setState({
			showEmailModal: b,
			currentEmailModalTitle: newTitle,
			emailModalHeaderText: headerText
		})
	}

	fn_showModal = (type, msgFrom, msgTo, data, options) => {
		// console.log('showing modal of type: ', type);
		this.setState({
			currentEditTitle: type,
			msgFrom: msgFrom,
			msgTo: msgTo,
			editModalData: data,
			options: options,
			showEditModal: true
		}, ()=>{
			this.editModalRef.current.style.display = 'flex';
			//this.forceUpdate();
		})
	}

	fn_hideModal = () => {
		//this.editModalRef.current.style.display = 'none';
		this.setState({
			showEditModal: false
		});
	}

	f_updateAdditionalDetails = (selected) => {
		//console.log("updated details: ", selected)
		this.setState({
			selectedAdditionalDetails: selected
		})
	}

	// d is used to edit fields that already exist, in this case the id is passed to edit this existing field
	f_saveCallback = async (type, d) => {
		//console.log('save callback called: ', type); 
		const data = document.getElementsByClassName("m-modal-toRead"); // shared class to read values from

		this.updateEditModalLoader(true);
		const promises = [];
		// console.log(this);
		// Needs to be reinitialized here for some reason
		const ll = require('../language.js');
		// console.log(ll.get('PD_TITLE'));
		// console.log(type);
		/* eslint-disable-next-line no-use-before-define */
		switch(type){
			
			case ll.get('PD_TITLE'):
					const personalDetails = {};		
					Array.from(data).forEach((field)=>{	
						if(field.value && field.value.length){
							if(field.name === "EmailMe"){
								// This field is not added to personal details, but directly under user uid
								promises.push(db.setEmailMe(this.state.uid, field.value));
								this.setState({
									emailMe: field.value,
									personalDetails: { ...this.state.personalDetails, EmailMe: field.value }
								});
							} else {
								personalDetails[`${field.name}`] = field.value;
								promises.push(db.setContactFieldToEdit(this.state.uid, field.name, true, field.value));
							}
						}
					});
					
					promises.push(
						db.saveAdditionalDetails(this.state.uid, this.state.selectedAdditionalDetails)
					)
					this.setState({
						personalDetails: personalDetails
					}, ()=>{
						//console.log('new personal details set: ', this.state.personalDetails);
					});
					
				break;
			case "Personality Chart":
				
				break;
			case 'Open Positions':
				const op = {};
				Array.from(data).forEach(field => {
					if(field.name === "tenure"){
						op.tenure = field.innerHTML;
					} 
					else 
						op[`${field.name}`] = field.checked || field.value;
				});
				//console.log(op);
				promises.push(db.saveHiringFor(this.state.uid, op));
				this.setState({
					hiringFor: op
				});
				break;
			case 'Loneliness':
				const l = {};
				Array.from(data).forEach(field => {
					l[`${field.name}`] = field.value;
				});
				promises.push(db.saveLonelinessLevel(this.state.uid, l));
				this.setState({
					lonelinessLevel: l
				});
				break;
			case "Qualities":
				const skills = [];
				Array.from(data).forEach(field => {
					skills.push(field.innerHTML);
				});
				promises.push(db.saveSkills(this.state.uid, {Skills: skills}));
				this.setState({
					skills: skills
				})
				break;
			case "Looking For":
				const lf = {};
				Array.from(data).forEach(field => {
					if(field.value && field.value.length && field.value !== "true"){
						if(field.name === "Long Distance is ok"){
							lf["Long Distance is ok"] = field.checked;
						} 
						else 
							lf[`${field.name}`] = field.value;
					}
				});
				console.log("SAVING: ", lf);
				var newId;
				promises.push(db.saveLookingFor(this.state.uid, lf)
					.then(id => {
						newId = id;
						//if(!d){
						//	const lookingFor = this.state.lookingFor;
						//	lf.id = id;
						//	lookingFor.push(lf);
							this.setState({
								lookingFor: [{...lf, id: id }]//lookingFor
							})
							// console.log(this.state);
						//} else {
							//this.loadData();
							//TODO get the one to edit specifically rather than calling all updates
						//}
					}));
				break;
			case "Aspirations":
				const a = {};
				const component = this;
				Array.from(data).forEach(as => {
					if(as.value && as.value.length){
						a[`${as.name}`] = as.value;
					}
				});
				//var newId;
				promises.push(db.saveAspiration(this.state.uid, a)
					.then(id => {
						newId = id;
						//console.log('aspiration:: ', a);
						//console.log('new id: ', newId)
						//console.log('d: ', d);
						//if(!d){
							//const aspirations = this.state.aspirations;
							//a.id = id;
							//aspirations.push(a);
							component.setState({
								aspirations: [{...a, id: id}]//aspirations
							})
						//} else {
							//this.loadData();
						//}
					}));
				break;
			case "Must Not":
				const mnh = {};
				Array.from(data).forEach(field => {
					if(field.value && field.value.length && field.value !== "true"){
						mnh[`${field.name}`] = field.value;
						if(d){
							mnh.id = d;
						}
					}
				});
				promises.push(db.saveMustNotHave(this.state.uid, mnh).then((id)=>{
					const oldMnh = this.state.mustNotHave;
					// If the value was edited
					if(d){
						// Find the mnh with the id we want to modify (should be using a dictionary for this)
						oldMnh[id.toString()] = mnh;
					}
					// Value was not edited
					else {
						oldMnh[id.toString()] = {...mnh, id: id};
					}
					//console.log('new mnh: ', oldMnh);
					this.setState({
						mustNotHave: oldMnh
					});
				}))
				break;
			case 'FAQ_ASK':
			case 'FAQ_ANSWER':
				//console.log(d);
				const faq = {};
				if(!d) d = Date.now().toString();
				Array.from(data).forEach(field => {
					// There's only one question
					faq.timestamp = d;
					if(type === 'FAQ_ASK'){
						faq.question = field.value;
					} else if(type === 'FAQ_ANSWER'){
						faq.question = this.state.faq[d].question;
						faq.answer = field.value;
					}
				})
				promises.push(db.saveFAQ(this.state.uid, d, faq).then(id => {
					const _faq = this.state.faq;
					if(_faq[id]){
						_faq[id] = faq;
					} else {
						_faq[id] = {...faq, id: id};
					}
					this.setState({
						faq: _faq
					});
					if(type === 'FAQ_ASK'){
						// This is a new question, send an email
						helpers.sendNewQuestionEmail(this.state.uid, _faq[id]);
					}
				}));
				break;
			case "Relationship History":
				const rh = {};
				Array.from(data).forEach(field => {
					//console.log('field: ', field);
					//console.log('field.value: ', field.value);
					//console.log('field.labelName: ', field.name);
					if(field.value && field.value.length && field.value !== "true"){
						if(field.name === "Long Distance") 
							rh["Long Distance"] = field.checked;
						else 
							rh[`${field.name}`] = field.value;
					}
				});
				db.saveRelationshipHistory(
					this.state.uid, 
					d,
					rh.Country, 
					rh.City,
					rh["Long Distance"], 
					rh["Start DateYear"],
					rh["Start DateMonth"],
					rh["End DateYear"],
					rh["End DateMonth"],
					rh.Description)
					.then(id=>{
						newId = id
						// Adding a new one
						if(!d){
							const relationshipHistory = this.state.relationshipHistory;
							relationshipHistory[newId] = {
								id: newId,
								country: rh.Country,
								city: rh.City,
								longDistance: rh["Long Distance"],
								startDateYear: rh["Start DateYear"],
								startDateMonth: rh["Start DateMonth"],
								endDateYear: rh["End DateYear"],
								endDateMonth: rh["End DateMonth"],
								desc: rh.Description
							};
							this.setState({
								relationshipHistory: relationshipHistory
							})
						// Editing
						} else {
							//const relationshipHistory = this.state.relationshipHistory;
							this.loadData();
							//TODO get the one to edit specifically rather than calling all updates
						}
					});
				break;
			case "References":
				if(Object.keys(d).length && Object.keys(d)[0].length){
					// Add a default pending message in, to be updated after the reference is created
					db.saveReference(this.state.uid, d.referenceId, {
						pending: d.email,
						msg: "",
						verified: false
					}).then(referenceId => {
						const references = this.state.references;
						references.push({
							id: referenceId,
							pending: d.email,
							msg: "",
							verified: false
						})
						this.setState({
							references: references
						});
							})
						}
				break;
			default: break;
		}
		await Promise.all(promises);
		//console.log("promises complete");
		this.updateEditModalLoader(false);
		this.fn_hideModal();
	}

	f_deleteCallback = async (type, id) => {
		//console.log('delete callback called: ', type, ', ', id);
		const promises = [];
		this.updateEditModalLoader(true);
		switch(type){
			case "Must Not":
				promises.push(
					db.deleteMustNotHave(this.state.uid, id)
						.then(()=>{
							const obj = this.state.mustNotHave;
							delete obj[id.toString()];
							this.setState({
								mustNotHave: obj
							});
						})
				);
				await Promise.all(promises);
				this.updateEditModalLoader(false);
				this.fn_hideModal();
				break;
			case "Relationship History":
				//console.log('id: ', id);
				promises.push(
					db.deleteRelationshipHistory(this.state.authUid, id)
						.then(()=>{
							//console.log('deleted and removing: ', id);
							const obj = this.state.relationshipHistory;
							delete obj[id.toString()];
							console.log(obj);
							this.setState({
								relationshipHistory: obj
							})
						})
				);
				await Promise.all(promises);
				this.updateEditModalLoader(false);
				this.fn_hideModal();
				break;
			case "References":
				promises.push(db.deleteReference(this.state.authUid, id));
				await Promise.all(promises);
				//this.fn_updateReferencePopup(false);
				this.updateEditModalLoader(false);
				this.fn_hideModal();
				//TODO fix to make more efficient
				this.loadData();
				break;
			case "Aspirations":
				promises.push(db.deleteAspiration(this.state.authUid, id));
				await Promise.all(promises);
				this.updateEditModalLoader(false);
				this.fn_hideModal();
				break;
			case 'Loneliness':
				promises.push(db.deleteLonelinessLevel(this.state.uid, id));
				await Promise.all(promises);
				this.updateEditModalLoader(false);
				this.fn_hideModal();
				break;
			case 'FAQ_ASK':
			case 'FAQ_ANSWER':
				promises.push(db.deleteFAQ(this.state.uid, id));
				await Promise.all(promises);
				this.updateEditModalLoader(false);
				this.fn_hideModal();
				//TODO fix to make more efficient
				const faq = this.state.faq;
				delete faq[id];
				this.setState({
					faq: faq
				});
				break;
			default: break;
		}
	}

	updateEditModalLoader = (b) => {
		this.setState({
			editModalLoading: b
		})
	}
	msgCallback = () => {
		this.fn_showModal('Send a message', this.state.authUid, this.state.uid);
	}

	fn_sendNewMsg = (uid) => {
		//TODO create a container for a new message
	}

	f_updateFloatingHeaderData = (totalRequests, accepted, rejected) => {
		this.setState ({
			totalRequests: totalRequests,
			accepted: accepted,
			rejected: rejected
		});
	}

	f_favorite = (currentUid, targetUid) => {
		//console.log("reloading this user's favourites");
		const component = this;
		return db.createFavourite(currentUid, targetUid).then(()=>{
			//console.log("Saved to Favourites successfully");
			return db.loadFavouritesForUser(this.state.uid)
				.then(data => {
					//console.log("Loaded Favourites: ", data);
					component.setState({
						favourites: data
					})
					return 1;
				})
		}).catch(err => {
			console.error("Error saving to favourites: ", err)
			return 0;
		});
	}

	f_removeChatQuote = (id) => {
		const cq = this.state.chatQuotes;
		delete cq[id];
		this.setState({
			chatQuotes: cq
		});
	}

	f_updateEFIPPercentage = (newPercentage) => {
		this.setState({
			efipPercentage: newPercentage
		});
	}

	render(){
		//console.log(this.state.profiles);
		//console.log(this.state.uid);
		//console.log("this.state.personalityValues: ", this.state.personalityValues)
		//console.log('this.state.aspirations: ', this.state.aspirations);
		//console.log('global.showGetStartedStatus: ', global.showGetStartedStatus);
		// console.log('rerendering: ', this.state.efipPercentage > 60);
		return(
			<div className="g-default-bg" style={{margin:'auto', padding: '0 30px'}}>
				{
					this.state.loading ?
					<Loader />
					:
					<div>
						{
							this.state.dbError
							? <DBError />
							:<div>
								{
									this.state.showEditModal
									? <EditModal 
										title={this.state.currentEditTitle}
										updateBody={editBody}
										body={editBody(this.state.currentEditTitle,this.state.editModalData, this.f_updateAdditionalDetails)}
										currentData={this.state.editModalData}
										parentRef={this.editModalRef} 
										fn_hideModal={this.fn_hideModal}
										showLoading={this.state.editModalLoading}
										msgFrom={this.state.msgFrom}
										msgTo={this.state.msgTo}
										f_saveCallback={this.f_saveCallback}
										f_deleteCallback={this.f_deleteCallback}
										options={this.state.options || {}} />
									: <div></div>
								}
							
								{ 
									this.state.showEmailModal && 
									<EmailModal 
										title={this.state.currentEmailModalTitle}
										headerText={this.state.emailModalHeaderText}
										fn_updateReferencePopup={this.fn_updateReferencePopup} 
										f_saveCallback={this.f_saveCallback}/> 
								}	

								{
									this.state.ownPage ?// && global.showEncouragementStatus ?
									<div className="g-default-container g-791">
										{ global.showGetStartedStatus && <h2>{this.state.efipPercentage < 60 ? l.get('EFIP_TITLE') : l.get('EFIP_TITLE2')}</h2> }
										<EncourageFillInProfile 
											lookingFor={this.state.lookingFor}
											aspirations={this.state.aspirations}
											relationshipHistory={this.state.relationshipHistory || []}
											personalDetails={this.state.personalDetails}
											mustNotHave={this.state.mustNotHave}
											references={this.state.references}
											skills={this.state.skills}
											authUid={this.state.authUid}
											fn_showModal={this.fn_showModal}
											fn_updateReferencePopup={this.fn_updateReferencePopup}
											f_updateEFIPPercentage={this.f_updateEFIPPercentage} />
									</div>
									: ""
								}	

								{
									!this.state.ownPage 
									&& (global.showGetStartedStatus)// || global.showGetStartedStatus === undefined) 
									&& (localStorage.getItem('uid') || global.authUid)
									? <GetStartedProfile uid={localStorage.getItem("uid") || global.authUid}/>
									: ""
								}

								{ !this.state.ownPage && (localStorage.getItem('uid') || global.authUid) && 
									<LikeArea uid={this.state.uid} authUid={this.state.authUid} myLikes={this.props.myLikes} />
								}

								{
									!this.state.ownPage 
									&& (localStorage.getItem('uid') || global.authUid)
									&& Object.values(this.state.personalDetails).filter((f)=>{return f !== undefined}).length 
									? <div className="g-default-container g-791 g-relative btn-hoverable">
										<h2>{l.get('NN_TITLE')} <span className="g-soft-text">({l.get('NN_DESCRIPTION')})</span></h2>
										<Nickname 
											nickname={this.state.nickname} 
											personalDetails={this.state.personalDetails} 
											authUid={this.state.authUid}
											currentUid={this.props.match.params.userId}
											fn_showModal={this.fn_showModal} 
										/>
									</div>
									: ""
								}
								
								{	// Check if there is data to be displayed
									this.state.ownPage || Object.values(this.state.personalDetails).filter((f)=>{return f !== undefined}).length 
									? <div>

										{/* { this.state.ownPage || (this.state.favourites || []).length ? */}
										<div className="g-default-container g-791">
											<h2>Dashboard</h2>
											<Favourites2 
												{...this.props} 
												f_updateFloatingHeaderData={this.f_updateFloatingHeaderData}
												favourites={this.state.favourites}/>
										</div>
										{/* : ""
										} */}

										<div className="g-default-container g-791">
											{!Object.values(this.state.personalDetails).filter((f)=>{return f !== undefined}).length && 
												<h2>{l.get('PD_TITLE')}</h2> 
											}
											<MainDetails {...this.props} 
												uid={this.state.uid}
												ownPage={this.state.ownPage} 
												fn_showModal={this.fn_showModal} 
												fn_refer2Friend={this.fn_updateReferencePopup}
												fn_sendNewMsg={this.fn_sendNewMsg}
												f_favorite={this.f_favorite}
												authUid={this.state.authUid}
												totalRequests={this.state.totalRequests}
												accepted={this.state.accepted}
												rejected={this.state.rejected}
												personalDetails={this.state.personalDetails}
												personalityValues={this.state.personalityValues}
												additionalDetails={this.state.additionalDetails}
												myFavourites={this.props.myFavourites || []}
												f_reloadFavourites={this.props.f_reloadFavourites} />

										</div>

										{ this.state.ownPage || Object.keys(this.state.hiringFor).length	
											? <div className="g-default-container g-791 g-relative btn-hoverable">
												<h2>{l.get('HFP_TITLE')}
												{ this.state.hiringFor && this.state.hiringFor.tenure 
													? " (" + this.state.hiringFor.tenure + ")"
													: "" 
												}
												</h2>
												<HireForPosition 
													ownPage={this.state.ownPage}
													hiringFor={this.state.hiringFor}
													fn_showModal={this.fn_showModal} />
											</div>
											: ""
										}

										{/* { this.state.ownPage || (this.state.lookingFor || []).length
											? <div className="g-default-container g-791 g-relative btn-hoverable">
												<h2>Requirements</h2>
												<LookingFor 
													{ ...this.props}
													ownPage={this.state.ownPage}
													fn_showModal={this.fn_showModal}
													lookingFor={this.state.lookingFor} />
												</div>
											: ""
										}

										{ this.state.ownPage || Object.keys(this.state.mustNotHave).length
											? <div className="g-default-container g-791">
												<h2>Must Not</h2>
												<MustNotHave
													ownPage={this.state.ownPage}
													fn_showModal={this.fn_showModal}
													mustNotHave={this.state.mustNotHave || []} />
											</div>
											: ""
										} */}

										{
											this.state.ownPage 
											|| (this.state.lookingFor || []).length 
											|| this.state.mustNotHave.length
											? <RequirementsCombined 
												{...this.props}
												ownPage={this.state.ownPage}
												fn_showModal={this.fn_showModal}
												mustNotHave={this.state.mustNotHave || []}
												lookingFor={this.state.lookingFor}
											/>
											: ""
										}

										{ this.state.ownPage || (this.state.aspirations || []).length
											? <div className="g-default-container g-791 g-relative btn-hoverable">
												<h2>{l.get('A_TITLE')}</h2>
												<Aspirations 
													{...this.props}
													ownPage={this.state.ownPage}
													fn_showModal={this.fn_showModal}
													aspirations={this.state.aspirations} />
											</div>
											: ""
										}
										{ this.state.ownPage || (this.state.skills || []).length 
											? <div className="g-default-container g-791 g-relative btn-hoverable">
												<h2>{l.get('S_TITLE')}</h2>
												<Skills {...this.props}
													ownPage={this.state.ownPage}
													fn_showModal={this.fn_showModal}
													skills={this.state.skills || []} />
											</div>
											: ""
										}

										{/* { this.state.ownPage || Object.keys(this.state.lonelinessLevel).length	
											? <div className="g-default-container g-791 g-relative btn-hoverable">
													{
														!Object.keys(this.state.lonelinessLevel).length 
														? <h2>Loneliness</h2>
														: ""
													}
													<LonelinessLevel
														ownPage={this.state.ownPage}
														lonelinessLevel={this.state.lonelinessLevel}
														fn_showModal={this.fn_showModal} />
											</div>
											: ""
										} */}
										
										{ this.state.ownPage || Object.keys(this.state.relationshipHistory).length
											? <div className="g-default-container g-791">
												<h2>{l.get('RH_TITLE')}</h2>
												<RelationshipHistory {...this.props} 
													ownPage={this.state.ownPage}
													fn_showModal={this.fn_showModal}
													relationshipHistory={this.state.relationshipHistory || []} />
											</div>
											: ""
										}
										
										<div className="g-default-container g-791">
											<h2>{l.get('FAQ_TITLE')}</h2>
											<FAQ 
												ownPage={this.state.ownPage}
												fn_showModal={this.fn_showModal}
												faq={this.state.faq}
											/>
										</div>

										{ this.state.ownPage || Object.keys(this.state.chatQuotes || {}).length
											? <div className="g-default-container g-791">
												<h2>{l.get('CQ_TITLE')}</h2>
												<ChatQuotes 
													uid={this.state.uid}
													ownPage={this.state.ownPage}
													chatQuotes={this.state.chatQuotes || {}}
													f_removeChatQuote={this.f_removeChatQuote}
												/>
											</div>
											: ""
										}

										{ this.state.ownPage || (this.state.references || []).length
											? <div className="g-default-container g-791">
												<h2>{l.get('R_TITLE')}</h2>
												<References {...this.props}
													ownPage={this.state.ownPage}
													fn_showModal={this.fn_updateReferencePopup}
													fn_showEditModal={this.fn_showModal}
													references={this.state.references || []} />
											</div>
											: ""
										}	
									</div>
									: <ProfileIsBlank />
								}

								{
									!this.state.ownPage
									&& !global.authUid.length && !localStorage.getItem("uid").length
									? <SignUpPrompt />
									: ""
								}
							</div>
						}	
					</div>
				}
			</div>
		);
	}
}

function editBody (type, data, f_updateAdditionalDetails){
	// console.log('data: ', data ? data : "");
	switch(type){
		case l.get('PD_TITLE'): 
			var d;	
			if (data){
					d = data.additionalDetails;
					data = data.personalDetails;
			} 
			// Called from EncouragementFillInProfile
			else {
				d = {};
				data = {
					Gender: "Male",
					Availability: "Yes",
					// EmailMe: "No",
				};
			}
			//console.log('d: ', d);
			return <div id="ANALYTICS_mainDetailsEditPressed">
				<EditLabel label={l.get('PD_FIELDS_ARE_NOT_VISIBLE')} />
				<EditInput label={l.get('PD_FIRST_NAME')} mandatory={true} default={data ? localStorage.getItem("displayName").split(" ")[0] : ""} />
				<EditInput label={l.get('PD_LAST_NAME')} mandatory={true} default={data ? localStorage.getItem("displayName").split(" ")[1] : ""}/>
				<EditInput label={l.get('PD_EMAIL')} type="email" default={data ? localStorage.getItem("email") : ""} noedit={true}/>
				<hr />
				<EditLabel label={l.get('PD_FIELDS_ARE_VISIBLE_WARNING')} />

				<EditRadio name={l.get('PD_GENDER')} classes={["Male","Female","Other"]} currentSelected={data.Gender || "Male"} />
				<EditInput label="Date of birth" type="date"  default={data ? data["Date of birth"] : ""}/>
				<EditInput label="Your Location"  default={data ? data["Your Location"] : ""} />
				{/* <EditInput label="Facebook Page URL" type="url" /> */}
				<EditInput label="Occupation"  default={data ? data.Occupation : ""} />
				<EditTextArea label={`About you in ${240} characters`} default={data ? data["About Me"] || data["About you in 75 characters"] || data["About you in 240 characters"] : ""} textLimit={240} height="32px" />
				<hr />
				<EditLabel label="A few specific things" />
				<AdditionalDetailsSelection default={d ? d : []} f_updateAdditionalDetails={f_updateAdditionalDetails} />
				<hr />
				<EditRadio name="Availability" label={l.get('PD_AVAILABLE')} classes={["Yes","No"]} currentSelected={data.Availability || "Yes"} />
				<EditRadio name="EmailMe" label={l.get('PD_EMAILME')} classes={["Yes", "No"]} currentSelected={data.EmailMe || "Yes"} />
			</div>;
		case l.get('S_TITLE'): //"Qualities"
			return <div id="ANALYTICS_qualitiesEditPressed">
				{/* <EditLabel label="Choose five to best describe yourself" /> */}
				<label className="editLabelLabel g-inlineblock">{l.get('S_EDIT_1')}</label>
				<label className="editLabelLabel g-inlineblock" style={{color:"red",marginLeft:"5px",marginRight:"5px"}}>{l.get('S_EDIT_2')}</label>
				<label className="editLabelLabel g-inlineblock">{l.get('S_EDIT_3')}</label>
				<QualitiesSelection default={data ? data : []} />
			</div>
		//case l.get('HFP_TITLE'):
		case l.get('LF_TITLE'):
			data = data && data.length ? data[0] : undefined;
			return <div>
				<div className="g-inlineblock" style={{}} id="ANALYTICS_lookingForEditPressed">
					<label className="editInputLabel g-inline-block">{l.get('HFP_EDIT_LABEL')}</label>
					<SelectStyledLikeText 
						name="gender"
						default={data ? data.gender : ""}
						options={[l.get('HFP_EDIT_GENDER_DEFAULT'), l.get('HFP_EDIT_GENDER_MAN'),l.get('HFP_EDIT_GENDER_WOMAN'),l.get('HFP_EDIT_GENDER_PERSON')]} />
					{/* <EditRadio name="Gender" classes={["Male","Female","Doesn't matter"]} currentSelected={"Male"}  default={data ? data.Gender : ""}/> */}
					<EditAgeRange label="Age range" default={data ? data["ageRange-from"] + '-' + data["ageRange-to"] : ""}/>
				</div>
				<br/>
				<EditHeight default={data ? data.Height : ""} defaultType={data ? data.heightType : ""} />
				{/* Removing culture haha */}
				{/* <EditCultural default={data ? data.cultureType : ""} /> */}
				<br/>
				{/* <EditCheckbox label="Long Distance" default={data ? data.longDistance : ""} /> */}
				{/* <EditRadio name="Wealth" label="Wealth" classes={["Average","Rich","Doesn't matter"]} currentSelected={"Doesn't matter"}  default={data ? data.Wealth : ""}/> */}
				<EditWealth default={data ? data.wealthType : ""}/>
				
				<EditStarRating label="And looks" name="Attractiveness" starCount={10} default={data ? Number(data.rating) : 0 }/>
				{/* <EditTextArea label="Also" default={data ? data["Special requirements"] : ""} textLimit={100} height="50px" placeholder={"Any additional requirements..."} /> */}
				<EditSpecialRequest default={data ? data["Special Requirements"] : ""} />
			</div>
		case l.get('MN_TITLE'):
			return <div>
				<EditLabel label="What will you not tolerate? What quirks do you refuse to deal with?" color={"rgba(0,0,0,.6)"} />
				<br/>
				<div className="g-flex g-mg-t-10">
					<div className="g-inlineblock g-mg-r-6 g-mg-t-6 g-ww-nowrap" style={{fontSize:"1.6rem",fontWeight:"600"}}>{l.get('MN_TITLE')}</div>
					<EditTextArea 
						label="value" 
						default={data ? data["value"] : ""} 
						textLimit={75} 
						height="32px" 
						dontShowLabel={true} 
						onlyBottom={true}
						width="100%" />
				</div>
			</div>
		case "Aspirations":
			return <div id="ANALYTICS_aspirationsEditPressed">
				<EditLabel label="What do you want to achieve, either now or in five years" />
				<br/>
				<EditTextArea label="Aspirations" default={data ? data["Aspirations"] : ""} textLimit={250} height="150px" dontShowLabel={true}/> 
			</div>;
		case "Loneliness":
			return <div>
				{/* <label className="editLabelLabel">Loneliness has recently become a global epidemic <a>Learn more</a></label> */}
				{/* <EditLabel label="How often do you feel lonely?" /> */}
				<EditRadio name="Loneliness" width={"100%"} label="How often do you feel lonely?" classes={["Never","Hardly ever","Occasionally","Some of the time","Often/Always"]} currentSelected={data ? data.Loneliness : "Occasionally"} />
				{/* Vertical chart */}
				<EditTextArea label="Why is this the case?" default={data ? data["Why is this the case?"] : ""} textLimit={150} height="56px" />
                
			</div>
		case 'FAQ_ANSWER':
			return <div id="ANALYTICS_faqAddPressed">
				<EditLabel label={data ? data.question: "Question"} />
				<EditTextArea label="Answer" textLimit={250} default={data ? data.answer : ""} height="112px" />
			</div>
		case 'FAQ_ASK':
			//TODO no defaults on FAQ sections, no edits either
			return <div>
				<EditTextArea label="What would you like to know?" textLimit={150} height="56px" />
			</div>
		case "Personality Chart":
			return <div>
			</div>;
		case "Relationship History":
			return <div id="ANALYTICS_relationshipHistoryEditPressed">
				<EditCountry label="Country" default={data ? data.country : ""} />
				<EditInput label="City" default={data ? data.city : ""} />
				<EditCheckbox label="Long Distance" default={data ? data.longDistance : ""} />

				<div className="g-flex g-row g-space-between" style={{width:"70%"}}>
					<EditDate name="Start Date" mandatory={true} default={data ? data.startDateYear + "-" + data.startDateMonth : ""} />
					<EditDate name="End Date" mandatory={true} default={data ? data.endDateYear + "-" + data.endDateMonth : ""}/>
				</div>
				<EditTextArea label="Description" default={data ? data.desc : ""} />
			</div>
		case 'Open Positions':
			return <div>
				{/* <EditCheckbox label="Part time" default={data ? data["Part time"] : ""} />
				<EditCheckbox label="Full time" default={data ? data["Full time"] : ""} />
				<EditCheckbox label="Fixed term contract" default={data ? data["Fixed term contract"] : ""} />
				<EditCheckbox label="Permanent temporary" default={data ? data["Permanent temporary"] : ""} /> */}
				<SelectStyledLikeText 
					name="tenure"
					width={"250px"}
					default={data ? data.tenure : "Select a frequency"} //"Select an intensity"
					options={["Select a frequency","Part Time","Full Time","Fixed Term Contract","Permanent Temporary","Casual"]} 
				/>
				<EditCheckbox label="Someone to talk to" default={data ? data["Someone to talk to"] : ""} />
				<EditCheckbox label="Really good friend" default={data ? data["Really good friend"] : ""} />
				<EditCheckbox label="Best friend" default={data ? data["Best friend"] : ""} />
				<EditCheckbox label="Girlfriend" default={data ? data.Girlfriend : ""} />
				<EditCheckbox label="Boyfriend" default={data ? data.Boyfriend : ""} />
				<EditCheckbox label="Wife" default={data ? data.Wife : ""} />
				<EditCheckbox label="Husband" default={data ? data.Husband : ""} />
				<EditCheckbox label="Friend with benefits" default={data ? data["Friend with benefits"] : ""} />
				<EditCheckbox label="Friend (without benefits)" default={data ? data["Friend (without benefits)"] : ""} />
				{/* <EditCheckbox label="Sugar Daddy" default={data ? data["Sugar Daddy"] : ""} /> */}
				{/* <EditCheckbox label="Cougar" default={data ? data.Cougar : ""} /> */}
				<EditCheckbox label="Someone to watch Fireworks with" default={data ? data["Someone to watch Fireworks with"] : ""} />
				{/* <EditCheckbox label="Someone to watch Netflix & Chill with" default={data ? data["Someone to watch Netflix & Chill with"] : ""} /> */}
				{/* <EditCheckbox label="Someone to watch Netflix & Chill with while watching Fireworks" default={data ? data["Someone to watch Netflix & Chill with while watching Fireworks"] : ""} /> */}
			</div>
		case 'References':
			// This is just for editing the references, creating them is done using EMAILMODAL.jsx
			return <div id="ANALYTICS_referencesAddPressed">
				<EditTextArea label="Reference" default={data ? data.msg : ""} readonly={true} />
			</div>
		case 'Send a message':
			return <div>
				<EditTextArea label="Message" />
			</div>
		default: return <div></div>;
	}
}

function DBError (props){
	return <div style={{marginTop:"7.5%"}}>
		<img src={placeholder} style={{width:"25%"}} className="g-mg-auto g-mg-b-20" alt="" />
		<div style={{width:"50%",margin:"auto",textAlign:"left", paddingLeft:"10%"}}>
			<h4>We couldn't load this page</h4>
			<p>Try:</p>
			<li>Checking your network cables, modem, router</li>
			<li>Reconnecting to Wifi</li>
			<li>Refresh your browser cache</li>
		</div>
		<p style={{marginTop:"50px"}}>Please try again later</p>
	</div>
}

//{/*<OtherProfile gender={this.state.genderData} bornDate={this.state.bornDateData} />*/}
//{/*<button onClick={this.props.loadNextPage}>Don't show this profile again</button>*/}
//<MultiStepComponent authUser={this.state.authUid} profile={this.state.profiles[uid]} />
/*
<div key={index}>
						<img />
</div>
						*/

