import React from 'react';
import l from '../language.js';
import Room from './room.jsx';
import add from '../Assets/add-white.svg';
import close from '../Assets/close-black.svg';
import './roomComponent.css';
import RoomList from './roomList.jsx';
import axios from 'axios';
import config from '../config.js';
import { v1 as uuidv1 } from 'uuid';
import RoomDB from './roomDb.js';

export default class RoomComponent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            rooms: {},
            currentRoom: {name:''},
            showCreateRoomModal: false,
            showTopPopout: true,
            guestId: 0,
        }
    }

    componentDidMount(){
        this.loadActiveRooms();

        const roomAdded = (id, data) => {
            // console.log('room added: ', id, data);
            const rooms = this.state.rooms;
            rooms[id] = data;
            this.setState({
                rooms: rooms
            });
        }
        const roomRemoved = (id) => {
            // console.log('room removed: ', id);
        }
        RoomDB.initRoomListener(roomAdded, roomRemoved);
    }

    f_updateCurrentRoom = (newRoomId) => {
        // console.log('updating current room');
        if(!this.state.rooms[newRoomId]) return;
        this.setState({
            currentRoom: this.state.rooms[newRoomId]
        })
    }

    createRoom(){
        this.setState({
            showCreateRoomModal: true
        })
    }

    loadActiveRooms = async () => {
        RoomDB.loadActiveRooms().then(rooms => {
            // console.log("Successfully retrieved rooms: ", rooms);
            this.setState({
                rooms: rooms,
            })
        })
        
    }

    addRoom = (newId) => {
        // No id means it is new
        // Id means it is an edit
        if(!newId){
            newId = uuidv1();
        }
        //axios.post(config.ROOM_SERVER_ADDRESS + '/addRoom', {
        RoomDB.addRoom(
            newId,
            document.getElementById("rc-input-name").value,
            document.getElementById('rc-input-participants-max').value,
            // document.getElementById('rc-input-duration-min').value,
        ).then(()=>{
            //console.log('DONE: ', res);
            this.setState({
                showCreateRoomModal: false,
            })
        }).catch(err => {
            console.error("Error adding room: ", err);
        })
    }

    joinRoom = (newId) => {
        const uid = global.authUid || localStorage.getItem('uid');
        if(!uid){
            // Create a guest Id
            const id = uuidv1();
            this.setState({
                guestId: id,
                uid: id,
            }, ()=>{ this.joinRoomREQ(id)});
        } else {
            this.joinRoomREQ(uid);
        }
    }

    joinRoomREQ = (id) => {
        axios.post(config.ROOM_SERVER_ADDRESS + '/joinRoom', {
            uid: id,
        }).then((res)=>{
            console.log("Added complete: ", res);
            
        })
        .catch(err => console.error("Error joining room: ", err));
    }

    f_updateCreateRoomModal(b, component){
        component.setState({
            showCreateRoomModal: b
        });
    }

    render(){
        return(
            <div>
                {
                    this.state.showCreateRoomModal && <div>
                        <CreateRoomModal addRoom={this.addRoom} f_updateCreateRoomModal={this.f_updateCreateRoomModal} component={this} />
                    </div>
                }
                {
                    this.state.showTopPopout && <TopPopout />
                }
                <div className="g-flex">
                    <div className="rc-header-left">
                        <div className="g-flex g-space-between rc-title-container">
                            <h2 className="rc-title">{l.get('RC_TITLE')}</h2>
                            <div className="rc-add-container">
                                <img src={add} alt="" className="rc-add-img" onClick={()=>{this.createRoom()}} />
                            </div>
                        </div>
                        <RoomList rooms={this.state.rooms} f_updateCurrentRoom={this.f_updateCurrentRoom} />
                    </div>
                    <div className="rc-header-right">
                        <Room r={this.state.currentRoom} />
                    </div>
                </div>
            </div>
        );
    }
}


function CreateRoomModal(props){
    return <div className="rc-modal-overlay">
        <div className="rc-modal-content">
            <h2 className="rc-modal-title">{l.get('RC_CREATE_MODAL_TITLE')}</h2>
            <img src={close} alt="" onClick={(e)=>props.f_updateCreateRoomModal(false, props.component)} className="m-modal-close" />
            <div className="rc-modal-input-container">
                <label>{l.get('RC_CREATE_MODAL_F1')}</label>
                <input id="rc-input-name" />
            </div>
            <div className="rc-modal-input-container">
                <label>{l.get('RC_CREATE_MODAL_F2')}</label>
                <input id="rc-input-participants-max" type="number"/>
            </div>
            {/* <div className="rc-modal-input-container">
                <label>{l.get('RC_CREATE_MODAL_F3')}</label>
                <input id="rc-input-duration-min" type="number"/>
            </div> */}
            <div id="rc-modal-btn-submit" onClick={()=>{props.addRoom()}}>{l.get('RC_CREATE_MODAL_SUBMIT')}</div>
        </div>
        {l.get('RC_CREATE_MODAL_ADDITIONAL')}
    </div>
}

function TopPopout(props){
    return <div className="rc-topPopout g-relative">
            {/* <div> */}
                {l.get('RC_TOPPOPUP')}
            <button>
                {l.get('RC_TOPPOPUP_CONFIRM')}
            </button>
        {/* </div> */}
        {/* <div> */}
            <span style={{position:"absolute",top:"10px",right:"10px",color:"rgba(255,255,255,.65",textDecoration:"underline"}}>{l.get('RC_TOPPOPUP_DENY')}</span>
        {/* </div> */}
    </div>
}

// axios.get(config.ROOM_SERVER_ADDRESS + '/activeRooms')
//             .then((res) => {
//                 console.log("Successfully retrieved rooms: ", res.data);
//                 this.setState({
//                     rooms: res.data.rooms,
//                 })
//             })
//             .catch(err => {
//                 console.log("Error retrieving active rooms: ", err);
//             })



    // createGuestId = async () => {
    //     axios.get(config.ROOM_SERVER_ADDRESS + '/getGuestUid')
    //         .then((res) => {
    //             console.log("Successfully retrieved guestUid: ", res.data);
    //             this.setState({
    //                 guestId: res.data.guestUid
    //             })
    //         })
    // }