import React from 'react';
import './suggestedProfiles.css';
import db from '../db';
import l from '../language.js';
import helpers from '../helpers.js';
import { Link } from 'react-router-dom';

export default class SuggestedProfiles extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            profiles: {},
            loading: true,
            authUid: props.authUid
        }
    }

    componentDidMount(){

        const cb = (profiles) => {
            const promises = [], suggested = {};

            // Shuffle array
            const shuffled = profiles.sort(() => 0.5 - Math.random());
            // Get sub-array of first n elements after shuffled
            const SUGGEST_COUNT = 10;
            let selected = shuffled.slice(0, SUGGEST_COUNT);
            // Get fields for each uid in randomly shuffled array
            selected.forEach(async (uid)=>{
                promises.push(suggested[uid] = await db.getContactFieldsToShow(uid));
            })
            
            Promise.all(promises).then(()=>{
                this.setState({
                    profiles: suggested,
                    loading: false,

                })
            })
        }
        db.loadAllProfiles(cb, 4);
        // TODO select the most likely profiles
        // Based on what the user is looking for
        // Then add a Recommendation system to select the most likely of those selected profiles
    }

    render(){
        //console.log('this.state.profiles: ', this.state.profiles);
        return(
            <div id="sp-container"> 
                {!this.state.loading && 
                    <h2 id="sp-title">{l.get('SP_TITLE')}</h2>
                }
                <div id="sp-contents">
                {
                    Object.keys(this.state.profiles).map((p,index) => (
                        // Remove current page from suggestions
                        !this.props.history.location.pathname.includes(p) 
                        // If signed in, remove own page from suggestions
                        && ((this.props.authUid && !this.props.history.location.pathname.includes(global.authUid)) || !this.props.authUid)
                        ? <div key={"sp-"+index}>
                            <Suggestion p={this.state.profiles[p]} uid={p} />
                        </div>
                        : ""
                    ))
                }
                </div>
            </div>
        );
    }
}

function Suggestion(props){
    return <Link to={'user/' + props.uid} className="sp-a ANALYTICS_suggestionPressed">
        <div className="sp-suggestion-container">
            <div className="sp-suggestion-line1">
                <span className="sp-identity">
                    {helpers.convertStringToDate(props.p['Date of birth'])}
                    {props.p['Gender'].substring(0,1)}
                </span>
                {props.p['Your Location']}
            </div>
            <div className="sp-suggestion-line2">
                {props.p['Occupation']} 
                <p className="sp-aboutMe">{props.p['About Me']}</p>
            </div>
        </div>
    </Link>
}
