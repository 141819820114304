import React from 'react';

import star from '../Assets/star.svg';
import blank_star from '../Assets/star_blank.svg';

export default class EditStarRating extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            label: props.label,
            name: props.name,
            starCount: props.starCount,
            currentCount: props.default || 5,
            currentHighlight: props.default || 5, 
            finalized: props.default !== undefined || false
        }
    }

    starHighlighted = (index) => {
        //console.log("Star highlighted: ", index);
        this.setState({
            currentHighlight: index + 1,
        })
    }

    starPressed = () => {
        this.setState({
            finalized: true,
            currentCount: this.state.currentHighlight
        })
        document.getElementById("editStarRatingHiddenInput").value = this.state.currentHighlight;
    }

    resetStars = () => {
        this.setState({
            currentHighlight: this.state.currentCount
        });
    }

    b_showYellowStar = (index) => {
        return index < this.state.currentHighlight || index < this.state.currentCount;
    }

    b_makeOpaque = (index) => {
        return (!this.state.finalized && this.b_showYellowStar(index)) 
            || (this.state.finalized && this.b_showYellowStar(index) && index >= this.state.currentCount)
            || (this.state.finalized && this.b_showYellowStar(index) && index < this.state.currentCount && index >= this.state.currentHighlight)
        //(!this.state.finalized && (index < this.state.currentHighlight)) || (index > this.state.currentCount);
    }

    render(){
        return(
            <div className="editStarRating-container g-mg-t-10 g-mg-b-10" 
                onMouseLeave={()=>this.resetStars()}>
                <label className="editInputLabel">{this.state.label}</label><br/>
                <div className="g-mg-t-5 g-inlineblock">
                {
                    [...Array(this.state.starCount)].map((s, index) => (
                        <div key={index} className="g-inlineblock">
                            <img src={
                                    this.b_showYellowStar(index)
                                    ? star : blank_star
                                } 
                                alt="" 
                                className={"editStarRating-img " + (!this.b_showYellowStar(index) ? " star-smaller " : "") } 
                                style={{opacity:`${this.b_makeOpaque(index) ? ".25":"1"}`}}
                                onMouseEnter={()=>this.starHighlighted(index)}
                                onMouseDown={()=>this.starPressed()} />
                        </div>
                    ))
                }
                <input 
                    id="editStarRatingHiddenInput"
                    className="m-modal-toRead"
                    style={{display:"none"}} 
                    name="rating" 
                    defaultValue="" />
            </div>
            <p className="editStarRatingLabel editLabelLabel m-modal-toRead" value={this.state.currentCount}>{this.state.currentCount}</p>
            </div>
        );
    }
}
