import React from 'react';
import './additionalDetailsSelection.css';

import smoking from '../Assets/smoking.svg';
import alcohol from '../Assets/alcohol2.png';
import vegetarian from '../Assets/vegetarian.svg';
import home from '../Assets/home.svg';
import pets from '../Assets/pets.svg';
import dog from '../Assets/dog.svg';
import drugs from '../Assets/drugs.svg';
import car from '../Assets/car.png';
import money from '../Assets/money.svg';
import c from '../Assets/not_interested.svg';

import close from '../Assets/close.svg';
import neutral from '../Assets/remove.svg';
import done from '../Assets/done.svg';


export default class AdditionalDetailsSelection extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selected: props.default || [], // If index is here, it means that we save data
            f_updateAdditionalDetails: props.f_updateAdditionalDetails,
            images: [
                {
                    src: smoking, 
                    name: "smoking",
                    desc_y: "I smoke",
                    desc_n: "I do not Smoke",
                    desc_nn: "Smoking"
                },
                {
                    src: alcohol,
                    name: "alcohol",
                    desc_y: "I consume alcohol",
                    desc_n: "I do not consume alcohol",
                    desc_nn: "Alcohol"
                },
                {
                    src: vegetarian,
                    name: "vegetarian",
                    desc_y: "I am vegetarian",
                    desc_n: "I eat anything",
                    desc_nn: "Vegetarian"
                },
                {
                    src: home,
                    name: "home",
                    desc_y: "I own a home",
                    desc_n: "I do not own a home",
                    desc_nn: "Homeowner"
                },
                {
                    src: car,
                    name: "car",
                    desc_y: "I own a car",
                    desc_n: "I do not have a car",
                    desc_nn: "Car owner"
                },
                {
                    src: pets,
                    name: "pets",
                    desc_y: "I own pets",
                    desc_n: "I do not own pets",
                    desc_nn: "Pets"
                }, 
                {
                    src: dog,
                    name: "dog",
                    desc_y: "I own dog(s)",
                    desc_n: "I dislike dogs",
                    desc_nn: "Dog lover"
                },
                {
                    src: drugs,
                    name: "drugs",
                    desc_y: "I use recreational drugs",
                    desc_n: "I am against recreational drugs",
                    desc_nn: "Recreational drugs"
                }, 
                {
                    src: money,
                    name: "money",
                    desc_y: "I have dough",
                    desc_n: "I require financial assistance",
                    desc_nn: "Finances"
                }
            ]
        }
    }

    f_update = (i, obj) => {
        const selected = this.state.selected;
        selected[obj.name] = {
            status: i,
            desc: "" //TODO add area to edit this
        };
        this.setState({
            selected: selected
        })
        this.state.f_updateAdditionalDetails(selected);
    }

    render(){
        //console.log("this.state: ", this.state.selected);
        return(
            <div id="additionalDetailsSelection-container">
                {
                    this.state.images.map((d, index) => (
                        <AdditionalDetailsSelectionItem d={d} default={this.state.selected} key={index} f_update={this.f_update} />
                    ))
                }
            </div>
        );
    }
}

class AdditionalDetailsSelectionItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hovering: false,
            status : 1,
            data: props.default
        }
    }

    componentDidMount(){
        const currentItem = this.props.default[this.props.d.name];
        if(currentItem){
            this.setState({
                status: currentItem.status
            })
        }
    }

    hover = (b) => {
        this.setState({
            hovering: b
        })
    }
    
    //TODO add extra description in
    cancelled = () => {
        this.props.f_update(0, this.props.d);
        this.setState({
            status: 0
        })
    }

    neutral = () => {
        this.props.f_update(1, this.props.d);
        this.setState({
            status: 1
        })
    }

    selected = () => {
        //console.log(this.props.d);
        this.props.f_update(2, this.props.d);
        this.setState({
            status: 2
        })
    }

    render(){
        //console.log(this.props);
        return <div className={"g-inlineblock" + (this.state.hovering ? " ad-parent-hover" : " ")}
                onMouseEnter={()=>this.hover(true)}
                onMouseLeave={()=>this.hover(false)}>
            <div className="g-relative ads-img-container">
                <img className={"additionalDetails-img" + (this.state.status === 1 ? " ads-neutral " : "")}
                src={this.props.d.src}
                alt="" 
                onClick={()=>this.save()}/>
                {   this.state.status === 0
                    ? <img src={c} className="ads-c" alt="" />
                    : this.state.status === 1 
                    ? <div></div>
                    : <img src={done} className="ads-done" alt="" />
                }
            </div>
            <p style={{marginBottom:"5px",opacity:`${this.state.status === 1 ? ".35":"1"}`}}>
                { this.state.status === 0 
                    ? this.props.d.desc_n 
                    : this.state.status === 1 
                    ? this.props.d.desc_nn
                    : this.props.d.desc_y
                }
            </p>
            <div className="additionalDetails-backgroundCircle"></div>
            
            <div className={"additionalDetails-choiceArea " + (this.state.hovering ? " ad-hover " : "")}>
                <img src={close} alt="" onClick={()=>this.cancelled()} />
                <img src={neutral} alt="" style={{opacity:".5"}}onClick={()=>this.neutral()}/>
                <img src={done} alt="" onClick={()=>this.selected()}/>
            </div>
            
        </div>
    }
}

/*
                <img src={alcohol} alt="" />
                <img src={vegetarian} alt="" />
                <img src={home} alt="" />
                <img src={pets} alt="" />
                <img src={dog} alt="" />
                <img src={drugs} alt="" />
                <img src={car} alt="" />
                <img src={money} alt="" />

*/