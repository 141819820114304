import React from 'react';
import './additionalDetails.css';
import smoking from '../Assets/smoking.svg';
import alcohol from '../Assets/alcohol2.png';
import vegetarian from '../Assets/vegetarian.svg';
import home from '../Assets/home.svg';
import pets from '../Assets/pets.svg';
import dog from '../Assets/dog.svg';
import drugs from '../Assets/drugs.svg';
import car from '../Assets/car.png';
import money from '../Assets/money.svg';

import c from '../Assets/not_interested.svg';
import done from '../Assets/done.svg';

export default class AdditionalDetails extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            additionalDetails: props.additionalDetails || {},
            expanded: props.expanded
        }
    }

    componentDidUpdate(){
        if(this.state.additionalDetails !== this.props.additionalDetails){
            this.setState({
                additionalDetails: this.props.additionalDetails
            })
        }
        if(this.state.expanded !== this.props.expanded){
            this.setState({
                expanded: this.props.expanded
            })
        }
    } 

    getImgFromName = (name) => {
        switch(name){
            case 'smoking': return smoking;
            case 'alcohol': return alcohol;
            case 'vegetarian': return vegetarian;
            case 'home': return home;
            case 'pets': return pets;
            case 'dog': return dog;
            case 'drugs': return drugs;
            case 'car': return car;
            case 'money': return money;
            default: return undefined;
        }
    }
    
    getDescFromName = (name, positive) => {
        if(positive) {
            switch(name){
                case 'smoking': return "Smokes";
                case 'alcohol': return "Loves Alcohol";
                case 'vegetarian': return "Vegetarian";
                case 'home': return "Homeowner";
                case 'pets': return "Pet lover";
                case 'dog': return "Dog lover";
                case 'drugs': return "Uses recreational drugs";
                case 'car': return "Drives";
                case 'money': return "Rich";
                default: return "";
            }
        }
        else {
            switch(name){
                case 'smoking': return "No smoking";
                case 'alcohol': return "Does not consume alcohol";
                case 'vegetarian': return "Not vegetarian";
                case 'home': return "Does not own a home";
                case 'pets': return "No pets";
                case 'dog': return "No dogs";
                case 'drugs': return "No recreational drugs";
                case 'car': return "Does not drive";
                case 'money': return "Poor";
                default: return "";
            }
        }
    }

    render(){
        //console.log(this.props.additionalDetails);
        return(
            <div>
                {
                    //Only show minimized if more than one
                    !this.state.expanded && Object.keys(this.props.additionalDetails).length > 1
                    ? <AdditionalDetailsMinimized {...this.state} getImgFromName={this.getImgFromName} getDescFromName={this.getDescFromName} />
                    : <AdditionalDetailsExpanded {...this.state} getImgFromName={this.getImgFromName} getDescFromName={this.getDescFromName} />
                } 
                
            </div>
        )
    }
}

function AdditionalDetailsMinimized(props){
    return <div className="additional-details-img-container-outer g-flex g-row">
        {
            Object.keys(props.additionalDetails).map((ad, index)=>(
                <div key={index}>
                    {   props.additionalDetails[ad].status !== 1 &&
                        <MinimizedItem name={ad} status={props.additionalDetails[ad].status} getImgFromName={props.getImgFromName} getDescFromName={props.getDescFromName} />
                    }
                </div>
            ))
        }
    </div>
}

function MinimizedItem(props){
    return <div className="additional-details-img-container-inner">
        <img src={props.getImgFromName(props.name)} alt="" />
        { props.status === 0 && <img src={c} alt="" /> }
        { props.status === 2 && <img src={done} alt="" style={{marginLeft:"4px",marginTop:"2px"}}/> }
    </div>
}

function AdditionalDetailsExpanded(props){
    return <div style={{marginTop:"4px"}}>
        {
            Object.keys(props.additionalDetails).map((ad, index)=>(
                <div key={index}>
                    <MaximizedItem name={ad} 
                        status={props.additionalDetails[ad].status} 
                        desc={props.additionalDetails[ad].desc} 
                        getImgFromName={props.getImgFromName} 
                        getDescFromName={props.getDescFromName} />
                </div>
            ))
        }
    </div>
}

function MaximizedItem(props){
    return <div>
        {   props.status !== 1 &&
            <div className="g-flex">
                <div className="additional-details-img-container-inner">
                    <img src={props.getImgFromName(props.name)} alt="" />
                    { props.status === 0 && <img src={c} alt="" /> }
                    { props.status === 2 && <img src={done} alt="" style={{marginLeft:"4px",marginTop:"2px"}}/> }
                </div>
                <div className="additional-details-desc-container">
                <p className="">
                    {props.getDescFromName(props.name, props.status === 2)}
                </p>
                    {/* { props.desc && <p>{props.desc}</p> } */}
                </div>
            </div>
        }
        </div>
}

