import * as firebase from 'firebase/app';
import 'firebase/firestore';

import firebaseConfig, { debugFirebaseConfig } from './firebaseConfig';

const config = require('./config.js');
// https://firebase.google.com/docs/projects/multiprojects
firebase.initializeApp(config.TEST_DB ? debugFirebaseConfig : firebaseConfig);

const db = firebase.firestore();

const USER_COLLECTION = config.DB_DEBUG ? 'Users_DEBUG' : 'Users_v2';
const REFERENCE_TOKEN_COLLECTION = config.DB_DEBUG ? 'ReferenceTokens_DEBUG': 'ReferenceTokens';
//const SCOPE_ENTRIES = 'ScopeEntries';
const REFERENCE_COLLECTION = "References";
//const OUTBOUND_CONTACT_REQUEST_COLLECTION = 'OutboundContactRequests';
//const INBOUND_CONTACT_REQUEST_COLLECTION = 'InboundContactRequests';
const PERSONAL_DETAILS_COLLECTION = 'PersonalDetails';
const ADDITIONAL_DETAILS_COLLECTION = 'AdditionalDetails';
const PERSONALITY_COLLECTION = 'Personality';
const RELATIONSHIP_HISTORY_COLLECTION = 'RelationshipHistory';
const LOOKINGFOR_COLLECTION = 'LookingFor';
const ASPIRATIONS_COLLECTION = 'Aspirations';
const HIRING_FOR_COLLECTION = 'HiringFor';
const LONELINESS_COLLECTION = 'LonelinessLevel';
const MUST_NOT_HAVE_COLLECTION = 'MustNotHave';
const CHAT_QUOTES_COLLECTION = 'ChatQuotes';
const FILTERS_COLLECTION = 'SearchFilters';
const LIKES_COLLECTION = 'Likes';
const NICKNAME_COLLECTION = 'Nicknames';
// const NICKNAME_DOC = "Nicknames";
const FAQ_COLLECTION = 'FAQ';
//const USER_FAVORITES = 'Favourites';
const REFER_2_FRIEND_COLLECTION = config.DB_DEBUG ? 'ReferProfile2Friend_DEBUG' : 'ReferProfile2Friend';

const VIEWS_COLLECTION = config.DB_DEBUG ? 'Views_DEBUG' : 'Views';

const CHAT_COLLECTION = config.DB_DEBUG ? 'Chat_v2_DEBUG' : 'Chat_v2';
const FAVOURITE_COLLECTION = config.DB_DEBUG ? 'Favourites_DEBUG' : 'Favourites';
const CHAT_MESSAGE_COLLECTION = 'Messages';

/* TODO how to override log?
Object.prototype.log = (str) => {
	if(config.SHOW_LOGS) console.log(str);
}
console.log("hey");
*/

export { db };

if(config.USER_MOCK_DATA) console.log("config.USE_MOCK_DATA: ", config.USE_MOCK_DATA);
/******************************** START OF MOCK DATA ********************/
export default config.USE_MOCK_DATA ? 
{
	saveViewedProfile: async () => {
		console.log("MOCK saveViewedProfile");
		return;
	},
	loadAllProfiles: async () => {
		console.log("MOCK loadAllProfiles");
		return [
			
		]
	},
	loadPersonalityAnswer: async () => {
		console.log("MOCK loadPersonalityAnswer");
		return [{
			personalityCode: "",//"INTJ", 
			personalityValues: {O: 4.90909, C: 1.68, E: 2, A:3, N: 0.85} //default with all set to 4
		}]
	},
	loadReference: async () => {
		console.log("MOCK loadReferenceHistory");
		return [
			{
				id: "1575203544",
				msg: "She was very good and really nice to be around, I still miss her to this day. If you are able to be with her, count yourself lucky, she chooses very carefully so don't even bother trying to fool her. She's the one that got away",
				verified: false
			},
			{
				id: "1565203530",
				msg: "Don't date this person, she will take all of your time.",
				verified: true
			}
		]
	},
	loadOutboundContactRequests: async () => {
		console.log("MOCK loadOutboundContactRequests");
		return {}},
	loadInboundContactRequests: async  () => {
		console.log("MOCK loadInboundContactRequests");
		return {}},
	getContactFieldsToShow: async () => {
		console.log("MOCK getContactFieldsToShow");
		return {
			Gender: "M",
			//displayName: "Jun Kagaya",
			"Date of birth": "1993-02-26",
			//email: "jun.a.kagaya@gmail.com",
			"Your Location": "Auckland, New Zealand",
			Occupation: "Software Developer"
			//facebookURL: "https://www.facebook.com/gieoon"
		}
	},
	getSkills: async (uid) => {
		console.log("MOCK getSkills");
		return [
				//"a", "abcde", "abcdefghijklmnopqrstuvwxyz", "a"
				"Honest",
				"Generous",
				"Loyal",
				"Trustworthy",
				"Conscientious"
			];
	},
	saveLookingFor: async () => {
		return {};
	},
	saveAspiration: async () => {
		return [];
	},
	getAllChatsForUser: async (uid) => {
		console.log("MOCK getAllChatsForUser");
		return [
			{
				participants: ["EKpHeqYbukUNcdcYGF1osPDaQ9x2", "234567"],
				messages: [
					[{
						sender: "EKpHeqYbukUNcdcYGF1osPDaQ9x2",
						timestamp: 1575887110742,
						message: "Heyyy"
					},
					{
						sender: "EKpHeqYbukUNcdcYGF1osPDaQ9x2",
						timestamp: 1565887110742,
						message: "Heyyy again"
					}],
					[{
						sender: "234567",
						timestamp: 1565887110742,
						message: "ZXCVBNM"
					}]
				],
				personalDetails: {
					Gender: "F",
					"Date of birth": "1999-07-15",
					"Your Location": "Auckland, New Zealand",
					Occupation: "Marketing Assistant"
				}
			},
			{
				participants: ["EKpHeqYbukUNcdcYGF1osPDaQ9x2", "987654"],
				messages: [
					[{
						sender: "987654",
						timestamp: 1575847110742,
						message: "Heyyy"
					},
					{
						sender: "EKpHeqYbukUNcdcYGF1osPDaQ9x2",
						timestamp: 1565187110742,
						message: "Heyyy again"
					}],
					[{
						sender: "987654",
						timestamp: 1569887110742,
						message: "lol"
					}]
				],
				personalDetails: {
					Gender: "F",
					"Date of birth": "1983-05-28",
					"Your Location": "Munich, Germany",
					Occupation: "Hotdog stand worker"
				}
			}
		]
	},
	getAllUsersForChat: async (uid) => {
		console.log("MOCK getAllUsersForChat");
		return []
	},
	getAdditionalDetails: async (uid) => {
		console.log("MOCK getAdditionalDetails");
		//0 == negative, 1 == neutral, 2 == positive
		return {
			smoking: {
				status: 0,
				desc: "I don't smoke often, only a few times a year under stress"
			},
			alcohol: {
				status: 1,
				desc: "I don't smoke often, only a few times a year under stress"
			},
			pets: {
				status: 2,
				desc: "I don't smoke often, only a few times a year under stress"
			}
		}
	},
	loadLookingFor: async (uid) => {
		console.log("MOCK loadLookingFor");
		return [
			{
				id: 123456789,
				"Special Requirements": "Someone who always puts the toilet seat down"
			}
		]
	},
	loadAspirations: async (uid) => {
		console.log("MOCK loadAspirations");
		return [
			{
				id: 123456789,
				Aspirations: "I want to be a millionaire before I turn 30"
			}
		]
	},
	loadReferences: async (uid) => {
		console.log("MOCK loadReferences");
		return [
			{	
				id: 123456789,
				pending: "",
				msg: "I agree that this worked really well and it was super interesting",
				verified: false
			},
			{	
				id: 123456789,
				pending: "",
				msg: "2 I agree that this worked really well and it was super interesting",
				verified: false
			}
		]
	},
	loadFavouritesForUser: async () => {
		console.log("MOCK loadFavouritesForUser");
		return [
			{
				from: "EKpHeqYbukUNcdcYGF1osPDaQ9x2",
				to: "234567",
				timestamp: Date.now().toString(),
				status: "pending"
			},
			{
				from: "234567",
				to: "EKpHeqYbukUNcdcYGF1osPDaQ9x2",
				timestamp: Date.now().toString(),
				status: "pending"
			},
			{
				from: "EKpHeqYbukUNcdcYGF1osPDaQ9x2",
				to: "123456",
				timestamp: Date.now().toString(),
				status: "accepted"
			},
			{
				from: "EKpHeqYbukUNcdcYGF1osPDaQ9x2",
				to: "9987654321",
				timestamp: Date.now().toString(),
				status: "rejected"
			},
		]
	},
	loadRelationshipHistory: async () => {
		console.log("MOCK loadRelationshipHistory");
		return [
			{
				id: "1565203530",
				country: "New Zealand",
				city: "Auckland",
				longDistance: true,
				startDateYear: "2012",
				startDateMonth: "02",
				endDateYear: "2015",
				endDateMonth: "04",
				desc: ""
			},
			{
				id: "1535203530",
				country: "United States",
				city: undefined,
				longDistance: false,
				startDateYear: "2013",
				startDateMonth: "02",
				endDateYear: "2015",
				endDateMonth: "10",
				desc: "Really solid experience where I learnt the foundations of a relationship"
			},
			{
				id: "1515203530",
				country: "Germany",
				city: "Munich",
				longDistance: false,
				startDateYear: "2019",
				startDateMonth: "08",
				endDateYear: undefined,
				endDateMonth: undefined,
				desc: "Ongoing relationship with a man in Germany"
			}
		]
	},
	loadHiringFor: async (uid) => {
		return [];
	},
	loadLonelinessLevel: async (uid) => {
		return {};
	},
	loadMustNotHave: async (uid) => {
		return [];
	},
	loadChatQuotes: async (uid) => {
		return [];
	},
	loadFAQ: async (uid) => {
		return [];
	},
	getEncouragementStatus: async function(uid){
		return {};
	},
	listenUserChatIdChanges: async () => {},
	listenAllChatMessagesIndexForUser: async () => {},
	listenMyUnreadChatForUser: async () => {},
}
:
/******************************** END OF MOCK DATA ********************/
// ACTUAL REAL DATA
{
	//TODO modify this to just get the next 10 who have personalDetails, and after 5 are depleted get the next 10 who also have personalDetails
	loadAllProfiles: async function(cb, profileSize){
		if(!profileSize) profileSize = 8;
		const data = [], promises = [];
		await db.collection(USER_COLLECTION)
			.get()
			.then((usersSnapshot)=>{
					usersSnapshot.forEach(async (user)=>{
						// Number of child collections
						promises.push(
							user.ref
								.collection(PERSONAL_DETAILS_COLLECTION)
								.get()
								.then(snapshot => {	
									// this number narrows to show profiles all personal info in them
									if(snapshot.size >= profileSize){
										data.push(user.id);
									}
								})
						)
					})
					//console.log("returning stuff")
					return data;
			})
			// .then(()=>{
			// 	console.log('successfully loaded profile data: ', data);
			// 	//return data;	
			// })
			// .catch((err)=>{
			// 	console.error("Error getting profile: ", err);
			// })
		Promise.all(promises).then(()=>{
			//console.log('RETURNING UIDS: ', data);
			return cb(data);
		})
	},
	/*loadProfile: async function(profileUid, b_getName){
		const data = {};
		return db.collection(USER_COLLECTION)
			.doc(profileUid)
			.get()
			.then(user => {
				data[profileUid] = {
					personalDetails: {
						liveLocation: user.get("Your Location"),
						gender: user.get("Gender"),
						bornDate: user.get("Date of Birth"),
						occupation: user.get("Occupation")
					},
					additionalDetails: user.get("Additional Details")
				}
				if(b_getName){
					data[profileUid]["personalDetails"]["First Name"] = user.get("First Name");
					data[profileUid]["personalDetails"]["Last Name"] = user.get("Last Name");
				}
				return data;
			})
			.catch(err => console.error("Error loading profile: ", profileUid, ': ', err));
	},*/
	loadLookingFor: async function(uid){
		const results = [];
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(LOOKINGFOR_COLLECTION)
			.orderBy("timestamp", "desc")
			.limit(1)
			.get()
			.then(lfs => {
				lfs.forEach((lf)=>{
					const l = lf.data();
					l.id = lf.id;
					results.push(l);
				})
				return results || [];
			})
			.catch(err => console.error("Error loading LookingFor: ", err));

		// return Promise.all(promises).then(()=>{
		// 	console.log("looking for: ", results);
		// 	return results || [];
		// })
	},
	saveLookingFor: function(uid, obj){
		const id = Date.now().toString();
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(LOOKINGFOR_COLLECTION)
			.add({timestamp: id, ...obj})
			.then(()=>{
				//console.log("Saved lookingFor")
				return id;
			})
			.catch(err => {console.error("Error saving lookingFor: ", err)});
	},
	loadAspirations: async function(uid){
		const results = [];
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(ASPIRATIONS_COLLECTION)
			.orderBy("timestamp", "desc")
			.limit(1)
			.get()
			.then(aspirationsSnapshot => {
				aspirationsSnapshot.forEach(a => {
					const asp = a.data();
					asp.id = a.id;
					results.push(asp);
				})
				return results || [];
			})
	},
	saveAspiration: function(uid, obj){
		const id = Date.now().toString();
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(ASPIRATIONS_COLLECTION)
			.doc(id)
			.set({
				timestamp: id, ...obj
			})
			//.add({timestamp: id, ...obj})
			.then(()=>{
				//console.log("Saved aspiration")
				return id;
			})
			.catch(err => {console.error("Error saving aspiration: ", err)});
	},
	deleteAspiration: async function(uid, id){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(ASPIRATIONS_COLLECTION)
			.doc(id)
			.delete()
			.then(()=>{ 
				//console.log("Deleted relationship history successfully");
				return;
			})
			.catch((err)=>{console.error("Error deleting aspiration: ", err)});
	},
	saveMustNotHave: async function(uid, obj){
		if(!global.authUid.length) return;
		if(!obj.id) obj.id = Date.now().toString();
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(MUST_NOT_HAVE_COLLECTION)
			.doc(obj.id)
			.set(obj)
			.then(()=>{
				return obj.id;
			})
			.catch((err) => {
				console.error("Error saving MustNotHave: ", err);
			})
	},
	loadMustNotHave: async function(uid){
		const promises = [], results = {};
		promises.push(db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(MUST_NOT_HAVE_COLLECTION)
			.get()
			.then(mnhSnapshot => {
				promises.push(
					mnhSnapshot.forEach(mnh => {
						results[mnh.id.toString()] = mnh.data();
					})
				)
			})
			.catch(err => console.error("Error retrieving MustNotHave: ", err))); 

		return Promise.all(promises).then(()=>{
			return results;
		})
	},
	deleteMustNotHave: async function(uid, id){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(MUST_NOT_HAVE_COLLECTION)
			.doc(id)
			.delete()
			.then(()=>{ 
				//console.log("Deleted MustNotHave successfully");
				return;
			})
			.catch((err)=>{console.error("Error deleting MustNotHave: ", err)});
	},
	//ref.update({ regions: firebase.firestore().FieldValue.arrayUnion("string"); });
	saveRelationshipHistory: function(uid, id, country, city, longDistance, startDateYear, startDateMonth, endDateYear, endDateMonth, desc){
		if(!global.authUid.length){
			//console.log("Not authenticated and returning");
			return;
		}
		if(!id) id = Date.now().toString();
		//console.log("id: ", id);
		return db.collection(USER_COLLECTION)
			.doc(global.authUid)
			.collection(RELATIONSHIP_HISTORY_COLLECTION)
			.doc(id)
			.set({
				country: country || "",
				city: city || "",
				longDistance: longDistance || "",
				startDateYear: startDateYear || "",
				startDateMonth: startDateMonth || "",
				endDateYear: endDateYear || "",
				endDateMonth: endDateMonth || "",
				desc: desc || ""
			})
			.then(()=>{ 
				//console.log("Successfully saved relationship history");
				return id;
			})
			.catch((err)=>{console.error("Error saving relationship history: ", err);});
	},
	loadRelationshipHistory: async function(uid){
		const promises = [], results = {};
		db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(RELATIONSHIP_HISTORY_COLLECTION)
			.get()
			.then(rhSnapshot => {
				promises.push(
					rhSnapshot.forEach(rh => {
						//console.log("Got Relationship History: ", rh.id, " => ", rh.data());
						results[rh.id] = {...rh.data(), id: rh.id};
					})
				)
			})
			.catch(err => console.error("Error retrieving Relationship Histories"));

		return Promise.all(promises).then(()=>{
			return results;
		})
	},
	deleteRelationshipHistory: async (uid, id) => {
		//const promises = [];
		//console.log("uid: ", uid, " id: ", id);
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(RELATIONSHIP_HISTORY_COLLECTION)
			.doc(id)
			.delete()
			.then(()=>{ 
				//console.log("Deleted relationship history successfully");
				return;
			})
			.catch((err)=>{console.error("Error deleting relationship history: ", err)});
	},
	saveAnswer: function(id, answer, completed){
		if(!global.authUid.length) {
			//console.log("Not authenticated and returning");
			return;
		}
		db.collection(USER_COLLECTION)
			.doc(global.authUid)
			.set({
				[`${id}`]: {
					answer: answer,
					completed: completed
				}
			}, {merge: true})
			.then(()=>{
				//console.log("Successfully saved answer")
			})
			.catch((err)=>{
				console.error("Error saving answer: ", err);
			});
	},
	loadPersonalityAnswer: async function(id){
		var data = {};
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(PERSONALITY_COLLECTION)
			.orderBy("timestamp", "desc")//firebase.firestore.desc
			.limit(1)
			.get()
			.then(fieldsSnapshot => {
				fieldsSnapshot.forEach(fieldDoc =>{
					//data[fieldDoc.id] = fieldDoc.data();
					data = fieldDoc.data();
				})
				//console.log('returning data: ', data);
				return data || [];
			})
			.catch(err=>{console.log("Error getting Personality fields to edit: ", err)});
	},
	savePersonalityAnswer: function(id, personalityScore){
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(PERSONALITY_COLLECTION)
			//.doc(Date.now().toString())
			.add(
				{timestamp:Date.now(), ...personalityScore}
			)//, {merge: true})
			.then(()=>{
				//console.log("Successfully saved personality answer")
				return 1;
			})
			.catch(err=>{
				console.error("Error saving personality answer: ", err)
				return 0;
			});
	},
	saveReference: async function(id, referenceId, data){
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(REFERENCE_COLLECTION)
			.doc(referenceId)
			.set(data, {merge: true})
			.then(()=>{
				//console.log("Successfully saved reference");
				return referenceId
			})
			.catch(err =>{console.error("Error saving reference: ", err)});
	},
	// loadReference: async function(uid, referenceId){
	// 	return db.collection(USER_COLLECTION)
	// 		.doc(uid)
	// 		.collection(REFERENCE_COLLECTION)
	// 		.doc(referenceId)
	// 		.get()
	// 		.then(referenceSnapshot => {
	// 			return referenceSnapshot.data();
	// 		})
	// 		.catch(err => console.error("Error loading reference: ", err));
	// },
	loadReferences: async function(uid){
		const data = [];
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(REFERENCE_COLLECTION)
			.get()
			.then(referenceSnapshot => {
				referenceSnapshot.forEach(referenceDoc => {
					data.push({
						id: referenceDoc.id, 
						pending: referenceDoc.get("pending"),
						msg: referenceDoc.get("msg"),
						verified: referenceDoc.get("verified")
					})
				})
				return data;
			})
	},
	deleteReference: async function(userUid, id){
		return db.collection(USER_COLLECTION)
			.doc(userUid)
			.collection(REFERENCE_COLLECTION)
			.doc(id)
			.delete()
			.then(()=>{
				//console.log("Deleted reference successfully");
				return;
			})
			.catch((err)=>{console.error("Error deleting reference: ", err)});
	},
	validateReferenceToken: async function(token){
		return db.collection(REFERENCE_TOKEN_COLLECTION)
			.doc(token)
			.get()
			.then(tokenSnapshot => {
				//console.log("tokenSnapshot:", tokenSnapshot);
				if(tokenSnapshot.exists){
					const expired = tokenSnapshot.get("expired");
					return {
						expired: expired,
						uid: tokenSnapshot.get("uid"),
						displayName: tokenSnapshot.get("displayName"),
						referenceId: tokenSnapshot.get("referenceId"),
						myEmail: tokenSnapshot.get("myEmail"),
						destinationEmailAddress: tokenSnapshot.get("destinationEmailAddress")
					}
				}
				return {
					expired: true
				};
			});
	},
	createReferenceToken: (uid, myEmail, displayName, token, referenceId, destinationEmailAddress) => {
		db.collection(REFERENCE_TOKEN_COLLECTION)
			.doc(token)
			.set({
				uid: uid,
				myEmail: myEmail,
				displayName: displayName,
				destinationEmailAddress: destinationEmailAddress,
				referenceId: referenceId,
				timestamp: null,
				expired: false
			})
	},
	consumeReferenceToken: (token) => {
		db.collection(REFERENCE_TOKEN_COLLECTION)
			.doc(token)
			.set({
				timestamp: Date.now(),
				expired: true
			}, {merge: true});
	},
	/*loadOutboundContactRequests: async function(id, accepted){
		const data = {};
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(OUTBOUND_CONTACT_REQUEST_COLLECTION)
			.where("handled", "==", accepted)
			.get()
			.then(userSnapshot =>{
				userSnapshot.forEach((contactRequestDoc)=>{
					data[contactRequestDoc.id] = contactRequestDoc.data();
				})
				return data;
			}).catch(err => console.error("Error loading outbound contact requests"));
	},
	loadInboundContactRequests: async function(id, accepted){
		const data = {};
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(INBOUND_CONTACT_REQUEST_COLLECTION)
			.where("handled", "==", accepted)
			.get()
			.then(userSnapshot =>{
				userSnapshot.forEach((contactRequestDoc)=>{
					data[contactRequestDoc.id] = contactRequestDoc.data();
				})
				return data;
			}).catch(err => console.error("Error loading inbound contact requests"));
	},
	createContactRequest: function(id, targetId){
		db.collection(USER_COLLECTION)
			.doc(id)
			.collection(OUTBOUND_CONTACT_REQUEST_COLLECTION)
			.doc(targetId)
			.set({ accepted: false, handled: false })
			.then(()=>{ console.log("created OUTBOUND contact request successfully") })
			.catch((err) => { console.log("Error creating contact request: ", err)});

		db.collection(USER_COLLECTION)
			.doc(targetId)
			.collection(INBOUND_CONTACT_REQUEST_COLLECTION)
			.doc(id)
			.set({ accepted: false, handled: false })
			.then(()=>{ console.log("created INBOUND contact request successfully") })
			.catch((err) => { console.log("Error creating contact request: ", err)});
	},
	updateContactRequest: function(id, targetId, newStatus){
		db.collection(USER_COLLECTION)
			.doc(id)
			.collection(INBOUND_CONTACT_REQUEST_COLLECTION)
			.doc(targetId)
			.set({ accepted: newStatus, handled: true })
			.then(()=>{console.log("accepted INBOUND contact request")})
			.catch(err=>{console.log("error accepting inbound contact request: ", err)});

		db.collection(USER_COLLECTION)
			.doc(targetId)
			.collection(OUTBOUND_CONTACT_REQUEST_COLLECTION)
			.doc(id)
			.set({ accepted: newStatus, handled: true })
			.then(()=>{console.log("accepted OUTBOUND contact request")})
			.catch(err=>{console.log("error accepting outbound contact request: ", err)});
	},
	//when a user accepts a friend request
	createScopeEntry: function(id, targetId){
		db.collection(USER_COLLECTION)
			.doc(id)
			.collection(SCOPE_ENTRIES)
			.doc(targetId)
			.set({
				step: 1,
				active: true
			}).then(()=>console.log("Successfully created Scope Entry"))
			.catch((err)=>console.error("Error creating Scope Entry"));

		db.collection(USER_COLLECTION)
			.doc(targetId)
			.collection(SCOPE_ENTRIES)
			.doc(id)
			.set({
				step: 1,
				active: true
			}).then(()=>console.log("Successfully created Scope Entry"))
			.catch((err)=>console.error("Error creating Scope Entry"));
	},
	getUserScopeEntries: async function(id){
		const data = [];
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(SCOPE_ENTRIES)
			.where("active", "==", true)
			.get()
			.then((scopesSnapshot)=>{
				scopesSnapshot.forEach((scopeSnapshot)=>{
					data.push(scopeSnapshot.id);
				})
				return data;
			})
	},*/
	getAdditionalDetails: async function(uid){
		const promises = [], results = {};
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(ADDITIONAL_DETAILS_COLLECTION)
			.get()
			.then(adSnapshot => {
				promises.push(
					adSnapshot.forEach(ad => {
						results[ad.id] = ad.data();
					})
				)
				return results;
			})
			.catch(err => console.error("Error retrieving additionalDetails: ", err));
	},
	saveAdditionalDetails: async function(uid, selected){
		const promises = [];
		Object.keys(selected || {}).forEach((s) =>{
			if(s && s.length){
				//console.log(s);
				const v = selected[s];
				promises.push(
					db.collection(USER_COLLECTION)
						.doc(uid)
						.collection(ADDITIONAL_DETAILS_COLLECTION)
						.doc(s.toString())
						.set({
							status: v.status,
							desc: v.desc
						}, {merge: true})
						.then(()=>{
							//console.log("Successfully saved additionalDetails");
						})
						.catch(err => console.error("Error saving additionalDetails: ", err))
				);
			}
		})
		return Promise.all(promises);	
	},
	getEmailStatusFromUid: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.get()
			.then(doc => {
				return doc.get('emailMe');
			})
			.catch(err => {
				console.error("Error getting email status from uid");
				return false;
			});
	},
	getContactFieldsToShow: async function(id){
		const data = {};
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(PERSONAL_DETAILS_COLLECTION)
			.get()//doc("Real Name")
			.then(fieldsSnapshot =>{
				fieldsSnapshot.forEach((fieldDoc)=>{
					if(fieldDoc.get("show")){
						data[fieldDoc.id] = fieldDoc.get("value");
					}
				})

				return {
					"Date of birth": data["Date of birth"],
					Gender: data.Gender,
					Occupation: data.Occupation,
					"Your Location": data["Your Location"],
					"About Me": data["About you in 75 characters"] || data["About you in 240 characters"],
					Availability: data.Availability,
				}
			})
			.catch(err=>{console.log("Error getting contact fields to show: ", err)});
	},
	getContactFieldsToEdit: async function(id){
		const data = {};
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(PERSONAL_DETAILS_COLLECTION)
			.get()
			.then(fieldsSnapshot => {
				fieldsSnapshot.forEach(fieldDoc =>{
					data[fieldDoc.id] = fieldDoc.data();
				})
				return data;
			})
			.catch(err=>{console.log("Error getting contact fields to edit: ", err)});
	},
	setContactFieldToEdit: function(id, fieldName, show, value){
		return db.collection(USER_COLLECTION)
			.doc(id)
			.collection(PERSONAL_DETAILS_COLLECTION)
			.doc(fieldName)
			.set({ 
				show: show, 
				value: value
			}, {merge: true})
			.then(()=>{
				//console.log("Successfully updated contact fieldname");
				return 1;
			})
			.catch(err=>{
				console.log("Error updating contact fieldname: ", err)
				return 0;
			});
	},
	getEmailMeStatus: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.get()
			.then(doc => {
				// console.log(doc.id, doc.data());
				return doc.get('emailMe')// || "No";
			})
			.catch(err => {
				console.error("Error getting emailMe status: ", err);
			});
	},
	setEmailMe: async function(uid, value){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.set({
				emailMe: value,
			}, {merge: true})
			.then(()=> {return})
			.catch(err => {
				console.error("Error setting emailMe: ", err);
				return;
			});
	},
	getSkills: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.get()
			.then(doc=>{
				return doc.get("Skills");
			})
			.catch(err => {
				console.error("Error getting skills: ", err)
				return {error: 404};
			});
	},
	saveSkills: async function(uid, data){
		db.collection(USER_COLLECTION)
			.doc(uid)
			.set(data, {merge:true})
			.then(()=>{
				//console.log("Successfully saved skills")
			})
			.catch(err => console.error("Error saving skills: ", err));
	},
	createRefer2Friend: async function(uidFrom, emailTo, profileUid){
		db.collection(REFER_2_FRIEND_COLLECTION)
			.doc(Date.now().toString())
			.set({
				uidFrom: uidFrom,
				emailTo: emailTo,
				profileUid: profileUid
			}).then(()=>{
				//console.log("Successfully saved referral 2 friend")
			})
			.catch(err => console.error("Error saving referral 2 friend: ", err));
	},
	createFavourite: async function(uid, targetUid){
		var favId = '';
		return db.collection(FAVOURITE_COLLECTION)
			.add({
				from: uid,
				to: targetUid,
				timestamp: Date.now().toString(),
				status: "pending"
			}).then(doc => {
				//console.log("Created Favourite with Id: ", doc.id);
				favId = doc.id;
				db.collection(USER_COLLECTION)
					.doc(uid)
					.set({
						favouriteIds: firebase.firestore.FieldValue.arrayUnion(favId)
					}, { merge: true})
					.then(()=>{
						//console.log("Favourite Id 1 stored with own uid successfully")
					})
					.catch(err => console.error("Error saving favorite 1 with own user: ", err));
				db.collection(USER_COLLECTION)
					.doc(targetUid)
					.set({
						favouriteIds: firebase.firestore.FieldValue.arrayUnion(favId)
					}, { merge: true})
					.then(()=>{
						//console.log("Favourite Id 2 stored with own uid successfully")
					})
					.catch(err => console.error("Error saving favorite 2 with own user: ", err));
				return favId;
			}).catch(err => console.error("Error creating new favourite: ", err));
	},
	loadFavouritesForUser: async function(uid){
		const promises = [], results = [];
		await db.collection(USER_COLLECTION)
			.doc(uid)
			.get()
			.then(doc => {
				const favIds = doc.get("favouriteIds");
				if(!favIds) return;
				favIds.forEach(favId => {
					//console.log('favId: ', favId);
					promises.push(this.getFavById(favId).then(favObj => {
						//console.log("Got favObj: ", favObj);
						results.push({...favObj, id: favId});
					}));
				})
			}).catch(err => console.error("Error loadingFavouriteForUser: ", err));
		return Promise.all(promises).then(()=>{
			return results;
		})
	},
	// listenFavourites: async function(uid, callback){
	// 	return db.collection(USER_COLLECTION)
	// 		.doc(uid)
	// 		.collection(USER_FAVORITES)
	// 		//.get("favouriteIds")
	// 		.onSnapshot({includeMetadataChanges: true},(userDoc)=>{
	// 			console.log("userDocs: ", userDoc);
	// 			userDoc.docChanges().forEach((doc) => {
	// 				if(doc.type === "added" || doc.type === "modified"){
	// 					callback(doc.get("favoriteIds"));
	// 				} 
	// 				else if(doc.type === "removed"){
	// 				}
	// 			})
    //         }, (err) => {console.error("Error loading all User Docs")})
	// },
	updateFavourite: async function(favId, newStatus){
		return db.collection(FAVOURITE_COLLECTION)
			.doc(favId)
			.set({
				status: newStatus
			}, {merge:true})
			.then(()=>{
				return;
			})
			.catch(err=>{console.error("Error updating favourite: ", err)});
	},
	getFavById: async function(favId) {
		return db.collection(FAVOURITE_COLLECTION)
			.doc(favId)
			.get()
			.then((doc)=>{
				return doc.data();
			})
			.catch(err =>{console.error("Error getting Favourite By Id: ", err)});
	},
	saveViewedProfile: function(uid, authUid) {
		db.collection(VIEWS_COLLECTION)
			.doc(uid)
			.set({
				"profileViews": firebase.firestore.FieldValue.increment(1), 
				"profileViewsThisWeek": firebase.firestore.FieldValue.increment(1),
			}, {merge: true})
			.then(()=>{
				//console.log("Successfully saved profile viewed")
			})
			.catch(err => {console.error("Error saving profile viewed: ", err)});

		if(authUid){
			db.collection(VIEWS_COLLECTION)
				.doc(uid)
				.set({
					"signedInProfileViews": firebase.firestore.FieldValue.increment(1),
					"signedInProfileViewsThisWeek": firebase.firestore.FieldValue.increment(1),
					"viewedFrom": firebase.firestore.FieldValue.arrayUnion(authUid)
				}, {merge: true})
				.then(()=>{
					//console.log("Successfully saved Signed In profile")
				})
				.catch(err=>{console.error("Error saving SignedInProfileView ", err)})
		}
	},
	saveHiringFor: async function(uid, obj){
		db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(HIRING_FOR_COLLECTION)
			.doc('Default')
			.set(obj)
			.then(()=>{ return 1; })
			.catch(err => {console.error("Error saving hiringFor: ", err)});
	},
	loadHiringFor: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(HIRING_FOR_COLLECTION)
			.doc('Default')
			.get()
			.then((doc)=>{
				return doc.data() || {};
			})
			.catch(err => {console.error("Error loading hiringFor", err)});
	},
	saveLonelinessLevel: async function(uid, obj){
		db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(LONELINESS_COLLECTION)
			.doc('Default')
			.set(obj)
			.then(()=>{return 1;})
			.catch(err => {console.error("Error saving loneliness level: ", err)});
	},
	loadLonelinessLevel: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(LONELINESS_COLLECTION)
			.doc('Default')
			.get()
			.then((doc)=>{
				return doc.data() || {};
			})
			.catch(err => {console.error("Error loading lonelinessLevel", err)});
	},
	deleteLonelinessLevel: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(LONELINESS_COLLECTION)
			.doc('Default')
			.delete()
			.then(()=>{ 
				//console.log("Deleted LonelinessLevel successfully");
				return;
			})
			.catch((err)=>{console.error("Error deleting LonelinessLevel: ", err)});
	},
	updateEncouragementStatus: async function(uid, b){
		db.collection(USER_COLLECTION)
			.doc(uid)
			.set({
				encouragementStatus: b
			}, {merge: true})
			.then(()=>{
				return 1;
			})
			.catch((err)=>{
				console.error("Error updating encouragementStatus: ", err);
			})
	},
	getEncouragementStatus: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.get()
			.then((doc)=>{
				return doc.get("encouragementStatus");
			})
			.catch(err => {
				console.error("Error retrieveing encouragementStatus: ", err);
			})
	},
	loadChatQuotes: async function(uid, cb){
		const promises = [], results = {};
		promises.push(db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(CHAT_QUOTES_COLLECTION)
			.get()
			.then(cqSnapshots => {
				promises.push(cqSnapshots.forEach(cq => {
					results[cq.id.toString()] = cq.data();
				}));
			})
			// .onSnapshot(cqSnapshots => {
			// 	cqSnapshots.docChanges().forEach((cqSnapshot) => {
			// 		if(cqSnapshot.type === "added" || cqSnapshot.type === "modified"){
			// 			cb(cqSnapshot.doc.id, cqSnapshot.doc.data());
			// 		}
			// 	})
			// }, err => console.error("Error loading chat quotes: ", err))
		);

		return Promise.all(promises).then(()=>{
			return results;
		})
	},
	saveChatQuote: async function(uid, id, quote){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(CHAT_QUOTES_COLLECTION)
			.doc(id)
			.set({value: quote, id: id})
			.then(()=>{ return id; })
			.catch(err => console.error("Error saving ChatQuote: ", err));
	},
	deleteChatQuote: async function(uid, id){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(CHAT_QUOTES_COLLECTION)
			.doc(id)
			.delete()
			.then(()=>{ 
				//console.log("Deleted chatQuote successfully");
				return;
			})
			.catch((err)=>{console.error("Error deleting chatQuote: ", err, id)});
	},
	loadFAQ: async function(uid){
		const res = {};
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(FAQ_COLLECTION)
			.get()
			.then((faqs)=>{
				faqs.forEach(faq => {
					res[faq.id] = {timestamp:Number(faq.id), ...faq.data()};
				})
				return res;
			})
			.catch(err => console.error("Error loading FAQ: ", err, uid));
	},
	saveFAQ: async function(uid, id, data){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(FAQ_COLLECTION)
			.doc(id)
			.set(data, {merge: true})
			.then(()=>{ return id; })
			.catch(err => console.error("Error saving FAQ: ", err, id, data));
	},
	deleteFAQ: async function(uid, id){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(FAQ_COLLECTION)
			.doc(id)
			.delete()
			.then(()=>{ return; })
			.catch(err => console.error("Error deleting FAQ: ", err, uid, id));
	},
	// Just load the latest search filter, with future options to show 'recent searches' and load the last 5
	loadSearchFilters: async function(uid){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(FILTERS_COLLECTION)
			.orderBy('timestamp', 'desc')
			.limit(1)
			.get()
			.then(doc => {
				return doc.data();
			})
			.catch(err =>{console.error("Error loading searchFilter: ", err)});
	},
	saveSearchFilters: function(uid, filterObj){
		const id = Date.now();
		db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(FILTERS_COLLECTION)
			.doc(id.toString())
			.set({...filterObj, timestamp: id})
			.then(()=>{})
			.catch(err => console.error("Error saving searchFilter: ", err));
	},
	updateLike: function(uid, pageUid, status){
		if(!uid || !pageUid) 
			return;
		db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(LIKES_COLLECTION)
			.doc(pageUid)
			.set({
				uid: uid,
				status: status,
				pageUid: pageUid,
				updatedOn: firebase.firestore.Timestamp.fromDate(new Date()),
			}, {merge: true})
			.then(()=>{})
			.catch(err => console.error("Error adding like: ", err));
	},
	listenToLikes: function(uid, cb, component){
		db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(LIKES_COLLECTION)
			.where("status","==", 1)
			.onSnapshot((querySnapshot) => {
				const likes = {};
				const promises = [];
				querySnapshot.forEach(async (doc) => {
					promises.push(
						this.getContactFieldsToShow(doc.data().pageUid)
							.then((details) =>{
								// console.log("returning details: ", details);
								likes[doc.id] = {
									pageUid: { ...details, uid: doc.id},
									updatedOn: doc.data().updatedOn
								};
								return;
							})
					);
				});
				Promise.all(promises).then(()=>{
					cb(likes, component);
				})
			})
			// .then(()=>{})
			// .catch(err => console.error("Error listening to likes: ", err));
	},
	listenToNicknames: function(uid, cb, component){
		db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(NICKNAME_COLLECTION)
			.onSnapshot((querySnapshot) => {
				const nicknames = {};
				// const promises = [];
				querySnapshot.forEach(async doc => {
					// promises.push(
						nicknames[doc.id] = doc.get('nickname')
					// );
				});
				//Promise.all(promises).then(()=>{
					// console.log('got nicknames: ', nicknames);
					cb(nicknames, component);
				// })
			});
	},
	saveNickname: async function(uid, targetUid, newNickname){
		return db.collection(USER_COLLECTION)
			.doc(uid)
			.collection(NICKNAME_COLLECTION)
			.doc(targetUid)
			.set({nickname: newNickname},{merge: true})
			.then(()=>{return 1;})
			.catch(err => {
				console.error('Error saving nickname: ', err);
				return 0;
			});	
	},
	

	//////////////////////// CHAT //////////////////////////////////
	listenChatUpdates: async function(cbNewChatDay, cbNewMessage, cbNewChatCreated, chatIds){
		for(var c of chatIds){
			const chatId = c
			db.collection(CHAT_COLLECTION)
				.doc(chatId)
				.collection(CHAT_MESSAGE_COLLECTION)
				.onSnapshot(messageSnapshot => {
					messageSnapshot.docChanges().forEach(change => {
						if(change.type === "added"){
							// console.log('message was added on new day: ', change.doc.id);
							// console.log('change.doc.data(): ', change.doc.data().messages.length);
							cbNewChatDay(chatId, change.doc.id, change.doc.data());
						}
						else if(change.type === "modified"){
							// console.log('MODIFIED: ', change.doc.data());
							cbNewMessage(chatId, change.doc.id, change.doc.data());
						}
					})
				})
		}
	},
	listenUserChatIdChanges: async function(uid, cbNewChatCreated){
		// Listen under the user's chatarray
		db.collection(USER_COLLECTION)
			//.doc(uid)
			.onSnapshot(userSnapshot => {
				userSnapshot.docChanges().forEach(change => {
					// Make sure the update is for this user
					if(change.doc.id !== uid) return;

					if(change.type === "added"){
						// New ChatIds created (user's first chat)
						cbNewChatCreated(change.doc.get("chatIds"), change.type);
					} 
					else if (change.type === "modified"){
						// Chat was modified
						//console.log('MODIFIED: ', change.doc.get("chatIds"));
						cbNewChatCreated(change.doc.get("chatIds"), change.type);
					}
				})
			});
	},
	createNewChat: async function(uid, targetUid){
		var chatId = '';
		return db.collection(CHAT_COLLECTION)
			.add({
				participants: [
					uid, targetUid
				],
				createdOn: firebase.firestore.FieldValue.serverTimestamp(),
				// Read positions
				[`${uid}`]: 0,
				[`${targetUid}`]: 0
			}).then(doc => {
				//console.log("Created Chat Document with Id: ", doc.id);
				chatId = doc.id;
				// Save to user's account
				db.collection(USER_COLLECTION)
					.doc(uid)
					.set({
						chatIds: firebase.firestore.FieldValue.arrayUnion(chatId)
					}, {merge:true})
					.then(()=>{
						//console.log("Chat Id stored with own Uid successfully")
					})
					.catch(err => console.error("Error saving chat ID to user: ", err));

				db.collection(USER_COLLECTION)
					.doc(targetUid)
					.set({
						chatIds: firebase.firestore.FieldValue.arrayUnion(chatId)
					}, {merge:true})
					.then(()=>{
						//console.log("Chat Id stored with targetUid successfully: ", chatId)
					})
					.catch(err => console.error("Error saving chat ID to user: ", err));
				return chatId;
			}).catch(err => console.error("Error creating new chat: ", err));	
	},
	/*
		Set the chat message position to read from the end
		The end is used because if it's the start then it will be hard to keep count once the chat is large, the whole chat will have to be loaded to check the last index
	*/
	setReadChatMessagesIndexForUser: async function(chatId, uid){
		db.collection(CHAT_COLLECTION)
			.doc(chatId)
			.set({
				[`${uid}`]: 0
			}, {merge: true })
			.then(()=>{return;})
			.catch(err => console.error("Error readChatMessage: ", err, chatId, uid));
	},
	listenAllChatMessagesIndexForUser: async function(chatIds, uid, cb){
		db.collection(CHAT_COLLECTION)
			.onSnapshot(docs => {
				docs.docChanges().forEach(change => {
					//console.log('doc updated: ', change)
					if(chatIds.includes(change.doc.id)){
						if(change.type === 'added' || change.type === 'modified'){
							//console.log('uid: ', uid);
							const obj = change.doc.data();
							const targetUid = Object.keys(obj).filter((f)=>{
								return f !== 'participants' && f !== uid;
							})[0];
							//console.log('chat unreadIndex modified: ', targetUid);
							if(obj[targetUid] !== undefined)// && obj[targetUid] === 0) 
								cb(change.doc.id, obj[targetUid]);
						}
					}
				})
			}, (err => console.error("Error loadingReadChatMessagesIndex: ", err, uid))
		);	
	},
	listenMyUnreadChatForUser: async function(chatIds, uid, cb){
		db.collection(CHAT_COLLECTION)
			.onSnapshot(docs => {
				docs.docChanges().forEach(change => {
					if(chatIds.includes(change.doc.id)){
						if(change.type === 'added' || change.type === 'modified'){
							const obj = change.doc.data();
							const targetUid = Object.keys(obj).filter((f)=>{
								return f !== 'participants' && f === uid;
							})[0];
							if(obj[targetUid] !== undefined && obj[targetUid] > 0)// && obj[targetUid] === 0) 
								cb(change.doc.id);
						}
					}
				})
			})
	},
	sendChatMessage: async function(chatId, messageObj, receiverUid){
		// Increment the value at the document for the other user
		db.collection(CHAT_COLLECTION)
			.doc(chatId)
			.set({
				[`${receiverUid}`]: firebase.firestore.FieldValue.increment(1)
			}, {merge: true})
			.then(()=>{})
			.catch(err => console.error("Error sendingChatMessage: ", err));
		
		// TODO combine this query with the previous one
		const latestMessageRef = db.collection(CHAT_COLLECTION)
			.doc(chatId)
			.collection(CHAT_MESSAGE_COLLECTION)
			// By default, orders by document Id, so take the last one
			//.orderBy('timestamp', 'desc') // Message object's date parameter (The day the message was sent)
			//.limit(1);
		latestMessageRef.get()
			.then(querySnapshot => {
				//console.log("querySnapshot.size: ", querySnapshot.size);
				if(!querySnapshot.size){
					const timestamp = Date.now();
					// Send the first message
					db.collection(CHAT_COLLECTION)
						.doc(chatId)
						.collection(CHAT_MESSAGE_COLLECTION)
						.doc(timestamp.toString())
						.set({
							//timestamp: timestamp, // Causes duplicates
							messages: [{...messageObj, unread: true}],
						})
						.then(()=>{
							//console.log("Message set successfully 1")
						})
						.catch(err => console.error("Error sending chat message 1: ", err));				
				} else {
					//var latestDocTimestamp = querySnapshot.docs[0].id;
					var latestDocTimestamp = querySnapshot.docs[querySnapshot.size - 1].id;

					// Check the day difference in timestamps
					var dt = new Date(parseInt(latestDocTimestamp));
					//var dt = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
					var today = new Date();
					var diff = (dt.getTime() - today.getTime()) / 1000;
					diff /= (60 * 60 * 24);
					//diff = Math.abs(Math.round(diff/365.25)) - 1;
					if(Math.abs(diff) > 1){
						latestDocTimestamp = Date.now();
					}
					db.collection(CHAT_COLLECTION)
						.doc(chatId)
						.collection(CHAT_MESSAGE_COLLECTION)
						.doc(latestDocTimestamp.toString())
						.set({
							//timestamp: latestDocTimestamp, // causes duplicates
							messages: firebase.firestore.FieldValue.arrayUnion({
								timestamp: messageObj.timestamp,
								message: messageObj.message,
								sender: messageObj.sender,
								unread: true,
							})
						}, {merge: true})
						.then(() => {
							//console.log("Successfully set message 2");
						})
						.catch(err => console.error("Error sending chat message 2: ", err));
				}
			})
	},
	//TODO use this
	createNewMessageDay: async function(chatId){
		db.collection(CHAT_COLLECTION)	
			.doc(chatId)
			.collection(CHAT_MESSAGE_COLLECTION)
			.doc(Date.now().toString())
			.set({default: "set"})
			.then(()=>{})
	},
	getChatById: async function(uid, chatId){
		const result = {};
		const promises = [];
		promises.push(db.collection(CHAT_COLLECTION)
			.doc(chatId)
			.collection(CHAT_MESSAGE_COLLECTION)
			.orderBy("timestamp", "asc") // Need to change the clientside which is also sorting.
			.get()
			.then(chatMessagesSnapshot => {
				const c = [];
				//const c = {};
				chatMessagesSnapshot.forEach(chatsDoc => {
					//console.log("chatsDoc.get('messages'): ", chatsDoc.get('messages'));
					c.push(chatsDoc.get("messages"));
					//console.log("c: ", c);
					//c[chatsDoc.id.toString()] = chatsDoc.get("messages");
				})
				result["messages"] = c;
				// Trying out using snapshot to load the messages
				//result["messages"] = [];
				//console.log("result: ", result);
				return result;
			}).catch(err => console.error("Error finding emssages for chat: ", err))
		);

		promises.push(db.collection(CHAT_COLLECTION)
			.doc(chatId)
			.get()
			.then(async (doc) => {
				//console.log("found participants document: ", doc.id);
				result["participants"] = doc.get("participants") //doc.data();

				// Get the personal details of the participant
				const participantUids = result.participants.filter((participant)=>{
					return participant !== uid
				})
				//console.log("participantUid: ", participantUids);
				//for(var p of participantUids){
					promises.push(
						result["personalDetails"] = await this.getContactFieldsToShow(participantUids[0])
					);
				//}

				return doc.get("participants");
			}).catch(err => console.error("Error retrieving participants for chat: ", err))
		);

		return Promise.all(promises).then(()=>{
			//console.log("promises resolved: ", result);
			return result;
		})
	},
	getAllChatsForUser: async function(uid){
		//console.log("getting chats for user: ", uid);
		const result = [];
		const promises = [];
		await db.collection(USER_COLLECTION)
			.doc(uid)
			.get()
			.then(doc => {
				//console.log("Found chatId's: ", doc.id, " => ", doc.get("chatIds"));
				const chatIds = doc.get("chatIds");
				if(!chatIds) return;
				chatIds.forEach(async(chatId) => {
					//console.log("checking chatId: ", chatId);
					promises.push(this.getChatById(uid, chatId).then(data => {
						//console.log("data: ", data);
						// if(data.messages.length){
							result.push(data);
							//console.log("result: ", result);
							result[result.length - 1].id = chatId;
							//return result;
						// }
					})
					)
				});
				//console.log('returning chats: ', result);
				//return result;
			})
			.catch(err => console.error("Error getting chats for user: ", err))
		
		//console.log("promises: ", promises);
		return Promise.all(promises).then(()=>{
			//console.log("promises resolved: ", result);
			return result;
		})
	},

	/* ROOMS */
	// getAllRooms: async function(uid){
	// 	return await db.collection(ROOMS_COLLECTION)
	// },
}

//export default d;

//readChatMessage: async function(chatId, msgId){
	// 	db.collection(CHAT_COLLECTION)
	// 		.doc(chatId)
	// 		.collection(CHAT_MESSAGE_COLLECTION)
	// 		.doc(msgId)
	// 		.set({
	// 			unread: false
	// 		}, {merge: true })
	// 		.then(()=>{return;})
	// 		.catch(err => console.error("Error readChatMessage: ", err, chatId, msgId));
	// },

//.update({
			/*.set({
				messages: firebase.firestore.FieldValue.arrayUnion({
					timestamp: messageObj.timestamp,
					message: messageObj.message
				})
			})*/
			/*.then((doc)=>{
				console.log("doc: ", doc);
				console.log('found doc: ', doc.id, " => ", doc.data());
			})
			.catch(err => console.error("Error sending chat message: ", err));
		*/