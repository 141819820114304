import React from 'react';
import './skills.css';
import EditBtnWhole from './editBtnWhole.jsx';
import AddBtn from './addBtn.jsx';

export default class Skills extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            skills: props.skills,
            ownPage: props.ownPage
        }
    }

    editCallback = () => {
        this.props.fn_showModal('Qualities', undefined, undefined, this.props.skills, {hideDeleteBtn: true});
    }

    render(){
        return(
            <div className="">
                {
                    this.props.ownPage && !this.props.skills.length &&
                    <AddBtn callback={this.editCallback} />
                }
                <div className="skills-container" 
                    style={{marginTop:'0%',width: "100%"}}>
                    {
                        this.props.ownPage && 
                        this.props.skills.length ?
                        // <EditBtn callback={this.editCallback}/>
                        <EditBtnWhole callback={this.editCallback} />
                        : ""
                    }
                    {
                        this.props.skills.map((s,index) => (
                            <div key={"skill-" + index} className="g-inlineblock">
                                <Skill skill={s} />
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

function Skill(props){
    return <div className="skill-container">
        {props.skill}
    </div>
}