import React from 'react';
import helpers from '../helpers.js';
import EditBtn from './editBtn.jsx';
//import AddBtn from './addBtn.jsx';
import './faq.css';
import l from '../language.js';

export default class FAQ extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            ownPage: props.ownPage
        }
    }

    addCallback = () => {
		this.props.fn_showModal(
            this.props.ownPage ? 'FAQ_ANSWER' : 'FAQ_ASK', undefined, undefined, this.props.faq);
    }

    editCallback = (data) => {
        //console.log(data);
        this.props.fn_showModal(
            this.props.ownPage ? 'FAQ_ANSWER' : 'FAQ_ASK', undefined, undefined, {
                id: data.timestamp.toString(), 
                question: data.question,
                answer: data.answer
            });
    }

    render(){
        //console.log('faq: ', this.props.faq)
        return(
            <div>
                {
                    !this.state.ownPage && <AskQuestionBtn callback={this.addCallback} />//<AddBtn callback={this.addCallback} />
                }
                {
                    Object.keys(this.props.faq).length
                    ?<div>
                    {
                        Object.keys(this.props.faq).map((faq, index) => (
                            <div key={'faq-'+index}>
                                <FAQItem 
                                    editCallback={this.editCallback} 
                                    faq={this.props.faq[faq]}
                                    ownPage={this.props.ownPage} />
                            </div>
                        ))
                    }</div>
                    : <div>
                        {/* <span id="faq-empty">Ask a question</span> */}
                    </div>
                }
            </div>
        );
    }
}

function AskQuestionBtn(props){
    return <div className="faq-ask-question-btn" 
                onClick={()=>{props.callback()}}>
        {l.get('FAQ_ASK_QUESTION')}
    </div>
}

function FAQItem(props){
    return <div className="g-relative g-flex g-space-between g-fd-row-reverse btn-hoverable relationshipContainer">
        <div>{
            props.ownPage && !props.faq.answer 
            ? <FAQReplyBtn data={props.faq} callback={props.editCallback} />
            : props.ownPage && <EditBtn callback={props.editCallback} data={props.faq} />
        }</div>
        <div>
            <div className="faq-q-container">
                <span className="faq-timestamp">{helpers.convertTimestampToDate(Number(props.faq.timestamp)) + ' ' + helpers.convertTimestampToYear(Number(props.faq.timestamp))}</span>
                <span className="faq-q">{props.faq.question ? props.faq.question.substring(0,1).toUpperCase() + props.faq.question.substring(1) : ""}</span>
            </div>

            <div className="faq-a-container">
                <span className="faq-a">{props.faq.answer ? props.faq.answer.substring(0,1).toUpperCase() + props.faq.answer.substring(1) : ""}</span>
            </div>
        </div>
    </div>
}

class FAQReplyBtn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            callback: props.callback,
            data: props.data
        }
    }

    render(){ 
        return(
            <div onClick={()=>this.props.callback(this.props.data)}>
                <div className="faq-reply-btn" >
                    <span>{l.get('FAQ_REPLY_BTN')}</span>
                </div>
            </div>
        );
    }
}