import React from 'react';
import './loader.css';

export default class Loader extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			marginTop: props.marginTop || 20
		}
	}

	render(){
		return(
			<div style={{marginTop: `${this.state.marginTop}%`}}>
				<div className="lds-facebook">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}
}
