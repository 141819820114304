import React from 'react';
import edit from '../Assets/edit.svg';

export default class EditBtnWhole extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            top: props.top
        }
    }

    render(){
        return(
            <div onClick={()=>this.props.callback()}>
                <div className="editBtnWholeContainer" style={{top:this.props.top || "20px"}} >
                    <img src={edit} alt="" />
                </div>
            </div>
        );
    }
}
