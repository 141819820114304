import React from 'react';
import axios from 'axios';
import db from '../db';
import './referenceTokenPage.css';
import close from '../Assets/close-black.svg';
import Loader from '../Browse/loader.js';
import config from '../config.js';
import helpers from '../helpers.js';

export default class ReferenceTokenPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            token: props.match.params.referenceToken,
            loading: true,
            tokenValidated: false,
            uid: undefined,
            referenceId: undefined,
            destinationEmailAddress: undefined,
            myEmail: undefined,
            dontDisplay: true,
            referenceSent: false
        }
        this.textAreaRef = React.createRef();
        this.instructionsRef = React.createRef();
        this.instructionsRef2 = React.createRef();
    }

    componentDidMount(){
        db.validateReferenceToken(this.state.token).then(res => {
            //console.log('validating token: ', res);
            this.setState({
                tokenValidated: !res.expired,
                loading: false,
                uid: res.uid,
                displayName: res.displayName,
                referenceId: res.referenceId,
                myEmail: res.myEmail,
                destinationEmailAddress: res.destinationEmailAddress,
                dontDisplay: false
            }, ()=>{
                //console.log('this.state.referenceId: ', this.state);
            })
        })
    }

    _submitReference = () => {
        const reference = this.textAreaRef.current.value;
        if(reference 
            && reference.length 
            && this.state.uid 
            && this.state.referenceId 
            && this.state.destinationEmailAddress
            && this.state.myEmail){
            const r = {
                msg: reference,
                pending: '',
                verified: Date.now()
            }
            this.setState({
                loading: true
            });
            db.saveReference(this.state.uid, this.state.referenceId, r).then(async ()=>{
                db.consumeReferenceToken(this.state.token);
                if(!await helpers.checkEmailStatusFromUID(this.state.uid)) return; //TODO check this

                axios({
                    method: 'POST', //'POST' // Not working due to CORS
                    url: `${config.SERVER_ADDRESS}/referenceConfirmation/`,
                    data: {
                      email: this.state.destinationEmailAddress,
                      myEmail: this.state.myEmail,
                      uid: this.state.uid
                    }
                })
                .then(res => {
                    //console.log("Creating CONFIRMATION email for user: ", res);
                    this.setState({
                        referenceSent: true,
                        loading: false
                    })
                }, (error) => {
                    console.error("Error posting CONFIRMATION reference email");
                })
            })
        }
    }

    _closePressed = () => {
        this.instructionsRef.current.style.display = "none";
    }

    _closePressed2 = () => {
        this.instructionsRef2.current.style.display = "none";
    }

    render(){
        return(
            <div id="referenceTokenPage">
                {
                    this.state.loading ? 
                    <div>
                        <Loader />
                    </div>
                    : !this.state.loading && !this.state.tokenValidated 
                    ? <div className="linkNotFound">
                        <h2>Oops, this link has expired</h2>
                        <h4><b>Here are a few possibilities</b></h4>
                        <p>You've already written a reference for {this.state.displayName ? this.state.displayName.split(" ")[0] : ""}</p>
                        <p>We've done something wrong, <a href={"mailto:" + config.CONTACT_EMAIL}>Contact us</a></p>
                        <p>If you're copying and pasting a url, make sure that you've pasted the whole link into your browser</p>
                    </div>
                    : this.state.referenceSent 
                        ? <div className="linkNotFound">
                            <h2>Awesome</h2>
                            <h4>Your reference was created successfully</h4>
                            <p><a href={config.PUBLIC_URL + '/user/' + this.state.uid}>Check out {this.state.displayName.split(" ")[0]}'s profile</a></p>
                            <p><a href={config.PUBLIC_URL}>Check out other profiles on {config.APP_TITLE}</a></p>
                        </div>
                        : !this.state.dontDisplay   
                            ? <div>
                                <div className="titleContainer">
                                    <p style={{textAlign:"left"}}>Reference requested for</p>
                                    <p className="titleText">{this.state.displayName}</p>
                                </div>
                                <div ref={this.instructionsRef} className="instructions-container">
                                    <img src={close} onClick={()=>{this._closePressed()}} alt="" />
                                    <div className="whatItMeans">
                                        <p className="">Unsure how to write a reference? Try answering these questions</p>
                                        <p>Remember, it doesn't need to be long to be effective</p>
                                    </div>
                                    <div className="instructions">
                                        <p>What do/did you feel a lot of while you were together</p>
                                        <p>Something that only they can do!</p>
                                        <p>What do you miss about them?</p>
                                    </div>
                                </div>
                                <div ref={this.instructionsRef2} className="instructions-container">
                                    <img src={close} onClick={()=>{this._closePressed2()}} alt=""/>
                                    <p style={{marginBottom:"0px"}}>This link is valid for one reference</p>
                                </div>
                                <textarea ref={this.textAreaRef} placeholder="Start writing..."></textarea>
                                <div className="submitBtn" onClick={()=>{this._submitReference()}}>
                                    <label>Save</label>
                                </div>
                            </div>
                            : ""
                }
            </div>
        );
    }
}