import React from 'react';
import close from '../../Assets/close-black.svg';

export default class AgeFilter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            from: props.default.from || 18,
            to: props.default.to || 20,
            fromLimit: 20,
            toLimit: 80,
            toPlaceholder: 20
        }
    }

    componentDidMount(){
        console.log(this.props.default);
        if(this.props.default){
            this.setState({
                from: this.props.default.from,
                to: this.props.default.to
            })
        }
    }

    updateFrom = (e) => {
        console.log('from: e.target.value: ', e.target.value);
        this.setState({
            from: Number(e.target.value),
            //fromPlaceholder: Number(e.target.value)
        })
        this.props.f_searchWithFilters();
    }

    updateTo = (e) => {
        console.log('to: e.target.value: ', e.target.value);
        this.setState({
            to: Number(e.target.value)
        })
        this.props.f_searchWithFilters();
    }

    render(){
        return(
            <div className="sf-no-close sf-activated g-relative">
                <div className="sf-active-filter-title-container f-1">
                    <span className="sf-active-filter-title">Age</span>
                </div>
                <div className="f-1 sf-no-close">
                    <span className="sf-filter-desc-txt">From</span>
                    <select name="sf-age-from" className="sf-no-close sf-read-me" onChange={this.updateFrom} defaultValue={this.props.default.from}>
                        <option value="" className="sf-no-close"></option>
                        {/* <option value={18} className="sf-no-close">18</option> */}
                        {
                            [...Array((this.state.toLimit - this.state.fromLimit) / 5)].map((e, i) => (
                                <option key={"sf-ageFrom-"+i} value={this.state.fromLimit + (i * 5)} className="sf-no-close">
                                    {this.state.fromLimit + (i * 5)}
                                </option>
                            ))
                        }
                    </select>

                    <span className="sf-filter-desc-txt">To</span>
                    <select name="sf-age-to" className="sf-no-close sf-read-me" onChange={this.updateTo} defaultValue={this.props.default.to}>
                        {/* {
                            [...Array(Math.max(1, (this.state.toLimit / 5) - Math.floor(this.state.from / 5)))].map((e, i) => (
                                <option key={"sf-ageTo-"+i} 
                                    //value={Math.max(this.state.from + (i * 5), this.state.to + (i * 5))} 
                                    value={this.state.fromPlaceholder + (i * 5)} 
                                    className="sf-no-close">
                                    {
                                        //Math.max(this.state.from + (i * 5), this.state.to + (i * 5))
                                    }
                                    {this.state.fromPlaceholder + (i * 5)} 
                                </option>
                            ))
                        } */}
                            <option value="" className="sf-no-close"></option>
                        {
                            [...Array((this.state.toLimit - this.state.fromLimit) / 5)].map((e, i) => (
                                20 + (i * 5) >= this.state.from 
                                ?<option key={"sf-ageFrom-"+i} 
                                    value={20 + (i * 5)} className="sf-no-close">
                                    {
                                        Math.max(20 + (i * 5), Number(this.state.fromLimit) + (i * 5))
                                    }
                                </option>
                                :""
                            ))
                        }
                    </select>
                    <div className="sf-close sf-no-close">
                        <img src={close} alt="" className="sf-no-close" onClick={()=>{this.props.f_closePressed('AgeFilter');}} />
                    </div>  
                </div>
            </div>
        );
    }
}