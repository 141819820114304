import React from 'react';
import LookingFor from './lookingFor.jsx';
import MustNotHave from './mustNotHave.jsx';
import './requirementsCombined.css';

export default class RequirementsCombined extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            lookingFor: props.lookingFor,
            mustNotHave: props.mustNotHave,
            ownPage: props.ownPage,
        }
    }

    componentDidUpdate(){
        if(this.state.lookingFor !== this.props.lookingFor){
            this.setState({
                lookingFor: this.props.lookingFor
            })
        }
    }

    render(){
        return(
            <div>
                <div className="g-relative btn-hoverable g-default-container g-791"> 
                    <div className="g-flex">
                        { this.state.ownPage || (this.state.lookingFor || []).length
                        ? <div className="g-relative btn-hoverable g-mg-r-15 g-f1" style={{maxWidth:"50%"}}>
                            <h2 className="requirementsCombined-header">Requirements</h2>
                            <LookingFor 
                                { ...this.props}
                                ownPage={this.state.ownPage}
                                fn_showModal={this.props.fn_showModal}
                                lookingFor={this.state.lookingFor} />
                        </div>
                        : ""
                        }
                        <div className="rc-center-separator"></div>
                        { this.state.ownPage || Object.keys(this.state.mustNotHave || {}).length
                        ? <div className=" g-mg-l-15 g-f1" style={{maxWidth:"50%"}}>
                            <h2 className="requirementsCombined-header">Must Not</h2>
                            <MustNotHave
                                ownPage={this.state.ownPage}
                                fn_showModal={this.props.fn_showModal}
                                mustNotHave={this.state.mustNotHave || {}} />
                        </div>
                        : ""
                        }
                    </div>
                </div>

            </div>
        );
    }
}