import React from 'react';
import EditBtn from './editBtn.jsx';
import AddBtn from './addBtn.jsx';
import './mustNotHave.css';

export default class MustNotHave extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mustNotHave: props.mustNotHave
        }
    }

    addCallback = () => {
		this.props.fn_showModal('Must Not', undefined, undefined, this.props.mustNotHave);
    }

    editCallback = (data) => {
        this.props.fn_showModal('Must Not', undefined, undefined, data);
    }

    render(){
        return(
            <div>
                {
                    this.props.ownPage && <AddBtn callback={this.addCallback} />
                }
                <div>
                {
                    Object.keys(this.props.mustNotHave).map((mnh, index)=>(
                        <div key={"mnh-" + index}>
                            {/* { index > 0 && <hr style={{margin:"0"}}/> } */}
                            <MustNotHaveItem 
                                mnh={this.props.mustNotHave[mnh]} 
                                ownPage={this.props.ownPage}
                                editCallback={this.editCallback} />
                        </div>
                    ))
                }
                </div>
            </div>
        );
    }
}

function MustNotHaveItem(props){
    //console.log(props.mnh)
    return <div className="g-relative btn-hoverable mustNotHaveItem-outer-container">
        {
            props.ownPage && <EditBtn callback={props.editCallback} data={props.mnh} />
        }
        { props.mnh.value &&
            <div className="mustNotHaveItem-inner-container">
                <span className="mustNotHaveItem-txt">
                    {props.mnh.value.substring(0,1).toUpperCase() + props.mnh.value.substring(1)}
                </span>
            </div>
    }
    </div>
}
