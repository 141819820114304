import React from 'react';
import './encourageFillInProfile.css';
import db from '../db.js';
import l from '../language.js';
//import add from '../Assets/add.svg';

export default class EncourageFillInProfile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            percentage: 1,
            fields: ["lookingFor", "aspirations", "relationshipHistory", "personalDetails", "references", "skills","mustNotHave"],
            changeAmt: 99 / 7,
            lowerMessage: '',
            leftovers: [],
            loaded: false,
            fn_showModal: props.fn_showModal,
            fn_updateReferencePopup: props.fn_updateReferencePopup,

            lookingFor: [],
            aspirations: [],
            relationshipHistory: [],
            personalDetails: {},
            references: [],
            skills: [],
            mustNotHave: []
        }
    }

    componentDidUpdate(){
        // Batched updates don't work when all are coming in at once, need to use an IF() instead of ELSE IF() as well as batched data management
        const fields = [];
        if(this.state.lookingFor !== this.props.lookingFor){
            //this.getNewPercentage("lookingFor");
            fields.push("lookingFor");
        } 
        if(this.state.aspirations !== this.props.aspirations){
            //this.getNewPercentage("aspirations");
            fields.push("aspirations");
        }
        if(Object.keys(this.state.relationshipHistory).length !== Object.keys(this.props.relationshipHistory).length){
            fields.push("relationshipHistory");
        }

        if(Object.values(this.state.personalDetails).filter((f)=>{return f !== undefined}).length !== Object.values(this.props.personalDetails).filter((f)=>{return f !== undefined}).length) { 
            //console.log('updating personal details!!!');
            fields.push("personalDetails");
        }
        if(this.state.references.length !== this.props.references.length){
            //this.getNewPercentage("references");
            fields.push("references");
        }
        if(this.state.skills !== this.props.skills){
            //this.getNewPercentage("skills");
            fields.push("skills");
        }
        if(Object.keys(this.state.mustNotHave).length !== Object.keys(this.props.mustNotHave).length){
            fields.push("mustNotHave");
        }
        if(fields.length){
            //this.getNewPercentage(fields);
            for(var fieldName of fields){
                this.setState({
                    [`${fieldName}`]: this.props[`${fieldName}`]
                })
            }
            this.getLeftovers();
        }
    }

    getLowerMessage = (newPercentage) => {
        //console.log(newPercentage)
        if(newPercentage >= 95){
            db.updateEncouragementStatus(this.props.authUid, false);
            global.showEncouragementStatus = false;
            global.showGetStartedStatus = false;
        }
    }

    getLeftovers = () => {
        const res = [];
        //const fields = ["relationshipHistory"];
        for(var f of this.state.fields){
            if(f === 'personalDetails'){
                if(!Object.values(this.props[f]).filter((f)=>{return f !== undefined}).length){
                    res.push(f);
                }
            }
            else {
                //console.log('checking: ',f, ':',  this.props[f]);
                if(Array.isArray(this.props[f])){
                    if(!this.props[f].length){
                        res.push(f);
                    }
                }
                else if(typeof this.props[f] === 'object'){
                    if(!Object.keys(this.props[f]).length){
                        res.push(f);
                    }
                }
            }
        }
        //console.log("leftovers: ", res);
        var newPercentage = (this.state.changeAmt * this.state.fields.length) - (this.state.changeAmt * res.length);
        if(newPercentage > 96){
            newPercentage = 100;
        }
        this.setState({
            percentage: newPercentage,
            lowerMessage: this.getLowerMessage(newPercentage),
            leftovers: res,
            loaded: true,
        })
        this.props.f_updateEFIPPercentage(newPercentage);
        // console.log(newPercentage);
        //return res;
    }

    render(){
        return(
            <div>
                { this.state.percentage < 95 &&
                <div className="efip-progress-border">
                    <div className="efip-progress-container" style={{width:`${this.state.percentage}%`}}>
                        { this.state.percentage > 5
                            ? <div className="efip-progress-center">
                                {Math.floor(this.state.percentage)}%
                            </div>
                            : ""
                        }
                    </div>
                </div>
                }
                <div style={{marginTop:"15px",textAlign:"left"}}>
                    { 
                        this.state.leftovers.length && this.state.loaded
                        // Show just the first one
                        ? [this.state.leftovers[0]].map((l, i) => (
                            <div key={"epif-"+i}>
                                <ItemTodo 
                                    l={l} 
                                    fn_showModal={this.props.fn_showModal}
                                    fn_updateReferencePopup={this.props.fn_updateReferencePopup} />
                            </div>
                        ))
                        : ""
                    }
                    <div className="g-flex g-space-between">{   this.state.percentage > 95 
                        ? <span className="epif-lowerMessage">
                            {l.get('EPIF_DONE_MSG')}
                            <hr style={{margin:"5px"}} />
                            {l.get('EPIF_DONE_MSG_2')}
                        </span>
                        : ""
                    }
                    {
                        this.state.percentage > 95 
                        ? <div className="epif-todo-btn-container" onClick={()=>{document.getElementById("randomPerson-txt").click();}}>
                            <span>{l.get('EPIF_DONE_BTN')}</span>
                        </div>
                        : ""
                    }
                    </div>
                </div>
            </div>
        );
    }
}

function ItemTodo(props){
    return <div className="epif-todo">
        <div>
            <h1 className="epif-todo-inner">{getPreMessage(props.l)}</h1>
            <span className="epif-todo-inner-sub">{getSubMessage(props.l)}</span>
        </div>
        <div className="epif-todo-img-container">
            <div className="epif-todo-btn-container" onClick={()=>{
                if(props.l === 'references'){
                    props.fn_updateReferencePopup(true, "Reference", "Ask a friend to give you a reference");
                } else {
                    props.fn_showModal(getModalName(props.l.substring(0,1).toUpperCase() + props.l.substring(1)), undefined, undefined, undefined)
                }
                }}>
                <span style={{whiteSpace:"nowrap"}}>Add {getButtonName(props.l.substring(0,1).toUpperCase() + props.l.substring(1))}</span>
            </div>
            {/* <img src={add} alt="" /> */}
        </div>
    </div>
}

function getPreMessage(l){
    switch(l){
        case 'aspirations':
            return "Let's add some Aspirations";
        case 'relationshipHistory':
            return "Let others know about your Relationship History";
        case 'lookingFor':
            return "Let others know what your Requirements are";
        case "personalDetails":
            return "Let's add your Personal Details";
        case "skills":
            return "What Skills do you possess?";
        case "references":
            return "Do you have any References in mind?"
        case "mustNotHave":
            return "Do you have anything you won't tolerate?"
        default:
            return '';
    }
}

function getSubMessage(l){
    switch(l){
        case 'aspirations':
            return "So people know what you're dreaming about";
        case 'relationshipHistory':
            return "That way, people will know what you've been through";
        case 'lookingFor':
            return "This helps to quickly find people who are a good fit for you";
        case "personalDetails":
            //return "So that others can identify how they are similar";
            return "So that people can relate to your specific quirks";
        case "skills":
            return "With this, others can understand you in depth";
        case "references":
            return "With the addition of references, you have a community supporting you"
        case "mustNotHave":
            return "By stating this, other people will know when to connect with you"
        default:
            return '';
    }
}

function getButtonName(l){
    switch(l){
        case 'RelationshipHistory':
            return 'Relationship History';
        case 'LookingFor':
            return 'Requirements';
        case 'PersonalDetails':
            return 'Personal Details';
        case 'MustNotHave':
            return 'Must Not Have';
        default:
            return l;
    }
}

function getModalName(l){
    switch(l){
        case 'LookingFor':
            return 'Looking For';
        case "MustNotHave":
            return 'Must Not'
        default: 
            return getButtonName(l);
    }
}

// console.log('updated: ', fieldName);
// console.log('this.props[`${fieldName}`]: ', this.props[`${fieldName}`]);
// console.log("this.state.relationshipHistory.length: ", this.state.relationshipHistory.length);

/*
const c = this;
setTimeout(()=>{
    c.setState({
        percentage: 50
    })
}, 3000);
*/

/*
DEPRECATED React doesn't handle multiple same updates at once
// numberSign = 0 for no change, 1 for positive change, -1 for negative change
updatePercentage = (fieldName, numberSign) => {
    console.log('updating percentage: ', fieldName, numberSign);
    this.setState({
        [`${fieldName}`]: this.props[`${fieldName}`],
        percentage: this.state.percentage + (this.state.changeAmt * numberSign)
    }, ()=>{
        console.log('new percentage: ', this.state.percentage);
    })
}
*/


/*
if(fieldName === 'relationshipHistory'){
    console.log(fieldName);
    console.log('this.state.relationshipHistory.lenght: ', this.state.relationshipHistory.length);
    console.log('this.props.relationshipHistory.length: ', this.props.relationshipHistory[0]);
}
*/

/*
   getNewPercentage = (fields) => {
        var newPercentage = this.state.percentage;
        for(var fieldName of fields){
            console.log(fieldName, this.props[`${fieldName}`]);
            // If new data has values and existing data does not, then increment
            // If new data has values and existing data has values, then no action
            // If new data does not have values and existing data has values, then decrement
            // If new data has values and existing data has values, then no action
            if(Array.isArray(this.props[`${fieldName}`])){
                if(this.props[`${fieldName}`].length){
                    if(this.state[`${fieldName}`] && this.state[`${fieldName}`].length){
                        // NO ACTION
                    }
                    else if(!this.state[`${fieldName}`] || !this.state[`${fieldName}`].length){
                        //this.updatePercentage(fieldName, 1);
                        newPercentage += this.state.changeAmt;
                        console.log('adding from field: ', fieldName)
                    }
                }
                else if(!this.props[`${fieldName}`].length){
                    if(this.state[`${fieldName}`] && this.state[`${fieldName}`].length){
                        //this.updatePercentage(fieldName, -1);
                        newPercentage -= this.state.changeAmt;
                    }
                    else if(!this.state[`${fieldName}`] || !this.state[`${fieldName}`].length){
                        // NO ACTION
                    }
                }
            }
            else if(typeof this.props[`${fieldName}`] === 'object'){
                if(Object.keys(this.props[`${fieldName}`] || {}).length){
                    if(Object.keys(this.state[`${fieldName}`] || {}).length){
                        // NO ACTION
                    }
                    else if(!Object.keys(this.state[`${fieldName}`] || {}).length){
                        //this.updatePercentage(fieldName, 1);
                        //console.log(fieldName, this.state[`${fieldName}`]);
                        if(Object.values(this.state[`${fieldName}`]).filter((f)=>{return f !== undefined}).length){
                            console.log('adding from field: ', fieldName)
                            newPercentage += this.state.changeAmt;
                        }
                    }
                }
                else if(!Object.keys(this.props[`${fieldName}`] || {}).length){
                    if(Object.keys(this.state[`${fieldName}`] || {}).length){
                        //this.updatePercentage(fieldName, -1);
                        newPercentage -= this.state.changeAmt;
                    }
                    else if(Object.keys(!this.state[`${fieldName}`] || {}).length){
                        // NO ACTION
                    }
                }
            }
            //console.log('updating field: ', fieldName, newPercentage);
            this.setState({
                [`${fieldName}`]: this.props[`${fieldName}`]
            })
        }
        //console.log('new percentage: ', newPercentage)
        this.setState({
            percentage: newPercentage,
            lowerMessage: this.getLowerMessage(newPercentage),
            leftovers: this.getLeftovers(fields),
            loaded: true
        })
    }
*/