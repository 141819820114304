import React from 'react';
import './signUpPrompt.css';
import signup from '../Assets/icons8/signin.png';
import l from '../language.js';

export default class SignUpPrompt extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        return (
            <div className="g-default-container g-791 sup-container g-flex">
                <div className="">
                    <h2>{l.get('SUP_TITLE')}</h2>
                    <div>
                        <p>{l.get('SUP_3')}</p>
                        <p>{l.get('SUP_2')}</p>
                        <p>{l.get('SUP_1')}</p>
                        {/* <p>{l.get('SUP_4')}</p> */}
                    </div>
                    {/* <span>All you need to do is </span> */}
                    <div className="sup-signin-btn" onClick={()=>{
                            const el = document.getElementById("signinBtn");
                            if(el)
                                el.click();
                        }}>
                        {l.get('SUP_BTN')}
                    </div>
                </div>
                <div id="sup-img">
                    <img style={{marginLeft:"20%",marginBottom:"-5%"}} src={signup} alt="" />
                </div>
            </div>
        );
    }

}
