import React from 'react';
import './likeArea.css';
import l from '../language.js';
import db from '../db.js';

export default class LikeArea extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selected: false,
            authUid: props.authUid,
            pageUid: props.uid,
            myLikes: props.myLikes,
        }
        this.heartRef = React.createRef();
    }

    componentDidMount(){
        this.setState({
            selected: this.checkPageIsLiked()
        });
    }

    componentDidUpdate(){
        if(Object.values(this.state.myLikes).length !== Object.values(this.props.myLikes).length){
            this.setState({
                myLikes: this.props.myLikes,
                selected: this.checkPageIsLiked(),
            });
        }
    }

    addLike(){
        db.updateLike(this.props.authUid, this.props.uid, 1);
    }

    removeLike(){
        db.updateLike(this.props.authUid, this.props.uid, 0);
    }

    checkPageIsLiked(){
        // console.log('checking page is liked: ', this.props.myLikes[this.props.uid]);
        // If it exists in the dictionary of likes, then it has already been liked
        var b = this.props.myLikes[this.props.uid] !== undefined;
        return b;
    }

    render(){
        return(
            // g-dark-background 
            <div className={"g-default-container g-791 g-text-center la-btn" + (this.state.selected ? " selected" : "")} onClick={()=>{
                this.setState({selected: !this.state.selected}, ()=>{
                    this.state.selected 
                        ? this.addLike() 
                        : this.removeLike();
                });   
            }}>
                {
                    !this.state.selected && <span className="la-default1">{l.get('LA_DEFAULT1')}</span>
                }
                {
                    !this.state.selected && <span className="la-default2">{l.get('LA_DEFAULT2')}</span>
                }
                <span className={"la-txt" + (this.state.selected ? " selected" : "")}>{l.get('LA_OH_MY')}</span>
                <span className={"la-txt2" + (this.state.selected ? " selected" : "")}>{l.get('LA_OH_MY2')}</span>
                <span className={"la-txt3" + (this.state.selected ? " selected" : "")}>{l.get('LA_OH_MY3')}</span>
                <div>
                    <div ref={this.heartRef} className={"la-heart" + (this.state.selected ? " selected" : "")}></div>
                </div>


            </div>
        );
    }
}
