import React from 'react';
import EditBtn from './editBtn.jsx';
import AddBtn from './addBtn.jsx';
import './relationshipHistory.css';

export default class RelationshipHistory extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            relationshipHistory: props.relationshipHistory
        }
    }

    addCallback = () => {
		this.props.fn_showModal('Relationship History', undefined, undefined, this.props.relationshipHistory);
    }

    editCallback = (data) => {
        this.props.fn_showModal('Relationship History', undefined, undefined, data);
    }
    
    getDifference = (startDateYear, startDateMonth, endDateYear, endDateMonth) => {
        if(startDateYear === "" || startDateMonth === "" || endDateYear === "" || endDateMonth === ""){
            return "";
        }
        var res = this.getDate(startDateYear, startDateMonth) + " - ";
        if(!endDateYear){
            res += "Present " + String.fromCharCode("0x2022") + " " + this.calcDuration(startDateYear, startDateMonth, new Date().getFullYear(), new Date().getMonth());
            return res;
        }
        res += this.getDate(endDateYear, endDateMonth) + " "; 
        res += String.fromCharCode("0x2022") + " " + this.calcDuration(startDateYear, startDateMonth, endDateYear, endDateMonth);
        return res;
    }

    getDate(year, month){
        return this.getMonthNameFromNumber(month) + " " + year;
    }

    getMonthNameFromNumber(number){
        switch(parseInt(number)){
            case 0: return "Jan";
            case 1: return "Feb";
            case 2: return "Mar";
            case 3: return "Apr";
            case 4: return "May";
            case 5: return "Jun";
            case 6: return "Jul";
            case 7: return "Aug";
            case 8: return "Sep";
            case 9: return "Oct";
            case 10: return "Nov";
            case 11: return "Dec";
            default: return "";
        }
    }

    getMonthNumberFromName(monthName){
        switch(monthName){
            case "January": return 0;
            case "February": return 1;
            case "March": return 2;
            case "April": return 3;
            case "May": return 4;
            case "June": return 5;
            case "July": return 6;
            case "August": return 7;
            case "September": return 8;
            case "October": return 9;
            case "November": return 10;
            case "December": return 11;
            default: return "";
        }
    }

    calcDuration = (startDateYear, startDateMonth, endDateYear, endDateMonth) => {
        var startDate = new Date(startDateYear, startDateMonth, 1);
        var endDate = new Date(endDateYear, endDateMonth, 1);
        var diff = this.monthDiff(startDate, endDate);
        return this.yearDiff(diff) + 
            (diff % 12 > 1
                ? diff % 12 + " Months"
                : diff % 12 > 0
                ? diff % 12 + " Month" 
                : "");
    }

    monthDiff(d1, d2) {
        var months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth(); // -= d1.getMonth() - 1
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    yearDiff(monthsDiff){
        return monthsDiff > 12 ? Math.floor(monthsDiff / 12).toString() + " Years " : "";
    }

    render(){
        //console.log("relationshipHistory: ", this.props.relationshipHistory)
        return(
            <div className="">
                {
                    this.props.ownPage && <AddBtn callback={this.addCallback} />
                }
                <div>
                    {
                        Object.keys(this.props.relationshipHistory).map((rh, index)=>(
                            <div key={"rh-" + index}>
                                { index > 0 && <hr /> }
                                <Relationship 
                                    rh={this.props.relationshipHistory[rh]} 
                                    getDifference={this.getDifference}
                                    ownPage={this.props.ownPage}
                                    getMonthNumberFromName={this.getMonthNumberFromName}
                                    editCallback={this.editCallback} />
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

function Relationship(props){
    //console.log('rh: ', props.rh)
    return <div className="g-relative btn-hoverable relationshipContainer">
        {
            props.ownPage && <EditBtn callback={props.editCallback} data={props.rh} />
        }
        <span className="rh-city">{ props.rh.city}</span>
        <span className="rh-country">{ (props.rh.city ? ", " : "") + props.rh.country }</span>
        <br />
        <span className="rh-Duration rh-grey">{
            props.getDifference(props.rh.startDateYear, props.getMonthNumberFromName(props.rh.startDateMonth), props.rh.endDateYear, props.getMonthNumberFromName(props.rh.endDateMonth))
        }</span>
        <br />
        { 
            props.rh.longDistance && <span className="rh-longdistance rh-grey">Long Distance</span>
        }
        <p className="rh-desc">{
            props.rh.desc    
        }</p>
    </div>
}

/*
Object.keys(props.rh).map(property => 
                <p>{props.rh[property].length ? property + ": " + props.rh[property] : ""}</p>
            )
*/
