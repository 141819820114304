//https://www.w3schools.com/howto/howto_custom_select.asp

import React from 'react';
import './selectStyledLikeText.css';

export default class SelectStyledLikeText extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            options: props.options, // []
            default: props.default,
            name: props.name,
            width: props.width || "150px"
        }
        this.selectStyled = React.createRef();
    }

    componentDidMount(){
        var x, i, j, selElmnt, a, b, c;
        /* Look for any elements with the class "custom-select": */
        x = [this.selectStyled.current]; //document.getElementsByClassName("selectStyled-custom-select");
        for (i = 0; i < x.length; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            /* For each element, create a new DIV that will act as the selected item: */
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            /* For each element, create a new DIV that will contain the option list: */
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < selElmnt.length; j++) {
                /* For each option in the original select element,
                create a new DIV that will act as an option item: */
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function(e) {
                    /* When an item is clicked, update the original select box,
                    and the selected item: */
                    var y, i, k, s, h;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    h = this.parentNode.previousSibling;
                    for (i = 0; i < s.length; i++) {
                        if (s.options[i].innerHTML === this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            for (k = 0; k < y.length; k++) {
                                y[k].removeAttribute("class");
                            }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
                h.click();
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        const component = this;
        a.addEventListener("click", function(e) {
            /* When the select box is clicked, close any other select boxes,
            and open/close the current select box: */
            e.stopPropagation();
            component.closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
            //console.log("select pressed: ", e.target.innerHTML);
            const currentText = e.target.innerHTML;
            //console.log("component.props.options[0]: ", component.props.options[0]);
            if(currentText !== component.props.options[0]){
                if(!e.target.classList.contains("m-modal-toRead")){
                    e.target.classList.add("m-modal-toRead")
                    e.target.value = currentText;
                    e.target.name = component.props.name;
                }
            } 
            else if(currentText === component.props.options[0]){
                if(e.target.classList.contains("m-modal-toRead"))
                    e.target.classList.remove("m-modal-toRead");
            }
        });
        }
        /* If the user clicks anywhere outside the select box,
        then close all select boxes: */
        document.addEventListener("click", this.closeAllSelect);
    }

    closeAllSelect = (elmnt) => {
        /* A function that will close all select boxes in the document,
        except the current select box: */
        var x, y, i, arrNo = [];
        x = document.getElementsByClassName("select-items");
        y = document.getElementsByClassName("select-selected");
        for (i = 0; i < y.length; i++) {
            if (elmnt === y[i]) {
                arrNo.push(i)
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (i = 0; i < x.length; i++) {
            if (arrNo.indexOf(i)) {
                x[i].classList.add("select-hide");
            }
        }
    }

    // updateOption = (index, e) => {
    //     console.log('SELECTSTYLEDLIKETEXT UPDATeUPtion: ', e.target.value);
    //     this.state.currentElement.classList.remove("m-modal-toRead");
    //     e.target.classList.add("m-modal-toRead");
    //     this.setState({
    //         default: this.props.default,
    //         currentIndex: index,
    //         currentElement: e.target,
    //         currentSelected: ""
    //     })
    // }

    // onChange = (e) => {
    //     console.log('SELECTSTYLEDLIKETEXT UPDATED: ', e.target.value);
    //     this.setState({
    //         currentSelected: e.target.value
    //     })
    // }

    render(){
        return(
            <div ref={this.selectStyled} className="selectStyled-custom-select" style={{width:this.state.width}}>
                <select 
                    name={this.props.name}
                    className=" g-inlineblock"//m-modal-toRead
                    defaultValue={this.props.default}
                    onChange={this.onChange}
                    >
                    {/* <option>Select a height</option> */}
                    {
                        this.state.options.map((o, index) => (
                            <option key={"editHeight-" + index} 
                                value={o} 
                                onClick={(e)=>{this.updateOption(index, e)}}>{o}</option>
                        ))
                    }
                </select>
            </div>
        );
    }
}
