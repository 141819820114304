import React from 'react';
import './mainDetails.css';
import db from '../db.js';
import helpers from '../helpers.js';
import EditBtn from './editBtn.jsx';
import AddBtn from './addBtn.jsx';
import AdditionalDetails from './additionalDetails.jsx';
import FavouritesHeader from './favouritesHeader.jsx';
import FloatingHeader from './floatingHeader.jsx';
import PersonalityChart from './personalityChart.jsx';
import l from '../language.js';

import heart from '../Assets/favorite_white.svg';
import arrow_down_blue from '../Assets/arrow_down_blue.svg';
//import axios from 'axios';
//import config from '../config.js';
//import save from '../Assets/save.svg';

export default class MainDetails extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			gender: "",
			bornDate: "",
			age: "",
			uid: props.uid,
			myFavourites: props.myFavourites, 
			dataObj: {},
			personalityCode: "",
			ownPage: props.ownPage,
			authUser: props.authUser,
			authUid: props.authUid,
			personalDetails: props.personalDetails,
			additionalDetails: props.additionalDetails,
			personalityValues: props.personalityValues,
			showRefer2FriendModal: false,
			awaiting: [],
			pending: [],
			accepted: [],
			favBtn: ""
        }
	}

	componentDidMount(){
		this.calculateFavourites();
	}

	componentDidUpdate(){
		if(this.state.ownPage !== this.props.ownPage){
			this.setState({
				ownPage: this.props.ownPage
			})
		}
		if(this.state.uid !== this.props.uid){
			this.setState({
				uid: this.props.uid
			});
		}
		if(this.state.authUid !== this.props.authUid){
			this.setState({
				authUid: this.props.authUid
			});
		}
		if(this.state.additionalDetails !== this.props.additionalDetails){
			//console.log("updating additional details: ", this.props.additionalDetails);
			this.setState({
				additionalDetails: this.props.additionalDetails
			});
		}
		if(this.state.personalityValues !== this.props.personalityValues){
			this.setState({
				personalityValues: this.props.personalityValues
			})
		}
		if(this.state.myFavourites !== this.props.myFavourites){
			//console.log('this.props.myFavourites: ', this.props.myFavourites);
			this.calculateFavourites();
		}
	}

	calculateFavourites = ()=> {
		const awaiting = [], pending = [], accepted = []
		for(var favObj of this.props.myFavourites){
			if(favObj.status === "pending"){
				pending.push(favObj.uid)
			} else if(favObj.status === "awaiting"){
				awaiting.push(favObj.uid);
			} else if(favObj.status === "accepted"){
				accepted.push(favObj.uid);
			}
		}
		this.setState({
			myFavourites: this.props.myFavourites,
			pending: pending,
			accepted: accepted,
			awaiting: awaiting
		}, ()=>{
			this.setFavBtn();
		})
	}
	
	convertKeyName2DBName(key){
		switch(key){
			case "bornDate": return "Date of birth";
			case "email": return "Email";
			case "gender": return "Gender";
			case "occupation": return "Occupation";
			case "liveLocation": return "Your Location";
			case "availability": return "Availability";
			case "emailMe": return "Email me about changes";
			default: return "";
		}
	}

	expandArea = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }
/*
	getDisplayKey(key){
		//console.log('getting key: ', key);
		switch(key){
			case "displayName" : return "Name: ";
			//case "gender" : return "Gender: ";
			//case "bornDate" : return "Age: ";
			case "email" : return "Email: ";
			case "bornLocation" : return "Born: ";
			case "liveLocation" : return "";//return "Living in ";
			case "facebookURL" :
			case "occupation":
			case "story1" : return "";
			default : return "";
		}
	}
*/
	getDisplayValue(key){
		const value = this.props.personalDetails[this.convertKeyName2DBName(key)];
		switch(key){
			case "bornDate" : return helpers.convertStringToDate(value);
			case "bornLocation" : return "Born In " + value;
			case "occupation": return value;
			//case "availability": return (value === "Yes" ? "Available for a relationship" : "In a relationship"); //for a relationship
			case "availability": 
				return value === "Yes" 
				//? "Available for a relationship" 
				? "Available" 
				: value === "No" 
				? "Unavailable"
				: ""; //for a relationship
			//case "availability": return (value === "Yes" ? "Available for a relationship" : ""); //Looks bad, so put this until further notice //for a relationship
			case "displayName" :
			case "facebookURL" :
			case "email" :
			case "photoUrl" :
			case "story1" :
			case "story2" : return ""//""
			default : return this.props.personalDetails[this.convertKeyName2DBName(key)];
		}
	}

	_contactRequest = (targetId) => {
		if(global.authUid.length) db.createContactRequest(global.authUid, targetId);
		else {
			alert("Sign in to Say Hi");
		}
	}

	f_savePersonalityAnswers = (data) => {
		this.setState({
			personalityValues: data.personalityValues,
			personalityCode: data.personalityCode
		})
	}

	editCallback = () => {
		//console.log("this.props.additionalDetails: ", this.props.additionalDetails);
		this.props.fn_showModal('Personal Details', undefined, undefined, {
			personalDetails: this.props.personalDetails,
			additionalDetails: this.props.additionalDetails});
	}

	sendMsgCallback = (uid) => {
		if(!this.props.authUid) return
		if(!this.state.authUid && !global.authUid){
			//console.log("please login");
			//TODO navigate to sign page when it is ready
		}
		this.props.fn_sendNewMsg(uid);
		
		const el = document.getElementsByClassName("msgArea-header")[0];
		if(!el.classList.contains("showing"))
			el.click();
		else {
			document.getElementById("chat-0").click();
		}
	} 

	favoriteCallback = async (uid) => {
		if(!this.props.authUid) return
		const result = await this.props.f_favorite((this.state.authUid || global.authUid), uid);
		//console.log('received result: ', result);
		if(result){
			this.props.f_reloadFavourites();
		}
		//global.reloadFavourites(this.props.authUid);
	}

	referToFriendCallback = (data) => {
		if(!this.props.authUid) return
        this.props.fn_refer2Friend(true, 'ReferProfile2Friend', "Refer this profile to a friend");
	}

	setFavBtn = () => {
		//console.log(this.props.match.params.userId);
		//console.log('this.state: ', this.state);
		if(this.state.accepted.includes(this.props.match.params.userId)){
			this.setState({ 
				favBtn: <h2 className="g-ww-nowrap personal-details-msgBtn personal-details-connected">{l.get('MD_CONNECTED')} <img src={heart} alt="" /></h2>
			});
		} 
		else if(this.state.pending.includes(this.props.match.params.userId)){
			this.setState({
				favBtn: <h2 className="g-ww-nowrap personal-details-msgBtn personal-details-pending">{l.get('MD_AWAITING_RESPONSE')} </h2>
			});
		}
		else if(this.state.awaiting.includes(this.props.match.params.userId)){
			this.setState({
				favBtn: <h2 className="g-ww-nowrap personal-details-msgBtn personal-details-pending">{l.get('MD_WAITING4RESPONSE')} </h2>
			}); 
		}
		else {
			this.setState({
			favBtn: <h2 id="favoriteBtn" className={"g-ww-nowrap personal-details-msgBtn" + (!this.props.authUid ? " personal-details-msgBtn-disabled " : "")} onClick={()=>{this.favoriteCallback(this.props.match.params.userId)}}>{l.get('MD_FAVOURITE')}</h2>
			});
		}
			// : <h2 className="g-ww-nowrap personal-details-msgBtn personal-details-connected">
			// 	Connected 
			// 	<img src={favourite} alt="" />
			// </h2>			//<img src={favourite} alt="" />
	}

	render(){
		//console.log("Object.values(this.props.personalDetails).filter((f)=>{return f !== undefined}).length: ", Object.values(this.props.personalDetails).filter((f)=>{return f !== undefined}).length);
		//console.log(this.props.authUid);
		//console.log(this.state.personalityValues)
		return(
			<div>
				<FloatingHeader bornDate={this.getDisplayValue("bornDate") || ""}
					gender={this.getDisplayValue("gender") || ""}
					location={this.getDisplayValue("liveLocation") || ""}
					availability={this.getDisplayValue("availability") || ""}
					favourites={this.state.favourites}
					myFavourites={this.state.myFavourites}
					ownPage={this.props.ownPage}
					authUid={this.state.authUid}
					totalRequests={this.props.totalRequests}
					accepted={this.props.accepted}
					rejected={this.props.rejected}
					myLikes={this.props.myLikes}
					uid={this.props.uid}
					favBtn={this.state.favBtn} />
				
				 <div>
				 	{
						!Object.values(this.props.personalDetails).filter((f)=>{return f !== undefined}).length &&
						this.state.ownPage &&
						<AddBtn callback={this.editCallback} />
					}
				{ Object.values(this.props.personalDetails).filter((f)=>{return f !== undefined}).length
				? <div>
					<div className="g-flex g-row g-mg-auto g-relative btn-hoverable md-reverseContainer">
						{ this.state.ownPage || Object.values(this.props.personalDetails).filter((f)=>{return f !== undefined}).length
							? <div className="g-f1 g-mg-auto" style={{marginTop:'0%',width: "95%"}}>
								{
								Object.keys(this.props.personalDetails).length && this.props.ownPage
									? <EditBtn callback={this.editCallback} />
									: ""
								}
								<div className="g-flex g-space-between" style={{width: "calc(100% - 25px)"}}>{/* <hr style={{margin: '0px',width:'95%'}}/> */}
									<div className="g-w-100">
										
										<div className="g-flex g-space-between g-w-100" style={{height:"42px"}}>
											<div>
												<p className="personal-details-top">
													<label className="g-inlineblock">{
														this.getDisplayValue("bornDate")
													}</label>
													<label className="g-inlineblock">{
														this.getDisplayValue("gender")
													}</label>
													{/* <label className="g-inlineblock">{
														this.getDisplayValue("liveLocation")
													}</label> */}
												</p>
											</div>
											<div id="favourites-btns-container">
												{/* {
													!this.state.ownPage && !this.state.authUid &&
													<span style={{color:"red"}}>* Please login to send a message</span>
												} */}
												{
													!this.state.ownPage// && this.props.authUid && this.props.authUid.length
													&& Object.values(this.props.personalDetails).filter((f)=>{return f !== undefined}).length 
													? <div className="g-flex g-row">
														{this.state.favBtn}

														<h2 id="refer2FriendBtn"
															className={"g-ww-nowrap personal-details-msgBtn" + (!this.props.authUid ? " personal-details-msgBtn-disabled " : "")}
															onClick={()=>{this.referToFriendCallback(this.state.uid)}}
															style={{marginLeft:"20px"}}>
															{l.get('MD_REFER2FRIEND')}</h2>

														<h2 id="messageBtn"
															className={"g-ww-nowrap personal-details-msgBtn" + (!this.props.authUid ? " personal-details-msgBtn-disabled " : "")}
															onClick={()=>{this.sendMsgCallback(this.state.uid)}}
															style={{marginLeft:"20px"}}>
															{l.get('MD_MESSAGE')}</h2>
													</div>
													: ""
												}
												<div className="g-flex "> {/*g-flex-end */}
													<FavouritesHeader favourites={this.state.favourites || []} />
												</div>
											</div>
										</div>

										<h2 className="personal-details-location">
											{this.getDisplayValue("liveLocation")}
											{/* {" " + String.fromCharCode("0x00B7") + " "} 
											<span className={"personal-details-" + this.getDisplayValue("availability").split(" ")[0]}>
												{this.getDisplayValue("availability")}
											</span> */}
										</h2>
										<h2 className="personal-details-occupation">{this.getDisplayValue("occupation")}</h2>
										<h2 className="personal-details-aboutme">{this.props.personalDetails["About Me"] || this.props.personalDetails["About you in 75 characters"]}</h2>
										{	Object.keys(this.props.additionalDetails).length
											? <AdditionalDetails 
												expanded={this.state.expanded}
												additionalDetails={this.props.additionalDetails} />
											: ""
										}
										{ this.state.ownPage || (this.state.personalityValues || []).length
										? <div className="personal-details-personality-container">
											{/* <h2>Personality</h2> */}
											<PersonalityChart 
												uid={this.props.uid}
												ownPage={this.state.ownPage}
												fn_showModal={this.editCallback} 
												personalityCode={this.state.personalityCode}
												personalityValues={this.state.personalityValues}
												expanded={this.state.expanded}
												f_savePersonalityAnswers={this.f_savePersonalityAnswers} />
										</div>
										: ""
										}
									</div>
								</div>
							</div>
							: ""
						}
					</div>
						{
							Object.keys(this.props.additionalDetails).length > 1
							? <div id="additionalDetailsLever">
								<div id="additionalDetailsLever-btn"onClick={()=>{this.expandArea()}}>
									<span>{!this.state.expanded ? "Show More" : "Show Less"}</span>
									<img id="additionalDetails-dropdown-arrow" 
										className={this.state.expanded ? "rotated" : ""} 
										src={arrow_down_blue} 
										alt="" />
								</div>
							</div>
							: ""
						}</div>
					: ""
					}
				</div>
				<div id="elementToShowFloatingHeader"></div>
			</div>
		);
	}
}

/*
<div className="g-mg-auto g-mg-t-30 g-mg-b-20" style={{width: "95%"}}>
								{
									this.state.dataObj["story2"] && this.state.dataObj["story2"].length > 0 &&
									<div>
										<label>I'm looking for: </label>
										<hr style={{margin: '0px'}}/>
										{
											this.state.dataObj["story2"]
										}
									</div>
								}
							</div>

							<div className="g-mg-auto g-mg-t-30 g-mg-b-20" style={{width: "95%"}}>
								{
									this.state.dataObj["story1"] && this.state.dataObj["story1"].length > 0 &&
									<div>
										<label>About me: </label>
										<hr style={{margin: '0px'}}/>
										{
											this.state.dataObj["story1"]
										}
									</div>
								}
							</div>

*/ 

