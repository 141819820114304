import React from 'react';
import './likesAreaRight.css';
import db from '../db.js';
import helpers from '../helpers.js';
import l from '../language.js';
import { Link } from "react-router-dom";

export default class LikesAreaRight extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: props.authUid,
            f_updateLikes: props.f_updateLikes,
            likes: [],
        }
    }

    componentDidMount(){
        // console.log(this.props.authUid);
        this.load();
    }

    

    load(){
        db.listenToLikes(this.props.authUid, this.updateLikes, this);
    }

    updateLikes(newLikes, component){
        component.setState({
            likes: newLikes
        });
        component.props.f_updateLikes(newLikes);
    }

    render(){
        return(
            <div>
                { Object.values(this.state.likes).length
                    ? <div className="lar-default g-mg-t-16 g-default-container">
                        <h2 className="lar-title">{l.get('LAR_TITLE')}</h2>
                        {/* <hr style={{margin:"6px 0"}}/> */}
                        {
                            Object.values(this.state.likes).map((like, i) => (
                                <div key={i} className="lar-container">
                                    <Link to={"/user/" + like.pageUid.uid} className="ANALYTICS_myLikesClicked">
                                        <span><b>
                                            {helpers.convertToDisplay(like.pageUid, helpers.convertStringToYear)}
                                        </b></span>
                                        <span className="g-span-dot"></span>
                                        <span>{like.pageUid['Your Location']}</span>
                                        <br/>
                                        <span style={{fontWeight: "200"}}>{helpers.displayAboutMe(like.pageUid)}</span>
                                        <br/>
                                        <span style={{color:"rgba(80,80,80,.65)",fontSize:".85rem"}}> {" " + helpers.convertServerTimestampToDate(like.updatedOn)}</span>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                    : ""
                }
            </div>
        );
    }
}
