import React from 'react';
import edit from '../Assets/edit.svg';
import './editBtn.css';

export default class EditBtn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            callback: props.callback,
            data: props.data
        }
    }

    render(){ 
        //console.log("edit data: ", this.props.data);
        return(
            <div onClick={()=>this.props.callback(this.props.data)}>
                <div className="editBtnContainer" >
                    <img src={edit} alt="" />
                </div>
            </div>
        );
    }
}
