// Header that pops up as soon as the page has been scrolled

import React from 'react';
import './floatingHeader.css';
import heart_yes from '../Assets/favorite.svg';
import heart_no from '../Assets/favorite_border.svg';
import db from '../db.js';

export default class FloatingHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            personalDetails: props.personalDetails,
            favourites: props.favourites,
            ownPage: props.ownPage,
            authUid: props.authUid,
            totalRequests: props.totalRequests,
            accepted: props.accepted,
            rejected: props.rejected,
            myLikes: props.myLikes,
            b_liked: false,
            uid: props.uid,
            favBtn: props.favBtn
        }
    }

    // componentDidMount(){
    //     this.setState({
    //         b_liked: this.checkPageIsLiked()
    //     });
    // }

    componentDidUpdate(){
        if(this.state.uid !== this.props.uid){
            this.setState({
                uid: this.props.uid,
                b_liked: this.checkPageIsLiked()
            })
        }
        if(this.state.personalDetails !== this.props.personalDetails){
            this.setState({
                personalDetails: this.props.personalDetails,
            })
        }
        if(this.state.favourites !== this.props.favourites){
            this.calcDisplayData();
        }
        if(this.state.ownPage !== this.props.ownPage){
            this.setState({
                ownPage: this.props.ownPage
            })
        }
        if(this.state.totalRequests !== this.props.totalRequests){
            this.setState({
                totalRequests: this.props.totalRequests,
                accepted: this.props.accepted,
                rejected: this.props.rejected
            })
        }
        if(Object.values(this.state.myLikes).length !== Object.values(this.props.myLikes).length){
            this.setState({
                myLikes: this.props.myLikes,
                b_liked: this.checkPageIsLiked()
            });
        }
    }

    componentDidMount(){
        document.getElementById("floatingHeader");
        //const header = new Headroom(fh);

        window.onscroll = function(){
            const elementToCheck = document.getElementById("elementToShowFloatingHeader");
            if(!elementToCheck) return;
            const heightToCheck = elementToCheck.offsetTop;
            const header = document.getElementById("floatingHeader");
            if(window.pageYOffset > heightToCheck){
                header.classList.add("floatingHeader-sticky");
                //header.style.display = "block";
                header.style.visibility = "visible";
                header.style.height = "fit-content";
                header.style.top = "52px";
            } else {
                header.classList.remove("floatingHeader-sticky");
                // header.style.display = "none";
                header.style.visibility = "hidden";
                header.style.height = "0px";
                header.style.top = "0";

            }
        }

        this.setState({
            b_liked: this.checkPageIsLiked()
        });
    }

    checkPageIsLiked(){
        // console.log('Updating b page is liked: ', this.props.myLikes, this.props.uid);
        // If it exists in the dictionary of likes, then it has already been liked
        // Same as <LikeArea />
        var b = this.props.myLikes[this.props.uid] !== undefined;
        // console.log(b);
        return b;
    }

    addLike(){
        db.updateLike(this.props.authUid, this.props.uid, 1);
    }

    removeLike(){
        db.updateLike(this.props.authUid, this.props.uid, 0);
    }

    render(){
        // console.log(this.state.b_liked);
        return(
            <div style={{display:`${this.props.ownPage ? "none" : "block"}`}}>
                <div id="floatingHeader">
                    <div id="floatingHeader-contents">
                        <div className={"floatingHeader-personalDetails "}>
                        {/* + this.props.location.length > 100 ? "" : " g-flex " */}
                            <div>
                                <img src={this.state.b_liked ? heart_yes : heart_no} alt="" className={this.state.b_liked ? "heart-yes": "heart-no"} onClick={()=>{
                                    this.setState({
                                        b_liked: !this.state.b_liked
                                    },()=>{
                                        this.state.b_liked ? this.addLike() : this.removeLike();
                                    });
                                    
                                }} />
                                <span className="floatingHeader-personalDetails-bolded">{this.props.bornDate}</span>
                                <span className="floatingHeader-personalDetails-bolded">{this.props.gender.substring(0, 1)}</span>
                                <span className="floatingHeader-personalDetails-location">{this.props.location}</span>
                            </div>
                            <div>
                                {/* <span className={"personal-details-" + (this.props.availability.split(" ")[0])}>
                                    {this.props.availability}
                                </span> */}
                            </div>
                        </div>
                        <div id="favourites-stats-container" className="favourites-stats-container-fh">
                            <div>
                                <span className="favourites-stats-data-floatingHeader">
                                    {this.state.totalRequests}
                                </span>
                                <span className="favourites-stats-desc-floatingHeader">Requests</span>
                            </div>
                            <div>
                                <span className="favourites-stats-data-floatingHeader">
                                    {this.state.accepted}
                                </span>
                                <span className="favourites-stats-desc-floatingHeader">Accepted</span>
                            </div>
                            <div>
                                <span className="favourites-stats-data-floatingHeader">
                                    {this.state.rejected}
                                </span>
                                <span className="favourites-stats-desc-floatingHeader">Rejected</span>
                            </div>
                        </div>
                        <div className="g-flex floatingHeader-buttons-container">
                            {this.props.favBtn}
                            <div onClick={()=>{document.getElementById("refer2FriendBtn").click()}} 
                                className={"personal-details-msgBtn" + (!this.props.authUid ? " personal-details-msgBtn-disabled " : "")}>
                                    Refer to a Friend</div>
                            <div onClick={()=>{document.getElementById("messageBtn").click()}} 
                                className={"personal-details-msgBtn" + (!this.props.authUid ? " personal-details-msgBtn-disabled " : "")}>
                                    Message</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}