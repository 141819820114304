import React from 'react';
import close from '../../Assets/close-black.svg';

export default class GenderFilter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selection: "",
            default: props.default
        }
    }

    update(field, e){
        this.setState({
            [`${field}`]: e.target.value
        }, ()=>{
            this.props.f_searchWithFilters();
        })
    }

    render(){
        return(
            <div className="sf-no-close sf-activated g-relative g-flex">
                <div className="sf-active-filter-title-container f-1">
                    <span className="sf-active-filter-title">Gender</span>
                </div>
                <div className="f-1 sf-no-close">
                    <span className="sf-filter-desc-txt"></span>
                    <select name="sf-gender" className="sf-no-close sf-read-me" onChange={(e) => this.update('selection', e)} defaultValue={this.props.default}>
                        {/* <option value="Select a gender" className="sf-no-close">Select a gender</option> */}
                        {
                            ['Select a gender','Male','Female','Other'].map((e, i) => (
                                <option key={"sf-gender-"+i} value={e} className="sf-no-close">
                                    {e}
                                </option>
                            ))
                        }
                    </select>
                    <div className="sf-close sf-no-close">
                        <img src={close} alt="" className="sf-no-close" onClick={()=>{this.props.f_closePressed('GenderFilter');}} />
                    </div>
                </div>
            </div>
        );
    }
}