import React from 'react';
import './interactionContainer.css';
import { SingleMsgArea } from '../chat/chat.jsx';

export default class InteractionContainer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            totalMessages: 0,
            nickname: props.nickname,
            currentUid: props.currentUid,
        }
        this.chatFormRef = React.createRef();
    }

    componentDidMount(){
        //console.log('chat: ', this.props.chat);
        this.calcNumberOfChats();
    }

    componentDidUpdate(){
        //return;
        // if(this.state.chat !== this.props.chat){
        //     this.setState({
        //         chat: this.props.chat
        //     });
        // }
        if(this.state.currentUid !== this.props.currentUid){
            this.setState({
                currentUid: this.props.currentUid
            });
        }

        // console.log(this.props.currentUid);
        // this.calcNumberOfChats();
    }

    calcNumberOfChats(){
        var totalMessages = 0;
        for(var messageDay of this.props.chat.messages){
            totalMessages += messageDay.length;
        }
        this.setState({
            totalMessages: totalMessages
        })
        //console.log(totalMessages);
    }

    render(){
        // console.log("this.state.currentUid: ", this.props.chat);
        return(
            <div className={"ic-container " + (this.props.chat.filler ? "no-shadow" : "")}>
                <SingleMsgArea 
                    {...this.props}
                    chats={[this.props.chat]}
                    chatFormRef={this.chatFormRef} 
                    nickname={this.props.nickname}
                    f_sendMsg={global.f_sendMsg} 
                    uid={this.props.uid}
                    currentUid={this.props.chat.currentUid}
                    scrollToBottom={global.scrollToBottom}
                    nextUid={this.props.nextUid}
                    readAllMessages={global.readAllMessages}
                    myUnreadChatIds={[]}
                    totalMessages={this.state.totalMessages}
                />
            </div>
        );
    }
}
