import React from 'react';
import './dotLoader.css';

export default class DotLoader extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    componentDidMount(){
        // console.log('mounted DOTLOADER');
    }

    render(){
        return (
            <div className="dotLoader">
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
}
