import React from 'react';
import './favouritesHeader.css';

export default class FavouritesHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            favourites: props.favourites
        }
    }

    componentDidUpdate(){
        if(this.state.favourites !== this.props.favourites){
            this.calcDisplayData(this.props.favourites);
        }
    }

    calcDisplayData = (favourites) => {
        var totalRequests = 0, accepted = 0, rejected = 0, acceptanceRate = 0;

        totalRequests = favourites.length;
        accepted = favourites.filter((f) => {return f.status === "accepted"}).length;
        rejected = favourites.filter((f) => { return f.status === "rejected"}).length;
        acceptanceRate = (accepted / totalRequests) * 100;

        this.setState({
            favourites: favourites,
            totalRequests: totalRequests,
            accepted: accepted,
            rejected: rejected,
            acceptanceRate: (isNaN(acceptanceRate) ? 0 : acceptanceRate) + "%" 
        })
    }

    render(){
        return(
            <div id="favouritesHeader">
                <h2> </h2>
                {
                    this.state.totalRequests > 0 &&
                    <div>
                        <div className="g-flex g-space-between fh-requests">
                            <div><label>{this.state.totalRequests > 0 ? this.state.totalRequests : "No"}</label></div>
                            <div><label> Request{this.state.totalRequests > 1 ? "s" : ""}</label></div>
                        </div>
                        <div className="g-flex g-space-between fh-accepted">
                            <div><label>{this.state.accepted}</label></div>
                            <div><label>Accepted</label></div>
                        </div>
                        <div className="g-flex g-space-between fh-rejected">
                            <div><label className="g-soft-text">{this.state.rejected}</label></div>
                            <div><label className="g-soft-text"> Rejected</label></div>
                        </div>
                    </div>
                }
                {
                    !isNaN(this.state.acceptanceRate) && <div className="g-soft-text g-flex g-space-between fh-acceptanceRate">
                        <div><label>{this.state.acceptanceRate}</label></div>
                        <div><label>Acceptance rate</label></div>
                    </div>
                }
            </div>
        );
    }
}