// https://levelup.gitconnected.com/using-google-analytics-with-react-3d98d709399b

import ReactGA from 'react-ga';
import config from '../config.js';

const TRACKING_ID = config.ANALYTICS_TEST ? 'UA-154891940-2' : 'UA-154891940-1';
if(config.ANALYTICS_ON){
    // console.log('Starting up analytics');
    ReactGA.initialize(TRACKING_ID, {
        debug: config.ANALYTICS_DEBUG,
        titleCase: false,
        gaOptions: {
            userId: 123
        }
    })
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.pageview(window.location.pathname);
}

//console.log('anlaytics');

//TODO, use this
/*
function setAnalyticsUserId(userId){
    ReactGA.set({
        userId: userId
    })
}
*/