const questions = {
	relationship: {
		1: "Do you have your partner's back no matter what?",
		2: "What do you consider cheating?",
		3: "Which is worse, emotional or physical cheating?",
		4: "How much honesty do you feel is actually needed in a relationship",
		5: "How do you think you've changed over the past few years",
		6: "What do you want out of a relationship",
		7: "How do you deal with difficult situations?",
		8: "Why didn't your last relationship work out?",
		9: "How did you deal with your feelings and emotions growing up?",
		10: "What do you personally think of your friend's relationships?",
		11: "Do you believe that relationships make each other better people?",
		12: "What do you value most in a relationship?"
	},
	set1: {
		13: "If you could invite anyone in the world to dinner, who would it be?",
		14: "Would you like to be famous? In what way?",
		15: "Before making a telephone call, do you ever rehearse what you are going to say? Why?",
		16: "What would constitute a 'perfect' day for you?",
		17: "When did you last sing to yourself? To someone else?",
		18: "If you were able to live to the age of 90 and retain either the mind or body of a 30 year old for the last 60 years of your life, which would you want?",
		19: "Do you have a secret hunch about how you will die?",
		20: "Name three things you and your partner appear to have in common",
		21: "For what in your life do you feel most grateful",
		22: "If you could change anything about the way you were raised, what would it be?",
		23: "Tell your life story in 280 characters",
		24: "If you could wake up tomorrow having gained any one quality or ability, what would it be?"
	},
	set2: {
		25: "If a crystal ball could tell you the truth about yourself, your life, the future or anything else, would you want to know?",
		26: "Is there something that you've dreamed of doing for a long time? Why haven't you done it?",
		27: "What is the greatest accomplishment of your life?",
		28: "What do you value most in a friendship?",
		29: "What is your most treasured memory?",
		30: "What is your most terrible memory?",
		31: "If you knew that in one year you would die suddenly, would you change anything about the way you are living now? Why?",
		32: "What does friendship mean to you?",
		33: "What roles do love and affection play in your life?",
		34: "What is something positive that you like in people around you?",
		35: "How close and warm is your family? Do you feel your childhood was happier than most other people's?",
		36: "How do you feel about your relationship with your mother?"
	},
	set3: {
		37: "Write down three things that are true right now",
		38: "Complete this sentence: I wish I had someone with whom I could share ...",
		39: "If you were to become a close friend with someone, what would be something important for them to know about you",
		40: "Remember someone you really liked, what did you like about them? What specific details can you not forget",
		41: "What was an embarrassing moment in your life?",
		42: "When did you last cry in front of another person? By yourself?",
		43: "What is something you really like in other people",
		44: "What, if anything, is too serious to be joked about",
		45: "If you were to die this evening with no opportunity to communicate with anyone, what would you most regret  not having told someone? Why haven't you told them yet?",
		46: "Your house, and everything you own catches fire. After saving your loved ones and pets, you have time to safely make a final dash to save any one item. What would it be, and why?",
		47: "Of all the people in your family, whose death would you find most disturbing? Why?",
		48: "Share a personal problem you have been having a lot"
	},
	personality: [
		{ question: "I try not to draw attention to myself", type: 'E+'},
		{ question: "I feel uncomfortable when I disagree with someone", type: 'A-'},
		{ question: "I lose my belongings",  type: 'C-'},
		{ question: "I have a vivid imagination",  type: 'O+'},
		{ question: "I would enjoy attending a large party in my honor", type: 'E+'},
		{ question: "I dislike being in competition with others", type: 'A+'},
		{ question: "I prefer to follow a schedule", type: 'C+'},
		{ question: "I question the wisdom of my elders", type: 'O+A-'},
		{ question: "I enjoy being the center of attention", type: 'E+'},
		{ question: "I try to avoid conflict", type: 'A+'},
		{ question: "I make sure my work is finished on time", type: 'C+'},
		{ question: "It is important to me to follow traditions", type: 'C+O-'},
		{ question: "I make an effort to be popular", type: 'E+'},
		{ question: "I sympathize with the homeless", type: 'A+'},
		{ question: "I have trouble controlling my impulses", type: 'C-'},
		{ question: "I think about why people do the things they do", type: 'A+'},
		{ question: "Being around lots of people energizes me", type: 'E+'},
		{ question: "I am helpful to the people around me", type: 'A+'},
		{ question: "I am ambitious", type: 'C+'},
		{ question: "I like to do things as they were done in the past", type: 'C+'},
		{ question: "I avoid being alone", type: 'E+'},
		{ question: "I forgive easily", type: 'A+'},
		{ question: "I like trying out new hobbies", type: 'O+'},
		{ question: "I like to stay active", type: 'N-E+'},
		{ question: "It is best to be totally objective when making a decision", type: 'A-'},
		{ question: "I keep my belongings in their proper place", type: 'C+'},
		{ question: "I have a rich fantasy life", type: 'O+'},
		{ question: "I have little to say", type: 'O-'},
		{ question: "I enjoy being there for people when they are feeling sad", type: 'A+'},
		{ question: "I would rather go with the flow than have a set schedule", type: 'C-'},
		{ question: "I enjoy philosophical discussions", type: 'O+'},
		{ question: "I let other people know what I think", type: 'A-'},
		{ question: "I avoid arguing, even when I disagree", type: 'A+'},
		{ question: "I am very attentive to deadlines", type: 'C+'},
		{ question: "I would like to play a/another muscial instrument", type: 'O+'},
		{ question: "I have a lot of fun", type: 'E+'},
		{ question: "It is easy for me to talk to strangers", type: 'E+'},
		{ question: "I am concerned for the welfare of elderly people", type: 'A+'},
		{ question: "I am determined to achieve success in life", type: 'C+'},
		{ question: "I believe in the importance of art", type: 'O+'},
		{ question: "I like to spend my free time alone", type: 'E-'},
		{ question: "I believe people should be punished for their mistakes", type: 'A-'},
		{ question: "I enjoy having a daily routine", type: 'C+'},
		{ question: "I spend time seeking out new experiences", type: 'O+'},
		{ question: "I am on the go", type: 'E+'},
		{ question: "I easily get over stressful situations", type: 'N-'},
		{ question: "I wish other people would be more logical", type: 'A-'},
		{ question: "I like to tidy up", type: 'C+'},
		{ question: "I enjoy imagining the future", type: 'O+'},
		{ question: "I find it difficult to express my opinions", type: 'A+'},
		{ question: "I like to help others with their personal problems", type: 'A+'},
		{ question: "I finish assignments before they are due", type: 'C+'},
		{ question: "I like thinking about the mysteries of the universe", type: 'O+'},
		{ question: "I have a lot of energy", type: 'E+'},
		{ question: "I can't stand when someone doesn't like me", type: 'A+'},
		{ question: "I follow my impulses", type: 'C-N+'},
		{ question: "I enjoy trying to understand compicated ideas", type: 'O+'},
		{ question: "I feel very happy", type: 'E+N-'},
		{ question: "I spend time trying to understand myself", type: 'E-'},
		{ question: "I need support from other people", type: 'A+'},
		{ question: "I get a thrill out of meeting new people", type: 'E+'},
		{ question: "I am not bothered by disorder", type: 'C-'},
		{ question: "I enjoy going to museums", type: 'O+'},
		{ question: "I avoid noisy crowds", type: 'E-'},
		{ question: "I forgive others' mistakes, even when they harm me personally", type: 'A+'},
		{ question: "I make plans and stick to them", type: 'C+N-'},
		{ question: "I experience strong irresistible cravings", type: 'N+'},
		{ question: "I do not overindulge", type: 'N-'},
		{ question: "I like to do things differently, even if I am not sure if it will work", type: 'O+'},
		{ question: "I do not like to sit still for long", type: 'E+'},
		{ question: "I make important decisions based on my gut feeings", type: 'C-'},
		{ question: "I start tasks in advance so that I have plenty of time to finish them", type: 'C+'},
		{ question: "I hold back my opinions", type: 'A+'},
		{ question: "I am sensitive to the feelings of others", type: 'A+'},
		{ question: "I am easily distracted", type: 'C-'},
		{ question: "I easily feel embarrassed", type: 'N+'},
		{ question: "I enjoy learning about scientific theories", type: 'O+'},
		{ question: "I love life", type: 'E+N-'},
		{ question: "I feel sad a lot",  type: 'N+'},
		{ question: "I get anxious more than my friends", type: 'N+'},
		{ question: "Everyone is watching and judging me", type: 'N+'},
		{ question: "I feel confused in new places", type: 'N+'},
		{ question: "I work hard to please others", type: 'A+'},
		{ question: "I have a zest for life", type: 'E+'},
		{ question: "I give to people who are less fortunate than I", type: 'A+'},
		{ question: "I like to have a detailed plan before starting a task", type: 'C+'},
		{ question: "I wonder why I am the way I am", type: 'E-'},
		{ question: "I love to make new friends", type: 'E+'},
		{ question: "It’s important to me that other people like me", type: 'A+'},
		{ question: "I have trouble sticking to a routine", type: 'C-'},
		{ question: "I appreciate the beauty of nature", type: 'O+'},
		{ question: "I prefer quiet surroundings", type: 'E-'},
		{ question: "I find it difficult to get down to work", type: 'C-'},
		{ question: "Emotional people make me uncomfortable", type: 'A-'},
		{ question: "People tell me I am too quiet", type: 'E-'},
		{ question: "I am concerned about others", type: 'A+'},
		{ question: "I like to finish all my chores before I do something fun", type: 'C+'},
		{ question: "I enjoy chatting with new acquaintances", type: 'E+'},
		{ question: "It is important to me to make decisions without being swayed by emotions", type: 'A-'},
		{ question: "I carry out my plans", type: 'C+'},
		{ question: "It is important to me to understand the bigger picture", type: 'O+'},
		{ question: "It is important to me to be of service to others", type: 'A+'},
		{ question: "I enjoy examining myself and my life", type: 'E-'},
		{ question: "I find it challenging to make new friends", type: 'E-'},
		{ question: "I like poetry", type: 'O+'},
		{ question: "I am a private person", type: 'E-'},
		{ question: "I experience panic and anxiety often",  type: 'N+'},
		{ question: "I question why things are the way they are", type: 'O+'},
		{ question: "I let others know that I care about their feelings", type: 'A+'},
		{ question: "I think about the needs of others", type: 'A+'},
		{ question: "I like to be needed by other people", type: 'A+'},
		{ question: "I would prefer to come up with an original solution to a problem", type: 'O+'},
		{ question: "I am full of new ideas", type: 'O+'}
	],
	personalityComparison: [
		{1: "I like ideas that are easy to understand", 2: "I like ideas that are complex", type:"O"},
		{1: "I keep my thoughts to myself", 2: "I speak up", type:"A-"},
		{1: "I spend my time pursuing my goals", 2: "I spend my time enjoying life",type:"C-"},
		{1: "I get angry often", 2: "I am emotionally stable",type:"N-"},
		{1: "I put my needs first", 2: "I put others’ needs ahead of my own",type:"A"},
		{1: "I am calm in difficult situations", 2: "I feel anxious and helpless when things are stressful",type:"N"},
		{1: "I am often disorganized", 2: "I keep myself organized",type:"C"},
		{1: "I enjoy activities that are familiar", 2: "I enjoy experiencing new things",type:"O"},
		{1: "I can withstand temptations", 2: "I give in to temptations",type:"N"},
		{1: "I like to try to innovate", 2: "I like to use trusted methods",type:"O-"},
		{1: "I prefer to follow a clear procedure", 2: "I prefer to improvise",type:"C-"},
		{1: "I work first, play later", 2: "I play first, work later",type:"C-"},
		{1: "I seek attention from others", 2: "I avoid attention from others",type:"E"},
		{1: "I look for ways to help others", 2: "I look for ways to achieve my own goals",type:"A-"},
		{1: "I make sure everyone is taken care of", 2: "I look out for myself",type:"A"},
		{1: "I value my social status", 2: "I value my privacy",type:"E-"},
		{1: "I feel sad a lot", 2: "I don't often feel sadness",type: "N-"},
		{1: "I am more interested in what is possible", 2: "I am more interested in what is real",type:"O"},
		{1: "I start conversations", 2: "I let others start conversations",type:"E-"},
		{1: "I prefer to work in an active, bustling office", 2: "I prefer to work in a calm, quiet office",type:"E-"}
	],
	personalityComparisonDiscarded: [
		{1: "I follow a plan", 2: "I act on impulse",type:"C-"},
		{1: "I make decisions with my head", 2: "I make decisions with my heart",type:"C-"},
		{1: "I do things step by step", 2: "I jump in and figure things out as I go",type:"C-"},
		{1: "I am always prepared", 2: "I am often unprepared",type:"C-"},
		{1: "I like to cooperate", 2: "I like to compete",type:"A-"},
		{1: "I feel the pain of other people", 2: "I am not easily affected by sad stories",type:"A-"},
		{1: "I focus on real life", 2: "I use my imagination",type:"O"},
		{1: "I trust traditional values", 2: "I question traditional values",type:"O"},
	],
	personalityComparison2: [
		{1: "I keep my cool", 2: "I show my enthusiasm"},
		{1: "I trust traditional values", 2: "I question traditional values"},
		{1: "I’m good at negotiating personal issues", 2: "I’m good at navigating logical systems"},
		{1: "I prefer working toward long-term goals", 2: "I prefer to work on tasks that must be done immediately"},
		{1: "I prefer a quiet, peaceful office", 2: "I prefer a busy, noisy office"},
		{1: "I focus on real life", 2: "I use my imagination"},
		{1: "I like solving logical problems", 2: "I like solving relationship problems"},
		{1: "I like to complete tasks well in advance of deadlines", 2: "I enjoy working under pressure"},
		{1: "I speak my mind freely", 2: "I consider things thoroughly"},
		{1: "I like to practice familiar skills", 2: "I like to constantly learn new things"},
		{1: "I like building relationships", 2: "I like demonstrating my competence"},
		{1: "I persist in my goals", 2: "I adapt to my environment"},
		{1: "I do my best thinking alone", 2: "I do my best thinking in a group"},
		{1: "I notice small details", 2: "I see the big picture"},
		{1: "I like to be friends with my coworkers", 2: "I prefer to be businesslike in my work relationships"},
		{1: "I thrive in chaos", 2: "I thrive within a clear structure"},
		{1: "I like to multitask", 2: "I like to focus on one thing at a time"},
		{1: "I trust the facts", 2: "I trust my instinct"},
		{1: "I want people to like me", 2: "I want people to respect me"},
		{1: "I enjoy having a daily routine", 2: "I like every day to be different"},
		{1: "I prefer to have a few meaningful friendships", 2: "I prefer to have lots of casual friends"},
		{1: "I look to the past for evidence of what works", 2: "I test new ideas to try and find a better way"},
		{1: "I am known to be tough, but fair", 2: "I am known to be a sympathetic ear"},
		{1: "I change course quickly when things aren’t working", 2: "I persist with my plans, even when I encounter obstacles"}
	],
	personalityCareer: [
		"Repair a dishwasher",
		"Analyze the structure of molecules",
		"Design a magazine cover",
		"Teach social skills to disabled children",
		"Coordinate a business conference",
		"Track monthly expenses for a company",
		"Use precision machines to build custom metal parts",
		"Do scientific experiments",
		"Paint a portrait",
		"Teach adults to read",
		"Plan a marketing strategy for a new company",
		"Review financial records for accuracy",
		"Install a hardwood floor",
		"Research the properties of nuclear energy",
		"Design a greeting card",
		"Help a disabled person with their daily routine",
		"Negotiate business partnerships",
		"Keep payroll records",
		"Repair an air conditioning system",
		"Research a new medicine",
		"Illustrate a children’s book",
		"Tutor a child with a learning disability",
		"Persuade others to my point of view",
		"Use spreadsheets to organize financial data",
		"Install an alarm system in a building",
		"Look at cells through a microscope",
		"Write a poem",
		"Counsel a person recovering from drug addiction",
		"Hire and fire employees",
		"Check tax returns for errors",
		"Install kitchen cabinets",
		"Work in a chemistry lab",
		"Write a script for a television show",
		"Counsel a person with depression",
		"Close an important business deal",
		"Calculate the cost of an insurance claim",
		"Build a stone wall",
		"Analyze soil samples for pollution",
		"Design a billboard advertisement",
		"Plan educational games for preschool children",
		"Lead a team",
		"Calculate the cost of a construction project",
		"Operate a bulldozer",
		"Study a fault line to predict earthquakes",
		"Edit a movie",
		"Plan activities for elderly people",
		"Start a new business",
		"Help customers fill out loan applications",
		"Take apart a car engine",
		"Do laboratory tests to diagnose diseases",
		"Compose a song",
		"Help a needy family find appropriate housing",
		"Give a speech in front of many people",
		"Develop a budget for a city government"
	]
}

export default questions;
		/*
Congratulations, you've answered all the questions! Now for the hard part.

In order to solidify your love, you have to look into your partner's eyes for four minutes. In silence. It's hard, and you'll squirm, but you'll learn an incredible amount. Good luck…

		*/