import React from 'react';
import { Link } from "react-router-dom";
import './headerPopup.css';

export default class HeaderPopup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: props.name,
            f_signOut: props.f_signOut,
            f_close: props.f_close,
            f_updateCurrentUid: props.f_updateCurrentUid
        }
    }

    render(){
        //console.log(this.state.name);
        return(
            <div className="popup">
                <div className="popup-container">
                    <div className="popup-username"><h3>{this.state.name}</h3></div>
                    <div className="popup-view-profile">
                        <Link to={"/user/" + this.props.authUid} 
                            className="g-mg-l-5" 
                            style={{whiteSpace:'nowrap',color:"black"}}
                            onClick={()=>{
                                this.state.f_close()
                                this.state.f_updateCurrentUid(this.props.authUid)
                            }}>View Profile</Link>
                    </div>
                    <div className="popup-spacer popup-spacer-top">
                        <div className="popup-inner">
                            <span id="ANALYTICS_signOutPressed" onClick={()=>this.props.f_signOut()}  style={{marginBottom:'0px',color:"black"}}>Sign out</span>
                        </div>
                    </div>
                </div>    
            </div>
        );
    }
}
