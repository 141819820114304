//const DEFAULT_LANGUAGE = window.navigator.userLanguage || window.navigator.language;
const DEFAULT_LANGUAGE = require('./config.js').LANGUAGE;

module.exports = {
    get: (str) => {
        return languages[DEFAULT_LANGUAGE][str];
    }
}

const languages = {
    English: {
        PD_TITLE: "Personal Details",
        PD_FIRST_NAME: "First Name",
        PD_LAST_NAME: "Last Name",
        PD_EMAIL: "Email",
        PD_GENDER: "Gender",
        PD_AVAILABLE: "Are you currently open to relationship requests?",
        PD_FIELDS_ARE_VISIBLE_WARNING: "These fields will be publicly visible",
        PD_FIELDS_ARE_NOT_VISIBLE: "These details will NOT be shared with anyone",
        PD_EMAILME: "Receive notifications by email",

        A_TITLE: "Aspirations",
        S_TITLE: "Top Qualities",
        S_EDIT_1: "Choose ",
        S_EDIT_2: " five ",
        S_EDIT_3: " that best describe yourself",
        RH_TITLE: "Relationship History",
        FAQ_TITLE: "FAQ",
        MN_TITLE: "Must Not",
        CQ_TITLE: "Quotes",
        R_TITLE: "References",
        CHAT_QUOTES_EMPTY: "While chatting with a friend, press 'Send to profile' on a message and it will appear here",
        EFIP_TITLE: "Make your profile stand out",
        EFIP_TITLE2: "You're almost there",
        EPIF_DONE_MSG: "Your profile looks great",
        EPIF_DONE_MSG_2: "Stay connected and updated",
        EPIF_DONE_BTN: "Start connecting",
        HFP_TITLE: "Currently Available Positions", //"Looking For" //"Currently Available Positions",
        HFP_EDIT_LABEL: "I'm looking for a",
        HFP_EDIT_GENDER_DEFAULT: "Select a gender",
        HFP_EDIT_GENDER_MAN: "Man",
        HFP_EDIT_GENDER_WOMAN: "Woman",
        HFP_EDIT_GENDER_PERSON: "Person",
        HEADER_RANDOM_PERSON: "SURPRISE ME",//"RANDOM PERSON",
        GSP_1: "Get started with your profile",
        GSP_2: "View your Profile",
        REF_PENDING: "Pending request sent to",
        REF_ONLY_VISIBLE_2_U: "(only visible to you, email may have gone to their spam)",
        FAQ_REPLY_BTN: "Reply",
        FAQ_ASK_TITLE: "Ask a question",
        FAQ_ANSWER_TITLE: "Answer question",
        SUP_TITLE: "Join our community",
        FAQ_ASK_QUESTION: "Ask question",
        SUP_1: "Have great conversations", //with this person
        //SUP_2: "Answer questions no one has asked you before",
        SUP_2: "Build relationships",
        SUP_3: "Meet interesting people",
        SUP_4: "Have someone to watch fireworks with",
        SUP_BTN: "Sign in",
        FOOTER_CONTACT: "We welcome your feedback & suggestions",
        FOOTER_ATTRIBUTION: "Using ",//"Graphics from ",
        SEARCH_BAR_PLACEHOLDER: "Try 'Love', 'Engineer'",
        //SP_TITLE: "Suggested for you",
        SP_TITLE: "More People",//"Suggested People",
        SF_lf_gender: "Gender they want",
        SF_lf_age: "Age they want",
        SF_Requests: "Requests",
        SF_GENDER: "Gender",
        SF_AGE: "Age",
        RC_TITLE: "Spaces",
        HEADER_MESSAGES: "Messages",

        LF_TITLE: 'Looking For',

        MD_REFER2FRIEND: "Refer to a Friend",
        MD_MESSAGE: "Message",
        MD_CONNECTED: "Connected",
        MD_AWAITING_RESPONSE: "Awaiting response",
        MD_WAITING4RESPONSE: "Waiting for response",
        MD_FAVOURITE: "Favorite",

        LA_OH_MY: "Oh My ",
        LA_OH_MY2: "We've taken note",
        LA_OH_MY3: "Saved for later",
        LA_DEFAULT1: "Like this person and see them again",
        LA_DEFAULT2: "Stay connected with awesome people",

        LAR_TITLE: "Likes",

        F_NEW_REQUEST: "You were liked by",
        F_IGNORE: "Ignore",
        F_ACCEPT: "Accept",
        F_UR_CONNECTIONS: "Your Connections",
        F_MORALE_LOWER: "Like profiles and stay connected",

        C_NO_MESSAGES: "You don't have any messages yet, ",
        C_VISIT_PROFILE: "Visit a profile",
        C_START_MESSAGING: "to start messaging",
        C_SEND: "Send",
        C_SEND2PROFILE: "Show on profile",

        NN_TITLE: 'Nickname',
        NN_DESCRIPTION: "This is only visible for you",
        // NN_DEFAULT: "Create a nickname for this person",

        IP_NEW_CONVERSATIONS: "Start new conversations",
        IP_CONTINUE_CHAT: "Your Interactions",//"Continue chatting",

        // Rooms
        RC_TOPPOPUP: 'Hang out in virtual spaces',//'Go on virtual dates.',
        RC_TOPPOPUP_CONFIRM: "Get Started",
        RC_TOPPOPUP_DENY: "No, I don't like to relax",//"No, I want to be single",
        RC_CREATE_MODAL_TITLE:"Create a space",
        RC_CREATE_MODAL_F1: "Name",
        RC_CREATE_MODAL_F2: "Max Participants",
        RC_CREATE_MODAL_F3: "Duration (Mins)",
        RC_CREATE_MODAL_SUBMIT: "Create",
        RC_CREATE_MODAL_ADDITIONAL: "Creating room",


    },
    Japanese: {

    },
    Chinese: {

    }
}

