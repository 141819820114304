import React from 'react';
import msg from '../Assets/person-white.svg';
import chat_white from '../Assets/chat_white.svg';
import arrow_down from '../Assets/arrow_down_white.svg';
import './chat.css';
import CircularLoader from '../Loader/circularLoader.jsx';
//import { thisExpression } from '@babel/types';
import config from '../config.js';
import l from '../language.js';
import db from '../db.js';
import helpers from '../helpers.js';
import axios from 'axios';
import {Link} from 'react-router-dom';

export default class Chat extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: props.uid,
            chats: [],
            showing: false,
            currentPageUser: props.currentPageUser,
            currentUid: props.currentUid,
            listeningChatIds: [],
            // Dictionary of all of the indexes of the unread chats
            // We assume that it is always the other targetUid
            unreadIndexes: {},
            // Number of new messages sent, so we can 
            newMessagesIndexes: {},
            // The chatIds I have not read
            myUnreadChatIds: [],
            f_updateInteractionsPage: props.f_updateInteractionsPage,
            b_display: props.b_display,
            // unlisten1: ()=>{},
            // unlisten2: ()=>{}
        }
        this.msgAreaContentsRef = React.createRef();
        this.chatFormRef = React.createRef();
    }

    componentDidMount(){
        if(!this.state.uid) return;

        // For interactionContainer to use these methods
        global.f_sendMsg = this.f_sendMsg; 
        global.readAllMessages = this.readAllMessages;
        global.scrollToBottom = this.scrollToBottom;
        
        db.getAllChatsForUser(this.state.uid)
            .then(data => {
                // Check to add in current user as new chat
                if(this.props.currentPageUser){
                    // Check not for self
                    if(this.props.currentPageUser.participants[0] !== this.props.currentPageUser.participants[1]){
                        // Check we don't already have a chat with this user
                        if(!this.alreadyHasChat(this.props.currentPageUser.participants)){
                            data.unshift(this.props.currentPageUser);
                        }
                    }
                }
                this.setState({
                    chats: data
                }, () => {
                    //console.log('got chats data: ', data);
                    const chatIds = [];
                    for(var c of data){
                        if(!c.id) continue;
                        chatIds.push(c.id);
                    }
                    this.listenToChats();
                    this.listenToChatReadIndexes(chatIds);
                    this.listenToMyChatReadIndexes(chatIds);
                })
            }).catch(err => console.error("Error retrieving chats for user: ", err));
        
        //window.addEventListener("beforeunload", ()=> this.sendWindowReloaded());
    }

    componentWillUnmount(){
        //this.sendChatMsgReceivedEmail();
        //window.removeEventListener("beforeunload");
    }

    sendChatMsgReceivedEmail = async (chatId, targetUid, value) => {

        // Send the total number of unread messages if it's more than 0
        //console.log('chat unmounting: ', this.state.newMessagesIndexes);
        if(Object.keys(this.state.newMessagesIndexes).length){
            const uid = localStorage.getItem('uid') || global.authUid || this.state.uid;

            const destinationUid = targetUid;
            if(!await helpers.checkEmailStatusFromUID(destinationUid)) return;
            axios({
                method: 'POST',
                url: `${config.SERVER_ADDRESS}/chatMsgReceived`,
                data: {
                    uid: uid,
                    destinationUid: destinationUid,
                    // Sending the unread indexes instead for current ones.
                    chatId: chatId,
                    value: value
                    ////unreadIndexes: this.state.newMessagesIndexes
                    //unreadIndexes: this.state.unreadIndexes
                }
            }).then(()=>{})
            .catch(err => console.error("Error sendingChatMsgReceived: ", err));
        }
    }


    listenToChatReadIndexes = (chatIds) => {
        const cb = (chatId, newIndex) => {
            const unreadIndexes = this.state.unreadIndexes;
            unreadIndexes[chatId] = newIndex;
            //console.log(unreadIndexes);
            this.setState({
                unreadIndexes: unreadIndexes
            })
            this.props.f_updateInteractionsPage({unreadIndexes: unreadIndexes});
        }
        db.listenAllChatMessagesIndexForUser(chatIds, this.state.uid, cb);
    }

    // Which ones I currently have unread (these are shown with orange notification button)
    listenToMyChatReadIndexes = (chatIds) => {
        const cb = (chatId) => {
            //console.log('myChatIds MODIFIED: ', chatId);
            const myUnreadChatIds = this.state.myUnreadChatIds;
            myUnreadChatIds.push(chatId);
            this.setState({
                myUnreadChatIds: myUnreadChatIds
            })
            this.props.f_updateInteractionsPage({myUnreadChatIds: myUnreadChatIds});
        }
        db.listenMyUnreadChatForUser(chatIds, this.state.uid, cb);
    }

    listenToChats = () => {
        // const chatIds = [];
        // for(var c of data){
        //     //console.log(c);
        //     if(!c.id) continue;
        //     chatIds.push(c.id);
        // }
        // Listen for chat updates
        const cbNewChatDay = async (chatId, msgId, data) => {
            // Check what the change is
            //console.log("new chat day: ", chatId, ': ', data, this.state.chats);
            var obj = this.getChatIndexById(chatId);
            if(obj === undefined){
                // Chat does not exist, and is from a new user.
                // console.log("Chat does not exist, and is from a new user: ", obj);
                obj = {};
                obj.chatObj = await db.getChatById(this.state.uid, chatId);
                // Remove this as it is added in by the listener later
                //TODO? obj.chatObj.messages = [];
                obj.chatObj.id = chatId;
                obj.index = this.state.chats.length;
                // console.log('new chat object: ', chatId, obj);
            }
            // console.log("obj.chatObj.length: ", obj.chatObj.messages.length);

            var newMessages = obj.chatObj.messages;
            // Check where to insert this new message in by comparing timestamp id and inserting into first position where it is smaller
            
            //console.log("msgId: ", msgId)
            // console.log(newMessages);
            // If this is the first message
            if(!newMessages.length){
                newMessages = [data.messages];
            }
            // Chats exist, so insert into the right place
            // The chats array becomes a sorted array as we insert in place
            // If we reach the end and there is no chat, then add as the latest message
            else if (newMessages.length){
                // console.log('length exists, so adding');
                for(var i=0;i<newMessages.length;i++){
                    // console.log(newMessages[i]);
                    if(newMessages[i]){
                        if(Number(msgId) < newMessages[i]){
                            // console.log('should insert at index: ', i);
                            newMessages.splice(i, 0, data.messages);
                            break;
                        }
                    }
                }
                // If still here then add to end
                newMessages.push(data.messages);
            }
            // console.log("newMessages: ", newMessages);
            

            const newObj = this.state.chats;
            newObj[obj.index] = obj.chatObj;
            newObj[obj.index].messages = newMessages;
            this.setState({
                chats: newObj
            }, ()=>{
                this.scrollToBottom();
            });
            this.props.f_updateInteractionsPage({chats: newObj});
            // console.log('newObj: ', newObj[0].messages.length);
        }
        const cbNewMessage = (chatId, msgId, data) => {
            // console.log("chatId: ", chatId);
            // console.log("msgId: ", msgId);

            // We just assume a new message was added
            const obj = this.getChatIndexById(chatId);
            //console.log(obj);
            if(!obj.index) return;
            const newChats = this.state.chats;
            newChats[obj.index] = obj.chatObj;
            newChats[obj.index].messages = [data.messages];
            // console.log('newChats: ', newChats[obj.index].messages.length);
            this.setState({
                chats: newChats
            }, ()=>{
                this.scrollToBottom();
                this.readAllMessages(obj.chatObj.id);
                this.props.f_updateInteractionsPage({chats: newChats});
            });
        }
        const cbNewChatCreated = (data, type) => {
            // If added, then it's the first so we trigger listeners from scratch
            // console.log("new chatIds: ", data, type);
            if(!data) return;
            if(type === "added"){
                db.listenChatUpdates(cbNewChatDay, cbNewMessage, cbNewChatCreated, data);
            } else if(type === "modified"){
                // Filter out exising chatsIds
                const alreadyListening = this.state.listeningChatIds;
                const filteredChatIds = data.filter(
                    function(e){
                        return this.indexOf(e) < 0;
                    }, 
                    alreadyListening
                );
                //console.log('filtered: ', filteredChatIds);
                db.listenChatUpdates(cbNewChatDay, cbNewMessage, cbNewChatCreated, filteredChatIds);
            }
            this.setState({
                listeningChatIds: data
            }, ()=>{
                this.scrollToBottom();
            })
            this.props.f_updateInteractionsPage({listeningChatIds: data});
        }

        //db.listenChatUpdates(cbNewChatDay, cbNewMessage, cbNewChatCreated, chatIds);
        db.listenUserChatIdChanges(this.state.uid, cbNewChatCreated);
    }

    getChatIndexById = (chatId) => {
        // console.log("newData: ", newData);
        for(var i=0; i<this.state.chats.length; i++){
        // for(var i=0; i<newData.length; i++){
            // console.log("this.state.chats[i]: ", this.state.chats[i].messages.length)
            if(this.state.chats[i].id === chatId)
                return {chatObj: this.state.chats[i], index: i };
        }
    }

    componentDidUpdate(){
        //console.log("this.state.uid: ", this.state.uid);
        //console.log("this.props.uid: ", this.props.uid);
        if(this.state.uid !== this.props.uid){
            this.setState({ uid: this.props.uid }, () => {
                //console.log("uid: ", this.state.uid);
                if(this.state.uid){
                    db.getAllChatsForUser(this.state.uid)
                        .then(data => {
                            // console.log('Got chats data: ', data);
                            this.setState({
                                chats: data
                            }, ()=>{
                                this.props.f_updateInteractionsPage({chats: data});
                            })
                        }).catch(err => console.error("Error retrieving chats for user: ", err));
                }
            });
        }
        // Creating a new User, and removing the existing one
        if(this.state.currentPageUser !== this.props.currentPageUser 
        || this.state.currentUid !== this.props.currentUid){
            
            //console.log("this.state.currentUid: ", this.state.currentUid);
            //console.log("this.props.currentPageUser: ", this.props.currentPageUser);
            if(this.props.currentPageUser.participants[0] !== this.props.currentPageUser.participants[1]){
                if(!this.alreadyHasChat(this.props.currentPageUser.participants)){
                    const c = this.state.chats;
                    //console.log('this.state: ', this.state.chats);
                    //console.log('this.props: ', this.props.chats);
                    //console.log("this.props.currentPageUser: ", this.props.currentPageUser);
                    //if(Object.keys(this.props.currentPageUser).length){
                    if(c[0] && c[0].personalDetails.thisPage){
                        //console.log("Removing first object from chats array");
                        c.shift();
                    }
                    this.props.currentPageUser.personalDetails.thisPage = true;
                    //console.log("this.props.currentPageUser: ", this.props.currentPageUser)
                    c.unshift(this.props.currentPageUser);
                    this.setState({
                        currentUid: this.props.currentUid,
                        currentPageUser: this.props.currentPageUser,
                        chats: c
                    }, ()=>{
                        this.props.f_updateInteractionsPage({chats: c});
                        // console.log("THIS.STATE.CHATS: ", this.state.chats[0].messages.length)
                    });
                }
            }
        }
    }

    alreadyHasChat = (participants) => {
        // Make sure it is not the current user
        const otherParticipant = participants.filter(p => {return p !== this.state.uid})[0];
        for(var chat of this.state.chats){
            const existingParticipant = chat.participants.filter(p => { return p !== this.state.uid})[0];
            if(existingParticipant === otherParticipant){
                return true;
            }
        }
        return false;
    }

    toggleContent = (b) => {
        //console.log("b: ", b.target); // by adding another parameter in the click(param), it's possible to retrieve the event that triggered this
        if(!b){
            //this.msgAreaContentsRef.current.style.display = "block";
            document.getElementsByClassName("msgArea-contents")[0].classList.add("showing");
            document.getElementsByClassName("msgArea-header")[0].classList.add("showing");
            document.getElementsByClassName("msgArea-container")[0].classList.add("showing");
            // document.getElementsByClassName("msgArea-container")[0].classList.remove("showing");
        } else {
            //this.msgAreaContentsRef.current.style.display = "none";
            document.getElementsByClassName("msgArea-contents")[0].classList.remove("showing");
            document.getElementsByClassName("msgArea-header")[0].classList.remove("showing");
            document.getElementsByClassName("msgArea-container")[0].classList.remove("showing");
            // document.getElementsByClassName("msgArea-container")[0].classList.remove("showing");
        }
        this.setState({
            showing: !b
        });
    }

    // Deprecated: This chat always exists, the current page is created automatically.
    // Which means that the chat always exists... <exists className=""></exists>
    // So, as a quick measure, check if messages array is empty, if it is, this is the first
    // chat initiated, so we can create it in the DB
    // In the future, we will query the DB to make sure that this chat does not already exist.
    // New version is to always assume chat exists and return the appropriate index from this
    getChatIndex(targetUid){
        // console.log('looking for chat: ', targetUid);
        // console.log("this.state.chats: ", this.state.chats);
        for(var i = 0; i < this.state.chats.length;i++){
            for(var p = 0;p < this.state.chats[i].participants.length;p++){
                // console.log("this.state.chats[i].participants[p]: ", this.state.chats[i].participants[p]);
                // console.log("targetUid: ", targetUid);
                // console.log("this.state.uid: ", this.state.uid);
                if(this.state.chats[i].participants[p] !== this.state.uid 
                    && targetUid === this.state.chats[i].participants[p]){
                    return {
                        isNew: this.state.chats[i].messages.length === 0,
                        index: i,
                        id: this.state.chats[i].id
                    }
                }
            }
        }
    }
    
    f_sendMsg = (uid, targetUid, formRef) => {
        const chatObj = this.getChatIndex(targetUid);
        // console.log('chatObj: ', chatObj);
        // console.log('targetUid: ', targetUid);
        if(!chatObj) return; //TODO send email to support address to say that chat couldn't be found
        // console.log("this.chatFormRef: ", this.chatFormRef);
        var message;
        // Using interactionsPage.jsx and class SingleMsgArea in chat.jsx
        if(!this.chatFormRef.current){
            // message = formRef.current.value;
            message = formRef.current.querySelector("textarea.chatForm-inner").value;
            formRef.current.querySelector("textarea.chatForm-inner").value = "";
        } else {
            message = this.chatFormRef.current.querySelector("textarea.chatForm-inner").value;
            this.chatFormRef.current.querySelector("textarea.chatForm-inner").value = "";
        }
        const messageObj = {
            timestamp: Date.now(),
            message: message,
            sender: uid
        }
        const chats = this.state.chats;
        
        // console.log(message);
        // console.log(this.state.uid && chatObj.isNew);

        if(this.state.uid && chatObj.isNew){
            // console.log('newMessage: ', message);
            //console.log("uid: ", uid);
            //console.log("targetUid: ", targetUid);

            db.createNewChat(uid, targetUid).then(newChatId => {
                console.log("newChatId: ", newChatId);
                // Store chatId

                /*chats.push({
                    participants: [uid, targetUid],
                    messages: [{
                        timestamp: Date.now(),
                        message: message
                    }] 
                })*/
                console.log("messageObj: ", messageObj );
                chats[chatObj.index] = {
                    id: newChatId,
                    participants: [uid, targetUid],
                    messages: [
                        [],//[messageObj]
                    ],
                    personalDetails: this.state.chats[chatObj.index].personalDetails
                };
                const newMessagesIndexes = this.state.newMessagesIndexes;
                newMessagesIndexes[newChatId] = 1;
                this.setState({
                    chats: chats,
                    newMessagesIndexes: newMessagesIndexes
                }, ()=>{
                    db.sendChatMessage(newChatId, messageObj, targetUid);
                    this.scrollToBottom();
                    this.sendChatMsgReceivedEmail(newChatId, targetUid, 1);
                })
            })
        } else {
            // console.log("this.state.uid: ", this.state.uid);
            //console.log("this.checkChatExists(targetUid)): ", this.getChatIndex(targetUid));
            const allMessages = this.state.chats[chatObj.index].messages;
            const latestMessages = this.state.chats[chatObj.index].messages[this.state.chats[chatObj.index].messages.length - 1];
            //TODO, get message index, whether it's for today or not.
            //console.log("m: ", m[0].timestamp);
            // Push to new array since we are not using DB Hooks, update local data to be in a new date
            // If not sure, then study the DB to figure it out
            var dt = new Date(parseInt(latestMessages[0].timestamp));
            var today = new Date();
            var diff = (dt.getTime() - today.getTime()) / 1000;
            diff /= (60 * 60 * 24);
            //console.log('day diff: ', Math.abs(diff));
            if(Math.abs(diff) > 1){
                //allMessages.push(m)
                allMessages.push([messageObj]);
            }
            else {
                //latestMessages.push(messageObj);
                allMessages[allMessages.length - 1].push(messageObj);
            }
            //console.log("messageObj: ", messageObj);
            //console.log("m: ", m);
            chats[chatObj.index] = {
                id: chatObj.id,
                participants: [uid, targetUid],
                messages: 
                    allMessages
                /*[
                    m
                ]*/,
                personalDetails: this.state.chats[chatObj.index].personalDetails
            };
            // console.log("messageObj: ", messageObj);
            const newMessagesIndexes = this.state.newMessagesIndexes;
            if(!newMessagesIndexes[chatObj.id]) 
                newMessagesIndexes[chatObj.id] = 1;
            else 
                newMessagesIndexes[chatObj.id] += 1;
            this.setState({
                chats: chats,
                newMessagesIndexes: newMessagesIndexes
            }, ()=>{
                db.sendChatMessage(chatObj.id, messageObj, targetUid);
                // console.log('newMessagesIndexes: ', this.state.newMessagesIndexes);
                this.scrollToBottom();
                this.readAllMessages(chatObj.id);
                this.sendChatMsgReceivedEmail(chatObj.id, targetUid, newMessagesIndexes[chatObj.id]);
            })
        }
        //console.log("chats[chatObj.index]: ", chats[chatObj.index]);
    }

    scrollToBottom = () => {
        // scroll to bottom
        // TODO for now just make everything scroll to the bottom
        const chatAreas = document.getElementsByClassName("chatContainer");
        // console.log(chatAreas);
        // const chatArea = document.getElementsByClassName("chatContainer")[0];
        window.setTimeout(()=>{
            for(var chatArea of chatAreas){
                if(!chatArea) return;
                chatArea.scrollTop = chatArea.scrollHeight;
            };
        }, 250);
    }

    // Check if the user has scrolled to the bottom of the page and run checkReadMessage();
    readAllMessages = (chatId) => {
        // Check if there are new messages to update state to read
        //console.log('this.state.unreadIndexes: ', this.state.unreadIndexes);
        //if(!this.state.unreadIndexes[chatId]) return;
        const uid = this.state.uid || localStorage.getItem('uid') || global.authUid;
        if(!chatId || !uid) return;
        db.setReadChatMessagesIndexForUser(chatId, uid);

        const myUnreadChatIds = this.state.myUnreadChatIds;
        myUnreadChatIds.splice(myUnreadChatIds.indexOf(chatId), 1);
        this.setState({
            myUnreadChatIds: myUnreadChatIds
        })
    }

    render(){
        //console.log('this.state.chats: ', this.state.chats);
        return(
            <div className="msgArea-container" style={{visibility: this.props.b_display ? "visible" : "hidden"}}>
                <div className="msgArea-header ANALYTICS_chatHeaderSelected" onClick={()=>{this.toggleContent(this.state.showing)}}>
                    <span className="text g-f1 chatHeader-txt" style={{pointerEvents:"none"}}>Send Message</span>
                    <img src={window.innerWidth < 650 ? chat_white : msg } alt="" className="g-cursor-pointer chatHeader-img" style={{pointerEvents:"none"}}/> 
                </div>  
                <div ref={this.msgAreaContentsRef} className="msgArea-contents">
                {
                    this.state.showing &&
                    <MsgArea {...this.state} 
                        chatFormRef={this.chatFormRef} 
                        f_sendMsg={this.f_sendMsg} 
                        uid={this.state.uid}
                        currentUid={this.state.currentUid}
                        scrollToBottom={this.scrollToBottom}
                        nextUid={this.props.nextUid}
                        readAllMessages={this.readAllMessages} />
                }
                </div>
            </div>
        );
    }
}

// Used by Interactions/InteractionContainer.jsx
class SingleMsgArea extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentPageUser: this.props.currentPageUser || {},
            chatFormRef: this.props.chatFormRef,
            targetUid: this.props.currentUid,
            currentUid: this.props.currentUid,
            uid: this.props.uid,
            unreadIndexes: this.props.unreadIndexes,
            myUnreadChatIds: this.props.myUnreadChatIds,
            numberOfMessages: this.props.numberOfMessages,
            nickname: this.props.nickname,
            minimized: true,
        }
    }

    componentDidMount(){
        if(this.state.totalMessages !== this.props.totalMessages){
            this.setState({totalMessages:this.props.totalMessages});
        }
    }

    componentDidUpdate(){
        if(this.state.targetUid !== this.props.currentUid){
            this.setState({ targetUid: this.props.currentUid});
        }
    }

    f_toggleMinimized = () => {
        this.setState({
            minimized: !this.state.minimized
        });
    }

    render(){
        //console.log('this.totalMessages: ', this.props.totalMessages);
        // console.log('this.state.targetUid: ', this.state.targetUid);
        // console.log('this.props.uid: ', this.props.uid);
        return (
            <div className="chat-container-lower g-w-100">
                {   this.props.chats.length
                    ? <div>
                        <div className="g-flex" style={{paddingTop:"0px",width:"100%"}}>
                            {/* <ChatUsers chats={this.props.chats} currentPageUser={this.props.currentPageUser} f_updateIndex={this.f_updateIndex} scrollToBottom={this.props.scrollToBottom} myUnreadChatIds={this.props.myUnreadChatIds} /> */}
                            {this.props.chats[0].filler === undefined
                            ? <ChatArea 
                                displayType={"singleMsgArea"}
                                nickname={this.props.nickname}
                                totalMessages={this.props.totalMessages}
                                uid={this.props.uid} chat={this.props.chats[0]} unreadIndex={this.props.unreadIndexes[this.props.chats[0].id]} 
                                minimized={this.state.minimized}
                                f_toggleMinimized={this.f_toggleMinimized} />
                            : <div></div>
                            }
                        </div>
                        { !this.state.minimized 
                        ? <div>
                            <ChatForm chatFormRef={this.props.chatFormRef} minimized={this.state.minimized} />
                            <ChatBottom uid={this.props.uid} targetUid={this.state.targetUid} f_sendMsg={this.props.f_sendMsg} chatFormRef={this.props.chatFormRef} minimized={this.state.minimized} />
                        </div>
                        : ""
                        }
                    </div>
                    : <NoMessages nextUid={this.props.nextUid} />
                }
            </div>
        );
    }
}

export { SingleMsgArea };

class MsgArea extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentIndex: this.props.chats.length ? 0 : undefined,
            targetUid: this.props.currentUid,
            currentPageUser: this.props.currentPageUser || {},
            chatFormRef: this.props.chatFormRef,
            currentUid: this.props.currentUid, // I used this incorrectly, so have to adjust this later on
            uid: this.props.uid,
            unreadIndexes: this.props.unreadIndexes,
            myUnreadChatIds: this.props.myUnreadChatIds
        }
    }

    componentDidMount(){
        //console.log("this.props.chats.length: ", this.props.chats.length);
        if(this.state.currentIndex !== undefined && this.props.chats.length){
            this.f_updateIndex(0);
            //document.getElementById("chat-" + this.state.currentIndex).style.backgroundColor = "#0073b1"; // "rgba(250,250,250,.75)"
            //document.getElementById("chat-" + this.state.currentIndex).style.color = "white";
        }
    }

    f_updateIndex = (newIndex) => {
        //console.log("updating index!!");
        if(this.state.currentIndex !== undefined){
            document.getElementById("chat-" + this.state.currentIndex).style.backgroundColor = "transparent";
            document.getElementById("chat-" + this.state.currentIndex).style.color = "rgba(0,0,0,.9)";
        }
        /*const targetUid = this.state.chats[0].personalDetails.thisPage 
            ? this.state.chats[newIndex-1].participants.filter(p => {return p !== this.props.uid})[0] 
            : this.state.chats[newIndex-1].participants.filter(p => {return p !== this.props.uid})[0] 
            */
        this.setState({
            currentIndex: newIndex,
            targetUid: this.props.chats[newIndex].participants.filter(p => {return p !== this.props.uid})[0]
        }, () => {
            // const chatArea = document.getElementsByClassName("chatContainer")[0];
            // chatArea.scrollTop = chatArea.scrollHeight;
            this.props.readAllMessages(this.props.chats[newIndex].id);
        })
        document.getElementById("chat-" + newIndex).style.backgroundColor = "#0073b1"; //"rgba(218, 218, 226, 0.35)" //"rgba(250,250,250,.75)"
        document.getElementById("chat-" + newIndex).style.color = "white";
    }

    render(){
        //console.log(this.props)
        return (
            <div className="chat-container-lower">
                {   this.props.chats.length
                    ? <div>
                        <div className="g-flex" style={{paddingTop:"0px",width:"100%"}}>
                            <ChatUsers chats={this.props.chats} currentPageUser={this.props.currentPageUser} f_updateIndex={this.f_updateIndex} scrollToBottom={this.props.scrollToBottom} myUnreadChatIds={this.props.myUnreadChatIds} />
                            {
                                this.state.currentIndex !== undefined && 
                                <ChatArea uid={this.props.uid} chat={this.props.chats[this.state.currentIndex]} unreadIndex={this.props.unreadIndexes[this.props.chats[this.state.currentIndex].id]} />
                            }
                        </div>
                        <ChatForm chatFormRef={this.props.chatFormRef} />
                        <ChatBottom uid={this.props.uid} targetUid={this.state.targetUid} f_sendMsg={this.props.f_sendMsg}/>
                    </div>
                    : <NoMessages nextUid={this.props.nextUid} />
                }
            </div>
        );
    }   
}

function NoMessages(props){
    return <div>
        <p className="g-soft-text g-mg-t-10">
            {l.get('C_NO_MESSAGES')}
            <br/>
            <a href={props.nextUid} style={{color:"rgba(0,0,0,.9)",textDecoration:"underline"}}>
                {l.get('C_VISIT_PROFILE')}
            </a> {l.get('C_START_MESSAGING')}
        </p>
    </div>
}

class ChatArea extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            unreadIndex: props.unreadIndex,
            nickname: props.nickname,

            // Only passed through for Interactions Page
            displayType: props.displayType || undefined,
            totalMessages: props.totalMessages,
            minimized: props.minimized,
            f_toggleMinimized: props.f_toggleMinimized || undefined,
        }
    }

    componentDidMount(){
        this.calculateUnread();
    }

    componentDidUpdate(){
        //console.log('updated: ', this.props.unreadIndex);
        //console.log('this.state.unreadIndex: ', this.state.unreadIndex);
        if(this.state.unreadIndex !== this.props.unreadIndex){
            this.setState({
                unreadIndex: this.props.unreadIndex
            }, ()=>{
                this.calculateUnread();
            })
        }
        if(this.state.totalMessages !== this.props.totalMessages){
            this.setState({
                totalMessages: this.props.totalMessages
            });
        }
        if(this.state.minimized !== this.props.minimized){
            this.setState({
                minimized: this.props.minimized
            });
        }
    }

    calculateUnread = () => {
        if(this.state.unreadIndex === undefined) return;
        // get which day's message 
        // Iterate through backwards to find which day to set to unread
        var unreadMsgDayIndex = 0; //TODO this might cause issues
        var leftovers = this.state.unreadIndex;
        //console.log(this.state.unreadIndex);
        //console.log(this.props.chat.messages);
        for(var i=this.props.chat.messages.length-1;i>=0;i--){
            if(this.props.chat.messages[i].length >= leftovers){
                //console.log('matching at: ', i);
                unreadMsgDayIndex = i;
                break;
            }
            else {
                //console.log('deducting leftovers: ', leftovers);
                leftovers -= this.props.chat.messages[i].length;
            }
        }
        // Get the leftover amount and set as the unread amount index
        // console.log("console.log(this.props.chat.messages[unreadMsgDayIndex]): ", this.props.chat.messages[unreadMsgDayIndex])
        // console.log('leftover: ', leftovers);
        //console.log('unreadMsgDayIndex: ', unreadMsgDayIndex);
        // console.log("this.props.chat.messages: ", this.props.chat.messages);
        //console.log('unreadMsgDayIndex: ', unreadMsgDayIndex);
        // Sometimes takes a while to load
        if(!this.props.chat.messages[unreadMsgDayIndex]) return;
        var unreadMsgIndex = Math.max(this.props.chat.messages[unreadMsgDayIndex].length - leftovers - 1, 0);
        // Dont show if it's an empty message
        if(unreadMsgDayIndex === 0 && unreadMsgIndex === 0)
            unreadMsgIndex = -1;

        this.setState({
            unreadMsgDayIndex: unreadMsgDayIndex,
            unreadMsgIndex: unreadMsgIndex
        })
    }

    render(){
        const props = this.props;
        // console.log("props.chat.messages: ", props.chat.messages);
        return <div className={"chatContainer " + this.state.displayType + (this.state.minimized ? " minimized " : "")}>
            <div className="chatHeader" 
                onClick={()=>{
                    // If not a part of interactionsPage
                    if(this.state.displayType){
                        this.props.f_toggleMinimized();
                    }
                }}
            >
                <div className="g-flex g-space-between g-pd-r-16">
                    <Link to={'/user/' + props.chat.participants.filter((p)=>{return p !== props.uid})[0]}>
                        <span className={"nickname-span " + (this.state.nickname ? "" : "g-invisible")}>{this.state.nickname || "_"}<br/></span>
                        <span> <b>{helpers.convertStringToYear(props.chat.personalDetails["Date of birth"]) || "?"}</b></span>
                        <span><b>{" " + String.fromCharCode("0x2022") + " "}</b></span>
                        <span><b> {props.chat.personalDetails.Gender || "?"}</b></span>
                        <b> - </b>
                        <span> {props.chat.personalDetails.Occupation}</span>
                        <br/>
                        <span> {props.chat.personalDetails["Your Location"]}</span>
                    </Link>
                    { this.props.totalMessages 
                        ? <div style={{margin:"auto 32px"}}>
                            <span className="chat-totalMessages">{this.props.totalMessages} 
                                <img src={chat_white} alt="" className="chat-totalMessages-img" />
                            </span>
                        </div>
                        : ""
                    }
                    <div className="g-text-right">
                        <img src={arrow_down} className={"chat_container-arrow " + (this.state.minimized ? "minimized" : "")} alt="" />
                    </div>
                </div>

                <hr style={{borderTopWidth:"2px"}}/>
                {/* <span className="chatHeader-subtitle">This is the start of your chat</span> */}
            </div>
            <div className={"chatArea " + (this.state.minimized ? "minimized" : "")}>
                {
                    props.chat.messages.map((messages, index1) => (
                        <div key={index1}>
                            {   messages.length ?
                                <span className="chatBoxTimeSeparator">
                                    {
                                        // The first timestamp is indicative of the first chat time, so we set a date from the first
                                        helpers.convertTimestampToDate(messages[0].timestamp) 
                                    }
                                </span>
                                : ""
                            }
                            {
                                messages.map((m, index2) => (
                                    <ChatMessage 
                                        unreadIndex={index1 === this.state.unreadMsgDayIndex && index2 === this.state.unreadMsgIndex}
                                        m={m} 
                                        uid={props.uid} 
                                        index={index2} 
                                        key={"chat-message-" + index2} />
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    }
}

class ChatMessage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            m: props.m,
            uid: props.uid,
            index: props.index,
            unreadIndex: props.unreadIndex,
            loading: false // TODO dynamically set this, display text from the start and update its loading state based on dynamically assigned refs, or targeting DOM ID
        }
    }
    
    sendToProfile = () => {
        this.setState({
            loading: true
        });
        const id = Date.now().toString();
        db.saveChatQuote(this.state.uid, id, this.props.m.message).then(()=>{
            this.setState({
                loading: false
            });
            // this.props.f_addChatQuote({
            //     value: this.props.m.message,
            //     id: id,
            // })
        })
    }

    render(){
        //console.log("props.unreadIndex: ", this.props.unreadIndex);
        //console.log("this.props.m.timestamp: ", this.props.m.timestamp);
        return(
            <div>
                <div key={this.state.index} className={"chatBox" + (this.props.m.sender === this.props.uid ? " self-send " : "")}>
                    <span className={this.state.loading ? "g-soft-text" : ""}>{this.props.m.message} </span>
                    {
                        this.state.loading ?
                        <CircularLoader />
                        : 
                        <span className="chatBox-msg-time">
                            {" " + String.fromCharCode("0x2022") + " "}
                            {helpers.convertTimestampToTime(this.props.m.timestamp)}
                        </span>
                    }
                    <span className="chat-send2Profile" onClick={()=>{this.sendToProfile()}}>{l.get('C_SEND2PROFILE')}</span>
                    
                    <span className={"chat-unread " + (this.props.unreadIndex ? " chat-unreadTag " : "")}></span>
                </div>
            </div>
        );
    }
}

function ChatUsers(props){
    // console.log('props.chats: ', props.chats)
    //console.log('myUnreadChatIds: ', props.myUnreadChatIds);
    return <div className="chatUsers">
        {
            props.chats.map((chat, index) =>(
                <ChatUser
                    key={index} 
                    user={chat.personalDetails}
                    index={index}
                    b_unread={props.myUnreadChatIds.includes(chat.id)}
                    f_updateIndex={props.f_updateIndex}
                    scrollToBottom={props.scrollToBottom} />
            ))
        }
    </div>
}

function ChatUser(props){
    // console.log("chat user: ", props.user);
    const user = props.user;
    return <div className="chatUser-container">
        {/* 🍔 */}
        <div className={"chatUser " + (user.thisPage ? " thisProfile-container " : "")}
            id={"chat-" + props.index}
            onClick={()=>{
                props.f_updateIndex(props.index);
                //TODO make this happen after setting state
                props.scrollToBottom();
            }} >
                {helpers.convertStringToYear(user["Date of birth"])}
                {user.Gender ? user.Gender.substring(0, 1) : ""}
                {/* {user.occupation} 
                {user.liveLocation} */}
        </div>
        { user.thisPage
            ? <div className="g-soft-text thisProfile">
                {/* <p>This Profile</p> */}
                {/* <hr/> */}
            </div>
            : <div></div>
        }   
        <div className={" chat-user-unread " + (props.b_unread ? " active " : "")}></div>
    </div>
}

class ChatForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            chatFormRef: props.chatFormRef
        }
    }
    
    chatFormClicked = (b) => {
        //TODO fix this to work for multiple elements
        // const el = document.getElementsByClassName("chatForm")[0];
        const el = this.props.chatFormRef.current;
        // console.log(el);
        //if(el.classList.contains("active")){
        if(!b){
            el.classList.remove("active");
        } else {
            el.classList.add("active");
        }
    }

    render(){
        return <div className="chatForm"
            ref={this.props.chatFormRef}

            onFocus={()=>{this.chatFormClicked(true)}}
            onBlur={()=>{this.chatFormClicked(false)}}>
            {/* <textarea ref={this.props.chatFormRef} className="chatForm-inner"  */}
            <textarea className="chatForm-inner" 
                //defaultValue="Write your message here..." 
                placeholder="Write your message here...">
                
            </textarea>
        </div>
    }   
}

function ChatBottom(props){
    // console.log("my uid: ", props.uid);
    // console.log("target uid: ", props.targetUid)
    // console.log("chatFormRef: ", props.chatFormRef);
    // console.log('f_sendMsg: ', props.f_sendMsg);
    return <div className="chatBottom">
        <div></div>
         <div className="msgArea-contents-inner-button-container">
            <div
                id="ANALYTICS_sendChatMessage" 
                className="msgArea-contents-inner-button" 
                onClick={()=>{
                    // console.log(props.uid, props.targetUid);
                    props.f_sendMsg(props.uid, props.targetUid, props.chatFormRef)}}
            >{l.get('C_SEND')}</div>
        </div>
    </div>
}

/*
function SayHi(props){
    return <div className="msgArea-contents-inner-container">
        <div className="msgArea-contents-inner-textarea-container">
            <textarea defaultValue={config.DEFAULT_MESSAGE_TEXT}></textarea>
        </div>
        <div className="msgArea-contents-inner-button-container">
            <button className="msgArea-contents-inner-button" onClick={()=>{props.f_sendMsg(props.authUid, props.targetUid)}}>Send</button>
        </div>
    </div>
}*/


// This needs to happen serverside
        // const sendNewChatMsgReceived = (uid, basicDetails, occupation, location, aboutMe)=>{
        //     axios({
        //         method: 'POST',
        //         url: `${config.SERVER_ADDRESS}/chatMsgReceived`,
        //         data: {
        //             numberUnread: this.state.numberUnread,
        //             userDetails: {
        //                 basicDetails: basicDetails,
        //                 occupation: occupation,
        //                 location: location,
        //                 aboutMe: aboutMe
        //             },
        //             uid: uid
        //         }
        //     })
        //     .then(res => {
        //     }, (error) => {
        //         console.error("Error posting chatMsgReceived email: ", error);
        //     })
        // }

        // for(var chatId of this.state.listeningChatIds){
        //     if(this.state.unreadIndexes[chatId] > 0){
        //         db.getContactFieldsToShow(uid)
        //             .then((data)=>{
        //                 //console.log('got contact fields data: ', data);
        //                 const age = helpers.convertStringToYear(data['Date of birth']);
        //                 const gender = data.Gender.substring(0,1);
        //                 sendNewChatMsgReceived(uid, age + gender, data.Occupation, data['Your Location'], data['About Me'])
        //             })	
        //             .catch((err) => {
        //                 console.error("Error retrieving hidden fields: ", err);
        //             });
        //     }
        // }


        // sendWindowReloaded = () => {
    //     if(Object.keys(this.state.newMessagesIndexes).length){
    //         const uid = localStorage.getItem('uid') || global.authUid || this.state.uid;
    //         axios({
    //             method: 'POST',
    //             url: `${config.SERVER_ADDRESS}/windowReloaded`,
    //             data: {
    //                 uid: uid,
    //                 // Sending the unread indexes instead for current ones.
    //                 unreadIndexes: this.state.newMessagesIndexes
    //                 //unreadIndexes: this.state.unreadIndexes
    //             }
    //         }).then(()=>{})
    //         .catch(err => console.error("Error sendingChatMsgReceived: ", err));
    //     }
    // }