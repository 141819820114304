import React from 'react';
import './roomUsers.css';

export default class RoomUsers extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        return(
            <div className="roomUsers-container">
                Room Users
            </div>
        );
    }
}
