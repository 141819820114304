import React from 'react';

export default class EditDate extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: props.name,
            mandatory: props.mandatory,
        }
    }

    createYears(){
        let options = [];
        for(var i = 2020; i >= 1950;i--){
            options.push(<option key={i} value={i}>{i}</option>)
        }
        return options;
    }

    getMonthNameFromNumber(number){
        switch(parseInt(number)){
            case 0: return "January";
            case 1: return "February";
            case 2: return "March";
            case 3: return "April";
            case 4: return "May";
            case 5: return "June";
            case 6: return "July";
            case 7: return "August";
            case 8: return "September";
            case 9: return "October";
            case 10: return "November";
            case 11: return "December";
            default: return "";
        }
    }
//this.getMonthNameFromNumber(this.props.default.split("-")[1])
    render(){
        //console.log('EditDate props: ', this.props.default);
        return(
            <div className="editDateContainer">
                <label className="editInputLabel">{this.state.name} 
                    <label className="mandatoryInputLabel">{this.state.mandatory ? "*" : ""}</label>
                </label>
                <br />
                <select className="editSelect m-modal-toRead" 
                    name={this.state.name.trim() + "Month"}
                    defaultValue={this.props.default.split("-")[1]}>
                    <option value>Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                </select>
                <select className="editSelect m-modal-toRead" 
                    name={this.state.name.trim() + "Year"}
                    defaultValue={this.props.default.split("-")[0]}>
                    <option value>Year</option>
                    {
                        this.createYears()
                    }
                </select>
            </div>
        )
    }
}
