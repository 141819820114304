import React from 'react';
import config from '../config.js';
import './footer.css';
import email from '../Assets/email.svg';
import l from '../language.js';

export default class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return <div id="footer">
			<div className="g-mg-b-10 g-mg-t-10 g-text-left">
                {config.APP_TITLE} Limited &copy; 2020 
                <br/>
                <span style={{fontSize:"1.2rem"}}>{config.APP_MOTTO}</span>
                <br/>
                <br/>
                <span style={{fontSize:"1.2rem"}}>{config.PHYSICAL_ADDRESS}</span>
            </div>
            <div className="txt g-text-left footer-text" style={{marginTop:"18px"}}>
                {l.get('FOOTER_ATTRIBUTION')} <a href="https://icons8.com" target="_blank" rel="noopener noreferrer">Icons8</a>, <a href="https://undraw.co/" target="_blank" rel="noopener noreferrer">Undraw</a>
            </div>
            <div className="footer-txt g-text-left g-mg-t-10">
                {/* <p>CONTACT</p> */}
                <span className="footer-txt">{l.get('FOOTER_CONTACT')}</span> 
                <a href={"mailto:" + config.CONTACT_EMAIL}>
                    <img src={email} alt="" className="footer-email-container" />
                </a>
            </div>
	</div>
    }
}