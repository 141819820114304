import React from 'react';

export default class EditSpecialRequest extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            txt: props.default
        }
    }

    onChange = (e) => {
        const newValue = e.target.value;
        this.setState({
            txt: newValue
        })
    }

    render(){
        return(
            <div>
                <label className="editInputLabel g-inlineblock">And </label>
                <input
                    name="Special Requirements" 
                    className="editInputInput m-modal-toRead editSpecialRequirements-input" 
                    defaultValue={this.state.txt || this.props.default} 
                    onChange={this.onChange}
                    placeholder=""
                    style={{
                        width: "90%",
                        fontWeight: "400"
                    }} />
            </div>
        );
    }
}
