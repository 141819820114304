import React from 'react';
import './qualitiesSelection.css';
import config from '../config.js';

// https://s-moodle.vsb.bc.ca/s-moodle/pluginfile.php/35128/mod_resource/content/2/List%20of%20Human%20Qualities.pdf

export default class QualitiesSelection extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            qualities: props.default,
            // All qualities including the ones that have been selected
            allQualities: config.QUALITIES,
            // Sorted qualities excluding the ones that have been selected
            displayedQualities: [

            ],
            showMaximumQualitiesSelectedWarning: false,
            //spanSpacings: this.createSpacingsFromProps(props.default)
        }
        this.qualitiesSelectionInputRef = React.createRef();
    }

    componentDidMount(){
        this.sortQualities();
        
    }

    sortQualities = () => {
        var displayedQualities = this.state.allQualities.filter((q)=>{
            //console.log(this.state.qualities);
            //console.log(q);
            //console.log("includes? ", this.state.qualities.includes(q))
            return !this.state.qualities.includes(q)
        })
        //console.log("filtered displayedQualities: ", displayedQualities);
        this.setState({ 
            displayedQualities: displayedQualities.sort()
        });
    }

    onChangeHandler = () => {
        
    }

    componentDidUpdate(){
        if(this.state.qualities !== this.props.default){
            this.sortQualities();
        }
    }

    render(){
        //console.log("qualities: ", this.state.qualities);
        //console.log("displayedQualities: ", this.state.displayedQualities);
        return(
            <div>
                <div id="qualities-selection-input-container" className="g-relative">
                    { this.state.showMaximumQualitiesSelectedWarning && 
                        <span id="showMaximumSelectedWarning"
                            style={{color:"red",position: "absolute", top: "-5px"}}>
                                * You have reached the maximum number of qualities, please remove one to continue
                        </span>
                    }
                    <input ref={this.qualitiesSelectionInputRef} 
                        id="qualities-selection-input"
                        type="text" 
                        onChange={this.onChangeHandler} />
                    <div id="qualities-selection-input-selected">{
                        this.state.qualities.map((q, index) => (
                            <div key={index} 
                            className="qualities-selection-quality qs-selected"
                            onClick={()=>{
                                
                                //console.log("adding element to selected: ", index);
                                this.state.displayedQualities.push(q)
                                this.state.qualities.splice(index, 1)
                                this.setState({
                                    qualities: this.state.qualities,
                                    displayedQualities: this.state.displayedQualities.sort(),
                                    showMaximumQualitiesSelectedWarning: false
                                })
                            }}>
                                <span className="m-modal-toRead">
                                    {q}
                                </span>
                            </div>
                        ))
                    }</div>
                </div>
                    <div className="qualities-selection-container">
                        {
                            this.state.displayedQualities.map((q, index) => (
                                <div key={index}
                                    className="qualities-selection-quality"
                                    onClick={()=>{
                                        if(this.state.qualities.length === 5) {
                                            //this.qualitiesSelectionInputRef.current.style.backgroundColor = "red";
                                            this.qualitiesSelectionInputRef.current.classList.add("fadeFromRed");
                                            //console.log();
                                            this.setState({
                                                showMaximumQualitiesSelectedWarning: true
                                            }, () => {
                                                //window.setTimeout(()=>{
                                                
                                                //}, 5000)
                                            })
                                            return;
                                        };
                                        //console.log("removing element at index: ", index)
                                        this.state.qualities.push(q);
                                        this.state.displayedQualities.splice(index, 1)
                                        this.setState({
                                            qualities: this.state.qualities,
                                            displayedQualities: this.state.displayedQualities,
                                            showMaximumQualitiesSelectedWarning: false
                                        })
                                    }}>
                                    {q}
                                </div>
                            ))
                        }
                    </div>
            </div>
        )
    }
}
/*
style={{
    top:"17px",
    left:`${this.state.spanSpacings[index]}px` //5
}}>


// Calculate dynamic spacing
    createSpacingsFromProps = (array) => {
        var totalSpacing = 0;
        const spanSpacings = []
        array.forEach((q)=>{
            spanSpacings.push(totalSpacing);
            totalSpacing += (q.length * 6.75) + 40;
        })
        return spanSpacings;
    }

    this.setState({
                spanSpacings: this.createSpacingsFromProps(this.props.default),
                qualities: this.props.default
            });
*/