import React from 'react';

export default class EditTextArea extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: props.label,
            readonly: props.readonly,
            textLimit: props.textLimit,
            placeholder: props.placeholder,
            height: props.height,
            width: props.width || "100%",
            onlyBottom: props.onlyBottom || false,
            charCount: props.textLimit,
            dontShowLabel: props.dontShowLabel || false
        }
    }

    onChange=(e)=>{
        const newLength = this.props.textLimit - e.target.value.length;
        //console.log(e.target);
        this.setState({
            charCount: newLength
        })
    }

    render(){
        //console.log("props.placeholder: ", this.props);
        return(
            <div className="g-w-100">
                { !this.state.dontShowLabel && <div>
                        <label className="editInputLabel">{this.state.name} 
                        <label className="mandatoryInputLabel">{this.state.mandatory ? "*" : ""}</label>
                    </label>
                </div>
                }
                {
                    this.state.readonly 
                    ? <textarea className="editTextArea m-modal-toRead" name={this.state.name} value={this.props.default} readOnly placeholder={this.props.placeholder || ""}/> 
                    : this.state.textLimit && this.state.textLimit > 0 
                    ? <textarea 
                        className={"editTextArea m-modal-toRead g-noresize" + (this.state.onlyBottom ? " editTextArea-onlyBottom " : "")}
                        name={this.state.name} 
                        style={{height:`${this.props.height}`,minHeight:`${this.props.height}`,width:this.state.width}} 
                        defaultValue={this.props.default} 
                        maxLength={this.state.textLimit} 
                        onChange={this.onChange} 
                        placeholder={this.props.placeholder || ""} />
                    : <textarea className="editTextArea m-modal-toRead" name={this.state.name} defaultValue={this.props.default} placeholder={this.props.placeholder || ""} />
                }
                {
                    this.state.textLimit && this.state.textLimit > 0 && <label className="g-text-right">{this.state.charCount} remaining</label>
                }
            </div>
        );
    }
}
