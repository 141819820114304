import React from 'react';
// import l from '../language.js';
import helpers from '../helpers.js';
import edit from '../Assets/edit.svg';
import db from '../db.js';
import './nickname.css';
// import EditBtnWhole from './editBtnWhole.jsx';
// Nickname for this user, as set by the user viewing this.
// Despite displaying on user's page, this belong to the authUser

export default class Nickname extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nickname: props.nickname,
            localNickname: props.nickname,
            currentUid: props.currentUid,
            authUid: props.authUid,
            personalDetails: props.personalDetails,
            defaultNickname: "",
            // Default nickname is 24 Female, 28 Male, etc.
        }
        //console.log(this.props.nickname);
    }

    componentDidMount(){
        this.getDefaultName();
    }

    componentDidUpdate(){
        if(this.state.nickname !== this.props.nickname){
            this.setState({
                nickname: this.props.nickname
            });
            this.getDefaultName();
        }
    }

    saveNickname = async (newNickname) => {
        //console.log(this.state.authUid, this.state.currentUid, newNickname);
        const val = await db.saveNickname(this.state.authUid, this.state.currentUid, newNickname);
        if(val){
            document.getElementById('nn-savedTxt').style.opacity = ".65";

            if(this.state.saveTextTimer){
                window.clearTimeout(this.state.saveTextTimer);
            }

            var saveTextTimer = window.setTimeout(()=>{
                document.getElementById('nn-savedTxt').style.opacity = "0";
            },2000);

            this.setState({
                saveTextTimer: saveTextTimer
            });
            // Save after every change
        }
    }

    // Creates a default name based on the uid
    getDefaultName = () => {
        // console.log(this.props.personalDetails);
        this.setState({
            defaultNickname: helpers.convertToDisplay(this.props.personalDetails, helpers.convertStringToYear)
        });
    }

    handleChange = (e) => {
        // console.log(e.target);
        this.saveNickname(e.target.value);
        this.setState({
            localNickname: e.target.value
        }, ()=>{
            
        });
    }

    render(){
        return(
            <div>
                {/* <EditBtnWhole callback={this.props.callback} data={this.props.nickname} src={edit} top={"0px"} /> */}
                <div className="g-flex g-space-between">
                    <span id="nn-savedTxt" style={{opacity:"0"}}>Saved all changes</span>
                    <input id="nicknameInput" 
                        maxLength={80}
                        spellCheck="false"
                        onChange={(e) => {this.handleChange(e)}}
                        placeholder={this.state.defaultNickname}
                        value={this.state.localNickname}>
                    </input>
                    
                    <div className="editBtnWholeContainer" style={{opacity:"1!important"}}>
                        <img src={edit} alt=""
                            onClick={()=>{
                                document.getElementById('nicknameInput').focus();
                            }} 
                        />
                    </div>
                </div>
            </div>
        );
    }
}
