import React from 'react';
import EditBtnWhole from './editBtnWhole.jsx';
import AddBtn from './addBtn.jsx';
import edit from '../Assets/edit.svg';
import './hireForPosition.css';

export default class HireForPosition extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hiringFor: props.hiringFor || {}
        }
    }

    editCallback = () => {
        this.props.fn_showModal('Open Positions', undefined, undefined, this.props.hiringFor, {hideDeleteBtn: true});
    }


    render(){
        //console.log("this.props.hiringFor: ", this.props.hiringFor);
        return(
            <div>
                {
                    this.props.ownPage ?
                        Object.keys(this.props.hiringFor).length ?
                            <EditBtnWhole callback={this.editCallback} data={this.props.hiringFor} src={edit}/>
                            : <AddBtn callback={this.editCallback} data={this.props.hiringFor} />
                        : ""
                }
                {
                    Object.keys(this.props.hiringFor)
                        .filter((f) =>{ return this.props.hiringFor[f] === true })
                        .map((hf, index) => (
                        <div key={"hf-"+index} className="hf-row">{hf}</div>
                    ))
                }
            </div>
        );
    }
}
