import React from 'react';

export default class EditCheckbox extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            label: props.label,
            default: props.default
        }
        this.inputRef = React.createRef();
    }

    componentDidUpdate(){
        if(this.state.default !== this.props.default){
            this.setState({
                default: this.props.default
            }, ()=>{
                //console.log("updated checkbox: ", this.state.default)
                this.inputRef.current.checked = this.state.default;
            });
        }
    }

    onChangeHandler = (e) => {
        //console.log("onchange pressed: ", e.target.checked);
        //this.inputRef.current.checked = false;
        /*
        if(this.inputRef.current.checked) this.inputRef.current.checked = false;
        else this.inputRef.current.checked = true;
        */
        /*this.setState({
            default: !e.target.checked
        }, ()=> {
            console.log("handling change: ", this.state.default)
        });
        */
    }

    render(){
        const d = Date.now().toString();
        //console.log("rendering checkbox: ", this.state.default);
        return(
            <div>
                <input id={d + this.state.label} 
                    ref={this.inputRef}
                    name={this.state.label} 
                    type="checkbox" 
                    className="m-modal-toRead" 
                    //checked={this.state.default || false}
                    defaultChecked={this.state.default === true}
                    //onClick={this.onChangeHandler}
                    //onChange={this.onChangeHandler}
                    />
                <label htmlFor={d + this.state.label}>{this.state.label}</label>
            </div>
        );
    }
}
