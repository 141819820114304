import React from 'react';

export default class EditInput extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			label: props.label,
            mandatory: props.mandatory,
			type: props.type,
			noedit: props.noedit,
		}
		this.inputRef = React.createRef();
	}

	// componentDidMount(){
	// 	if(this.props.noedit){
	// 		console.log(this.inputRef)
	// 		this.inputRef.current.readonly = true;
	// 	}
	// }

	render(){
		//console.log('rendering editinput: ', this.props.label);
		return(
			<div>
				<label className="editInputLabel">
					{this.props.label} 
					<label className="mandatoryInputLabel">{this.props.mandatory ? "*" : ""}</label>
				</label>
				<div className="editInputDiv">
					<input name={this.props.label} 
						//ref={this.inputRef}
						className="editInputInput m-modal-toRead" 
						type={this.props.type || "text"} 
						readOnly={this.props.noedit}
						disabled={this.props.noedit}
						defaultValue={this.props.default}/>
				</div>
			</div>
		);
	}
}