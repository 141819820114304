import React from 'react';
import AddBtn from './addBtn.jsx';
import EditBtnWhole from './editBtnWhole.jsx';
import edit from '../Assets/edit.svg';
import './aspirations.css';

export default class Aspirations extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            aspirations: props.aspirations
        }
    }

    componentDidMount(){
        //console.log('this.props.aspirations: ', this.props.aspirations);
    }

    addCallback = () => {
		this.props.fn_showModal('Aspirations', undefined, undefined, this.props.aspirations.length ? this.props.aspirations[0] : []);
    }

    editCallback = (data) => {
        this.props.fn_showModal('Aspirations', undefined, undefined, data);
    }

    render(){
        //console.log("this.props.aspirations: ", this.props.aspirations);
        return(
            <div>
                {
                    this.props.ownPage ?
                        this.props.aspirations.length ?
                            <EditBtnWhole callback={this.addCallback} data={this.props.aspirations[0]} src={edit}/>
                            : <AddBtn callback={this.addCallback} data={this.props.aspirations[0]} />
                        : ""
                }
                {
                    (this.props.aspirations || []).map((a, index) => (
                        <div key={"a-" + index}>
                            {index > 0 && <hr />}
                            <AspirationItem a={a} />
                        </div>
                    ))
                }
            </div>
        );
    }
}

function AspirationItem(props){
    // Old version of code is .Aspirations, new is .desc
    return <div className="aspirationText">
        {props.a.Aspirations || props.a.desc}
    </div>
}
