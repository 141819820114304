import React from 'react';
import { Route, withRouter//, BrowserRouter
	,Redirect 
} from 'react-router-dom' //Redirect, Link, BrowserRouter as Router 
import './switcher.css';
import BrowseComponent from './Browse/browse.js';
//import ProfileComponent from './Browse/profileComponent.js';
import SuggestedProfiles from './LeftContainer/suggestedProfiles.jsx';
import ReferenceTokenPage from './ReferenceToken/referenceTokenPage.jsx';
import InteractionsPage from './Interactions/interactionsPage.jsx';
import Header from './Header/header.jsx';
import Footer from './Footer/footer.jsx';
import Chat from './chat/chat.jsx';
import Favourites from './Favourites/favourites.jsx';
import LikesAreaRight from './Favourites/likesAreaRight.jsx';
import RoomComponent from './Rooms/roomComponent.jsx';
import favourite from './Assets/favorite.svg';
import db from './db.js';
import close from './Assets/close-black.svg';
//import date from './Assets/undraw/date_night.svg';

//import config from './config.js';

class Switcher extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			authUser: "",
			authPhoto: "",
			authUid: "",
			profile: {},
			profiles: [],
			allUids: [],
			uids: [],
			visitedPages: {},
			chats: [],
			myLikes: {},
			nextUid: "",
			nicknames: {}, // {uid: nickname}
		}
		this.pageLoaderRef = React.createRef();
	}

	componentDidMount(){
		// const [value, setValue] = React.useState(
	 //    	localStorage.getItem('authenticatedUser') || ''
	 //  	);
	 //  	console.log(value);
		const authUser = localStorage.getItem("displayName") || "";
		const authPhoto = localStorage.getItem("photoURL") || "";
		const authUid = localStorage.getItem("uid") || "";
		global.authUser = authUser;
	  	global.authPhoto = authPhoto;
	  	global.authUid = authUid;
		this.setState({
			authUser: authUser,
			authPhoto: authPhoto,
			authUid: authUid
		});
		//console.log('loaded state: ', authUser);
		if(authUid){
			db.getEncouragementStatus(authUid).then((res)=>{
				global.showEncouragementStatus = res;
			})
		}

		const component = this;
		const cb = (uids)=>{
			//console.log("uids: ", uids);
			if(!uids || !uids.length) return;
			component.setState({
				profile: uids[global.authUid],
				profiles: uids,
				allUids: uids,
				uids: uids,
			}, ()=>{
				// Makes the page load a random user
				if(!this.props.history.location.pathname.includes("/profile")
					&& !this.props.history.location.pathname.includes("/spaces")
					&& !this.props.history.location.pathname.includes("/messages")
					&& !this.props.history.location.pathname.match(/user\/.+/)){
					component.setState({
						currentUid: this.loadNextPage()
					}, ()=>{
						//console.log('loading next page: ', component.state.currentUid);
						this.props.history.location.pathname = "/";
						this.props.history.push("user/" + component.state.currentUid);
						this.loadVisitedPages();
						this.forceUpdate();
					})
				}
				
			})
		}
		  db.loadAllProfiles(cb);
		  
		  this.listenToNicknames();
	}

	listenToNicknames(){
		const cb = (newNicknames, component) => {
			//console.log('retrieved new nicknames: ', newNicknames)
			component.setState({
				nicknames: newNicknames
			});
		}
		if(global.authUid && global.authUid.length){
			db.listenToNicknames(global.authUid, cb, this);
		}
	}

	f_loadProfile = (profileUid) => {
		
	}

	f_loadNextPage = (nextUid) => {
		this.setState({
			nextUid: nextUid
		})
	}

	f_updateCurrentUid = (newUid) => {
		console.log('updating to new uid: ', newUid)
		this.setState({
			currentUid: newUid
		})
	}

	f_setAuthenticated = (uid, result, email) => {
		localStorage.setItem("displayName", result);
		localStorage.setItem("photoURL", "");
		localStorage.setItem("email", email);  
		localStorage.setItem("uid", uid);
		this.setState({ 
			authUid: uid,
			uid: uid,
			authUser: result,
			authEmail: email
		}, ()=>{
			//console.log('auth user updated: ', this.state);
			this.listenToNicknames();
		})
	}

	f_setChatCurrentPageUser = (uid, personalDetails) => {
		//console.log("f_setChatCurrentPageUser: ", uid);
		this.setState({
			chatUserUid: uid,
			
			// Set Current Page's chat user
			chatCurrentPageUser: this.createBlankChatForUser(uid, personalDetails),
		});
	}

	createBlankChatForUser(uid, personalDetails){
		return { 
			personalDetails: personalDetails,
			messages: [],
			participants: [
				localStorage.getItem("uid") || global.authUid,
				uid
			],
		};
	}


	saveVisitedPages = () => {
		localStorage.setItem("visitedPages", JSON.stringify(this.state.visitedPages));
	}

	loadVisitedPages = () => {
		const visitedPages = JSON.parse(localStorage.getItem("visitedPages")) || [];
		this.setState({visitedPages: visitedPages});
	}

	loadNextPage(){
		const component = this;
		// Check if any keys exist
		var randKey;
		if(!this.state.uids.length && this.state.allUids.length) {
			component.setState({
				uids: component.state.allUids //TODO this is not scalable for over 100 users, so make it select only the next three at any one time, and load those
			}, ()=>{
				randKey = component.loadRandom();
			})
		} else {
			randKey = this.loadRandom();
		}
		
		return randKey;
	}

	loadRandom(){
		const component = this;
		var randVal = Math.floor(Math.random() * this.state.uids.length);
		var randKey = this.state.uids[randVal];
		//console.log("all uids: ", this.state.uids);
		//console.log("this.state.uid: ", this.state.uid);
		//console.log("randKey: ", randKey);
		while(this.state.uid === randKey && this.state.uids.length > 1) {
			randVal = Math.floor(Math.random() * this.state.uids.length);
			randKey = this.state.uids[randVal];
		}
		//this.state.uids.splice(randVal, 1);
		//console.log('this.state.uids: ', this.state.uids);
		this.setState({
			//uids: ,
			uid: randKey
		}, () => {
			//console.log('loading new page uid: ', randKey);
			if(!component.state.uids.length) {
				// Every page has been visited, reload all pages
				//console.log('Reloading all: ', component.state.uids);
				component.setState({
					uids: component.state.allUids
				}, ()=>{
					//console.log(component.state.allUids);
				})
			}
		})
		return randKey;
	}

	f_sendUserToChat = (users) => {
		this.setState({
			chatUsers: users
		})
	}

	// f_addChatQuote = (obj) => {
	// 	const cq = this.state.chatQuotes;
	// 	cq[obj.id] = obj.value;
	// 	this.setState({
	// 		chatQuotes: cq
	// 	})
	// }

	f_myFavourites = (myFavourites) => {
		this.setState({
			myFavourites: myFavourites
		})
	}

	f_reloadFavourites = () => {
		this.setState({
			reloadFavourites: Date.now().toString()
		})
	}

	f_updateDashboard = () => {
		this.setState({
			reloadDashboard: Date.now().toString()
		})
	}

	f_updateLikes = (newLikes) => {
		this.setState({
			myLikes: newLikes
		});
	}

	// For chat to pipe data through
	f_updateInteractionsPage = (stateObj) => {
		// console.log('updating switcher state: ', stateObj);
		this.setState(stateObj);
	}
	
	render(){
		// console.log("this.state.currentUid: ", this.props.match.params)
		// console.log('this.state.nicknames: ', this.state.nicknames)
		// console.log("this.state.nicknames[this.props.match.params.userId]: ", this.state.nicknames[this.props.match.params.userId]);
		return(
			<div className="g-mg-auto" style={{maxWidth:"2000px"}}>
				<Header 
					{...this.props}
					authUser={this.state.authUser}
					authUid={this.state.authUid}
					f_setAuthenticated={this.f_setAuthenticated}
					f_updateCurrentUid={this.f_updateCurrentUid}
					f_loadNext={()=>{}}
					allUids={this.state.allUids}
					f_loadNextPage={this.f_loadNextPage}
				/>
				<div style={{height: "52px"}}></div>
				<div id="header-sizer">
					<div className="g-flex g-mg-auto g-flex-center" id="switcher-container">
						{
							!this.props.history.location.pathname.includes("/spaces") &&
							<SuggestedProfiles {...this.props} authUid={this.state.authUid} />
						}

						

						<div className="g-text-center browser-container">
							<Route exact path="/spaces" render={(props) => 
								<RoomComponent {...props} />
							} />

							<Route path="/messages" render={
								(props) => <InteractionsPage {...props} {...this.state}
									chats={this.state.chats} 
									currentUid={this.state.chatUserUid}
									currentPageUser={this.state.chatCurrentPageUser}
									nicknames={this.state.nicknames}
									users={this.state.chatUsers} 
									uid={this.state.authUid} 
									myLikes={this.state.myLikes}
									f_createBlankChatForUser={this.createBlankChatForUser}
								/> 
							} />
							
							<Route path="/" exact>
								{ 
									//<Redirect to={"/user/" + this.state.currentUid} />
									//()=> {
										//console.log("this.state: ", this.props)
										//this.props.history.push("/user/") 
									//}
								}
							</Route>
							<Route path="/user/:a/:b/:c">
								{
									<Redirect to={"/"} />
									// ()=> {
									// 	console.log("this.state: ", this.props)
									// 	this.props.history.push("/") 
									// }
								}
							</Route>
							<Route path="/user/:userId/:referenceToken" render={(props) => <ReferenceTokenPage {...props} />} />

							<Route exact path="/user/:userId" render={(props) => 
									<BrowseComponent {...props} 
										authUser={this.state.authUser} 
										authUid={this.state.authUid} 
										authEmail={this.state.authEmail} 
										profiles={this.state.profiles} 
										currentUid={this.state.currentUid} 
										myFavourites={this.state.myFavourites}
										myLikes={this.state.myLikes}
										f_updateCurrentUid={this.f_updateCurrentUid}
										nicknames={this.state.nicknames}
										f_reloadFavourites={this.f_reloadFavourites}
										reloadDashboard={this.state.reloadDashboard}
										f_setChatCurrentPageUser={this.f_setChatCurrentPageUser}
										//f_sendUserToChat={this.f_sendUserToChat} 
										loadNextPage={()=>this.loadNextPage(this)} />
							} />
							{/* <Route path="/scripts/" component={"/Scripts/intelliticks.js"}></Route> */}
							
							{/* <Route path="/profile" render={(props) => <ProfileComponent {...props} authUser={this.state.authUser} authUid={this.state.authUid} profile={this.state.profile} />} /> */}
							{/*<Route path="/user/undefined" exact>
								{ <Redirect to={"/loading"} />}
							</Route>*/}
						</div>
						
						{	this.state.authUid ? 
								window.innerWidth < 650 && (this.state.myFavourites || []).length ?
								<div>
									<div id="favourites-container-mobile" 
										className={this.state.showingMobile ? " showing " : ""} 
										onClick={()=>{this.setState({showingMobile:!this.state.showingMobile})}}>
										<img src={this.state.showingMobile ? close : favourite} alt="" />
									</div>
									<div className={"right-container" + (this.state.showingMobile ? " showing " : "")}>
										<Favourites authUid={this.state.authUid} 
											f_myFavourites={this.f_myFavourites}
											f_updateDashboard={this.f_updateDashboard} />
										{/* <img src={date} alt="" style={{width: "50vw",margin:"10vh auto 5vh auto"}} /> */}
										{/* <label>Like profiles and stay connected</label>	 */}
									</div>
								</div>
								: <div className="right-container">
									<LikesAreaRight authUid={this.state.authUid} f_updateLikes={this.f_updateLikes}/>
									<Favourites authUid={this.state.authUid} 
										f_myFavourites={this.f_myFavourites}
										f_updateDashboard={this.f_updateDashboard}
										reloadFavourites={this.state.reloadFavourites} />	
								</div>
							: ""
						}
					</div>
				</div>
				
				{
					this.state.authUid 
					&& this.state.authUid.length 
					? <Chat chats={this.state.chats} 
						b_display={!this.props.history.location.pathname.includes("/messages")}
						currentUid={this.state.chatUserUid}
						currentPageUser={this.state.chatCurrentPageUser}
						users={this.state.chatUsers} 
						uid={this.state.authUid} 
						nextUid={this.state.nextUid}
						f_updateInteractionsPage={this.f_updateInteractionsPage} />
					: ""
				}
				<Footer />
			</div>
		)
	}
}

export default withRouter(Switcher);
/*
<Route exact path="/">
	{ <Redirect to={"/user/" + this.state.currentUid} /> }
</Route>

*/
