import React from 'react';
import reading from '../Assets/undraw/reading.svg';
import './profileIsBlank.css';

export default class ProfileIsBlank extends React.Component {
	constructor(props){
		super(props);
		this.state = {

		}
	}

	letUserKnowYoureWaiting = () => {

	}

	render(){

        return <div>
            <img src={reading} alt=""
			   id="profileIsBlank-img" />
			<h4 className="g-mg-t-30 g-mg-b-100">Come back and visit me later</h4>
            <span onClick={()=>{this.letUserKnowYoureWaiting()}}></span>
		</div>
	}
}