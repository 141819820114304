import React from 'react';
import Chart from 'chart.js';
//import helpIcon from '../Assets/help.svg';

export default class StarChart extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			personalityCode: props.personalityCode,
			data: props.data
		}

	}

	componentDidMount(){
		this.createChart();
	}

	createChart(){
		var ctx = document.getElementById('starChart');
		const data = {
			labels: ['Openness', 'Conscientiousness', 'Extraversion', 'Agreeableness', 'Neuroticism'],
		    datasets: [{
		        data: [
					this.props.data.O, 
					this.props.data.C, 
					this.props.data.E, 
					this.props.data.A, 
					this.props.data.N
				],// this.props.data,//[5,1,2,3,8],
		        backgroundColor: 'rgba(0,115,177,.75)',//'rgba(90, 230, 71,.75)',
		        borderColor: 'rgba(90, 230, 71,.1)',
		        label: "",
		        borderWidth: 1,
		        pointRadius: 0,
		        pointHitRadius: 25
		    }]
		}



		const chartData = {
			type: 'radar',
			data: data,
			options: {
				scale: {
			        display: true,
			        ticks: {
			        	beginAtZero: true,
			        	max: 10,
			        	min: 0,
			        	display: false,
			        },
			        pointLabels:{
				       fontColor: "rgba(0,0,0,.6)",
				       fontSize: 10
				    },
				    gridLines: {
	              		color: 'rgba(0,0,0,.75)',
	              		lineWidth: 0.25
		            }
			    },
			    legend: {
			    	display: false,
			    	labels: {
						fontFamily: '"Montserrat", sans-serif !important',
						fontSize: "32px"
		        	}
			    },
			    tooltips: {
					enabled: true,
					label : {
						fontSize: '62px'
					}
		        },
		        layout: {
		        	padding: {
		        		bottom: 0,
		        		top: 0
		        	}
		        },
			},
		};
		new Chart(ctx, chartData);
	}

	_helpPressed = () => {
		console.log('personality help pressed');

	}

	render(){
		//console.log("personality data: ", this.props);
		return(
			<div>
				{/* <label className="g-inlineblock g-mg-0 g-mg-r-10">{this.props.personalityCode}</label> */}
				{/* <a 	href="https://en.wikipedia.org/wiki/Big_Five_personality_traits" 
					target="_blank">
					<img src={helpIcon} style={{maxHeight: '24px',marginBottom:'3.5px'}} onClick={()=>this._helpPressed()}/>
				</a> */}
				{/* <hr style={{width:'95%',margin:'0px',marginBottom:'10px'}}/> */}
				<div style={{width: '95%',marginTop:'0px',zIndex: "300"}} className="g-mg-b-20">
					<canvas id="starChart" width="300" height="175"></canvas>
				</div>
			</div>
		);
	}

	// width="500" height="275"
}
