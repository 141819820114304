// PRODUCTION = false;
const DEBUG = false; // Rename to LOCAL

// PRODUCTION = false;
// _DEBUG fields for Database
const DB_DEBUG = false;

// PRODUCTION = false;
const TEST_ENV = false; // Deploys to test url

// PRODUCTION = false;
const SEARCH_LOCAL = false;

// PRODUCTION = false;
const EMAIL_LOCAL = false;

// PRODUCTION = false;
const ROOMS_LOCAL = false;

// PRODUCTION = false;
const ANALYTICS_DEBUG = false;

// PRODUCTION = false;
const ANALYTICS_TEST = false;

// PRODUCTION = true;
// Set to false when developing so the data is pure
const ANALYTICS_ON = true;

// PRODUCTION = false;
// Use offline data
const USE_MOCK_DATA = false;

// PRODUCTION = false;
// Second DB in other Firebase project
const TEST_DB = false; 

console.log("client v0.6.2");

module.exports = {
	USE_MOCK_DATA: USE_MOCK_DATA,
	DEBUG: DEBUG,
	DB_DEBUG: DB_DEBUG,
	TEST_DB: TEST_DB,
	TEST_ENV: TEST_ENV,
	ANALYTICS_TEST: ANALYTICS_TEST,
	PUBLIC_URL: TEST_ENV ? "https://ohmyperson-test.firebaseapp.com/" : DEBUG ? "https://localhost:3000" : "https://ohmyperson.com",
	SERVER_ADDRESS: EMAIL_LOCAL ? "http://localhost:5000" : "https://dating-globally.appspot.com",//"https://mailer.ohmyperson.com",
	SEARCH_SERVER_ADDRESS: SEARCH_LOCAL ? "http:localhost:8080" : "https://live-tourism-itinerary.appspot.com",
	ROOM_SERVER_ADDRESS: ROOMS_LOCAL ? "http:localhost:9000" : "https://",
	ANALYTICS_DEBUG: ANALYTICS_DEBUG,
	ANALYTICS_ON: ANALYTICS_ON, 
	ELASTICSEARCH_ENDPOINT: 'https://search-ohmyperson-search-v3ezk24bxvcy5qi6m5hrzlsxva.ap-southeast-2.es.amazonaws.com',
	APP_TITLE: "Oh My Person", 
	LANGUAGE: "English", // "Japanese" || "Chinese" || "English"
	//"No Picture Profiles",//"OH MY PERSO",//"Personalities",
	// ePerson, virtualPeople
	APP_TITLE1: "2", //"No P",
	APP_TITLE2: "x", //"cture Prof",
	APP_TITLE3: "i", //"les",
	APP_SLOGAN: "Meet interesting people",
	//"Learn about people without distractions", 
	//"Dating for busy people", 
	//"Dating the proper way" 
	// Connecting Like-minded people/individuals
	//"We're eliminating loneliness"
	// Eliminating loneliness
	// Eliminate Loneliness
	APP_MOTTO: "Eliminating loneliness" , 
	MEET_PEOPLE: "Meet People",
	DEFAULT_MESSAGE_TEXT: "Hey, your profile is cool, let's connect!",
	QUALITIES: [
		"Loyal", "Honest", "Generous", "Passionate", "Hardworking",
		"Reliable", "Nice", "Humble", "Confident",
		"Integrity", "Empathetic", "Intuitve", "Creative", "Lifelong Learner", "Good Listener",
		"Persuasive", "Responsible", "Kind", "Leader", "Courageous", "Risk Taker", "Introspective", 
		"Self-aware", "Imaginative", "Sincere", "Committed", "Trustworthy", "Brave", "Calm",
		"Decisive", "Goal-oriented", "Inspiring", "Knowledgeable", "Motivating", "Optimistic",
		"Progressive", "Focused", "Unbiased", "Wise", "Zealous", "Supportive", "Noble", "Patient",
		"Agreeable", "Assertive", "Balanced", "Astute", "Intelligent", "Caring", "Cautious", 
		"Compassionate", "Consistent", "Cooperative", "Driven", "Determined", "Faithful",
		"Independent", "Idealistic", "Pragmatic", "Loving", "Obedient", "Polite",
		"Tactful", "Tolerant", "Understanding", "Warm", "Selfless", "Grateful", "Respectful",
		"Joyful", "Frugal", "Farsighted", "Chirpy", "Adventurous", "Affable", "Non-judgmental", "Low-maintenance",
		"Vulnerable", "Sensitive", "Communicator", "Happy"
	],
	CONTACT_EMAIL: "jun.a.kagaya@gmail.com",
	PHYSICAL_ADDRESS: "140 Anzac Avenue, Auckland 1010, New Zealand",

	BACKGROUND_LIGHT: "#0073b1",
	BACKGROUND_DARK: "#283e4a",
	BACKGROUND_RED: "rgb(224, 36, 94)",
}

// console.warn(
// 	"Want to help eliminate loneliness? Get in touch at " + module.exports.CONTACT_EMAIL
//);

console.warn(
	"We're on a mission to eliminate loneliness. You can join by contacting " + module.exports.CONTACT_EMAIL
);	

//module.exports.APP_TITLE 


/*
OhMyPerson
Personalities
Personalipedia (Academic, thoughtful, about different people and ideas)
Little
HoldingHands
2x2 (Casual)
FIREWORKS 
Fireworks Dating
Fireworksx2
nopicturedating
ohmyperson
verypersonal
hyperpersonal
mylifeisyourbusiness
u2me
linkingChains - Each is a link.
Fireworks42.com
2xi
2lonelypeople
me2lonely
weare2lonely
*/

/*
"Meet someone to watch fireworks with"
"Let people learn about you without distractions"
*/

/*
Let people know how amazing you are
Start a great relationship
Start a lasting relationship
"Start a Lasting Relationship"
The best way of reliably meeting great people

*/

/*

Project Console: https://console.firebase.google.com/project/dating-globally/overview
Hosting URL: https://dating-globally.firebaseapp.com

*/

/*

Dating for sober intellectuals

*/