import React from 'react';
import './favourites2.css';

export default class Favourites2 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            favourites: props.favourites,
            reloadDashboard: props.reloadDashboard
        }
    }

    componentDidMount(){
        //console.log('Reloading favourites2: ', this.state.favourites);
        //console.log('this.props.favourites; ', this.props.favourites);
        this.calcDisplayData(this.props.favourites);
    }

    componentDidUpdate(){
        //console.log('this.state.favourites: ', this.state.favourites);
        //console.log('this.props.favourites: ', this.props.favourites);
        if(this.state.favourites !== this.props.favourites){
            this.calcDisplayData(this.props.favourites);
            // db.loadFavouritesForUser(this.props.match.params.userId)
			// 	.then(data => {
            //         console.log("Loaded Favourites: ", data);
                    
					
			// 		this.setState({
			// 			favourites: data
			// 		})
			// 		return 1;
			// 	})
        }
    }

    calcDisplayData = (favourites) => {
        //console.log('calculating data to display!');

        var totalRequests = 0, accepted = 0, rejected = 0, acceptanceRate = 0;
        totalRequests = favourites.length;
        accepted = favourites.filter((f) => {return f.status === "accepted"}).length;
        rejected = favourites.filter((f) => { return f.status === "rejected"}).length;
        acceptanceRate = (accepted / totalRequests) * 100;

        this.setState({
            favourites: favourites,
            totalRequests: totalRequests,
            accepted: accepted,
            rejected: rejected,
            acceptanceRate: (isNaN(acceptanceRate) ? 0 : acceptanceRate) + "%" 
        }, ()=>{
            this.props.f_updateFloatingHeaderData(totalRequests, accepted, rejected);
        })
    }


    render(){
        return(
            <div>
                <div id="favourites-stats-container">
                    <div>
                        <span className="favourites-stats-data">
                            {this.state.totalRequests}
                        </span>
                        <span className="favourites-stats-desc">Requests</span>
                    </div>
                    <div>
                        <span className="favourites-stats-data">
                            {this.state.accepted}
                        </span>
                        <span className="favourites-stats-desc">Accepted</span>
                    </div>
                    <div>
                        <span className="favourites-stats-data">
                            {this.state.rejected}
                        </span>
                        <span className="favourites-stats-desc">Rejected</span>
                    </div>
                </div>
            </div>
        );
    }
}
