import React from 'react';
import EditBtn from './editBtn.jsx';
import StarChart from '../Graphs/star';
import PersonalityScoreModal from '../Modal/personalityScoreModal.jsx';
import './personalityChart.css';
import db from '../db.js';

export default class PersonalityChart extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uid: props.uid,
            personalityValues: props.personalityValues,
            personalityDescriptions: this.getDescriptionFromOCEAN(props.personalityValues),
            personalityCode: props.personalityCode,
            showingPersonalityScoreModal: false,
            expanded: props.expanded,
            f_savePersonalityAnswers: props.f_savePersonalityAnswers,
            pleaseFillInAll: false
        }
    }

    componentDidMount(){
        if(this.state.expanded !== this.props.expanded){
            this.setState({
                expanded: this.props.expanded
            })
        }
        if(this.state.personalityValues !== this.props.personalityValues){
            this.setState({
                personalityValues: this.props.personalityValues,
                personalityDescriptions: this.getDescriptionFromOCEAN(this.props.personalityValues)
            });
        }
    }

    componentDidUpdate(){
        if(this.state.personalityValues !== this.props.personalityValues){
            this.setState({
                personalityValues: this.props.personalityValues
            })
        }
    }

    // Bad practice, is calling MainDetails editCallback() method. This Shouldn't be here at all.
    editCallback = () => {
		this.props.fn_showModal();
    }

    getNameFromOCEAN(name){
        switch(name){
            case "O": return "Openness";
            case "C": return "Conscientiousness";
            case "E": return "Extraversion";
            case "A": return "Agreeableness";
            case "N": return "Neuroticism";
            default: return ";"
        }
    }

    getDescriptionFromOCEAN = (personalityValues) => {
        //console.log("making description for: ", personalityValues);
        const descriptions = [];
        Object.keys(personalityValues || {}).forEach((o) => {
            const value = personalityValues[o];
            // Only add in descriptions if less than 2, otherwise it looks ugly
            if(descriptions.length < 2){
                if(value >= 9){
                    descriptions.push({
                        value: value, 
                        desc: this.getNameFromOCEAN(o),
                        w: "HIGH"
                    });
                } else if(value <= 2){
                    descriptions.push({
                        value: value, 
                        desc: this.getNameFromOCEAN(o),
                        w: "LOW"
                    });
                }
            }
            // if(value >= 9){
            //     switch(o){
            //         case "O": descriptions.push("Seeks New Experiences");
            //         case "C": descriptions.push("Organized");
            //         case "E": descriptions.push("Extroverted");
            //         case "A": descriptions.push("Kind");
            //         case "N": descriptions.push("Anxious");
            //     }
            // }
        })
        //TODO just do a simple selection of the lowest and highest and show them.
        return descriptions;
    }

    getOCEANFromScore = (totalQuestionsNumber) => {
        const promises = [];
        const data = document.getElementsByClassName("m-modal-toRead");
        const categorizedAnswers = {O:{total: 0, frequency: 0, totalPoints: 0},C:{total: 0, frequency: 0, totalPoints: 0},E:{total: 0, frequency: 0, totalPoints: 0},A:{total: 0, frequency: 0, totalPoints: 0},N:{total: 0, frequency: 0, totalPoints: 0}};
        const options = {
            "False": 1,
            "Somewhat false": 2,
            "Neither true or false": 3,
            "Somewhat true": 4,
            "True": 5
        }
        //console.log("totalQuestionsNumber: ", totalQuestionsNumber);
        //console.log("data.length: ", data.length);
        if(totalQuestionsNumber !== data.length){
            //console.log("Please fill in all of the answers before proceeding");
            this.setState({
                pleaseFillInAll: true
            }, ()=>{
                window.setTimeout(()=>{
                    this.setState({
                        pleaseFillInAll: false
                    })
                }, 3500);
            })
            return;
        }
        Array.from(data).forEach((answer)=>{
            //console.log(answer);	
            const category = answer.name.split("|")[0];
            if(category.length > 2){
                // Two categories are present here
                var c1 = category.substring(0,1);
                var t1 = category.substring(1,2);
                var c2 = category.substring(2,3);
                var t2 = category.substring(3,4);
                var v1 = options[answer.value], v2 = options[answer.value];
                if(t1 === '-') v1 *= -1;
                if(t2 === '-') v2 *= -1;
                categorizedAnswers[c1].total += v1;
                categorizedAnswers[c1].frequency++;
                categorizedAnswers[c2].total += v2;
                categorizedAnswers[c2].frequency++;
            } else {
                var c = category.substring(0,1);
                var type = category.substring(1,2);
                var value = options[answer.value];
                if(type === '-') value *= -1;
                categorizedAnswers[c].total += value;
                categorizedAnswers[c].frequency++;
            }
        });
        Object.keys(categorizedAnswers).forEach(c => {
            categorizedAnswers[c].totalPoints = categorizedAnswers[c].frequency * 5; // out of a 5 point scale
            categorizedAnswers[c].singlePoint = 10.0 / categorizedAnswers[c].totalPoints; // 10 points max
            categorizedAnswers[c].finalAnswer = categorizedAnswers[c].total * categorizedAnswers[c].singlePoint;
        })
          
        const personalityObj = {
            personalityValues: {
                O: categorizedAnswers['O'].finalAnswer,
                C: categorizedAnswers['C'].finalAnswer,
                E: categorizedAnswers['E'].finalAnswer,
                A: categorizedAnswers['A'].finalAnswer,
                N: categorizedAnswers['N'].finalAnswer
            },
            personalityCode: ""
        }
        promises.push(
            db.savePersonalityAnswer(this.state.uid, personalityObj) // {O: 1, C: 2}...etc OCEAN object
        );
        // Update local MainDetails
        this.props.f_savePersonalityAnswers(personalityObj);

        Promise.all(promises).then(()=>{
            this.setState({
                showingPersonalityScoreModal: false
            })
            return;
        })
    }
    
    getMinimizedInfo = () => {

    }

    f_setPersonalityScore = () => {
        this.setState({
            showingPersonalityScoreModal: true
        })
    }

    f_closePersonalityModal = () => {
        this.setState({
            showingPersonalityScoreModal: false
        })
    }

    render(){
        //console.log("personalityValues: ", this.state.personalityValues);
        return(
            <div className="g-mg-t-10">
                {
                    this.props.ownPage && <EditBtn callback={this.editCallback}/>
                }
                {
                    !Object.keys(this.state.personalityValues || []).length 
                    ? <NoPersonalityValues f_setPersonalityScore={this.f_setPersonalityScore} />
                    : !this.props.expanded
                    ? <PersonalityMinimized {...this.state} />
                    : <PersonalityMaximized {...this.state} f_setPersonalityScore={this.f_setPersonalityScore} /> 
                }
                {
                    this.state.showingPersonalityScoreModal 
                    ? <PersonalityScoreModal 
                            f_savePersonalityAnswers={this.getOCEANFromScore}
                            f_closePersonalityModal={this.f_closePersonalityModal}
                            pleaseFillInAll={this.state.pleaseFillInAll} /> 
                    : ""
                }
            </div>
        );
    }
}

//this.props.personalityCode

function PersonalityMinimized(props){
    return <PersonalityComments personalityDescriptions={props.personalityDescriptions}/>
}

function PersonalityMaximized(props){
    return <div>
        {	props.personalityValues && 
            <div>
                {/* <hr style={{margin:"6px 0"}} /> */}
                <StarChart data={props.personalityValues} personalityCode={props.personalityCode} />
                {/* <PersonalityComments personalityDescriptions={props.personalityDescriptions} /> */}
                <br/>
                <PersonalityDescription />
                <CreateNewPersonalityValues f_setPersonalityScore={props.f_setPersonalityScore}/>
            </div>                
        }
    </div>
}

function PersonalityComments(props){
    return <div>{
        Object.keys(props.personalityDescriptions || []).map((d, index) => (
            <div key={"pd-" + index} 
                className={"pd-comment-text" + (props.personalityDescriptions[d].value >= 9 ?
                    " pd-high "
                    : props.personalityDescriptions[d].value <= 2 ?
                    " pd-low "
                    : "")
                }>
                <span>{props.personalityDescriptions[d].desc}</span>
            </div>
        ))
    }</div>
}

function PersonalityDescription(props){
    return <div>
        <p style={{marginBottom:"10px"}}>This is based on the 
            <a href="https://en.wikipedia.org/wiki/Big_Five_personality_traits" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{color:"black"}}>
                <b> Big Five</b>
            </a> personality profile
        </p>
        <label>Openness - open to new ideas</label>
        <label>Conscientiousness - levels of organization</label>
        <label>Extraversion - willing to associate with others</label>
        <label>Agreeableness - liked by others</label>
        <label>Neuroticism - tendency to negative thinking</label>
        <br/>
        {/* <hr style={{margin:"6px 0"}} /> */}
    </div>
}

function NoPersonalityValues(props) {
    return <div onClick={()=>{props.f_setPersonalityScore()}} className="noPersonality">
        <label className="g-cursor-pointer">Get your personality score</label>
    </div>
}

function CreateNewPersonalityValues(props) {
    return <div onClick={()=>{props.f_setPersonalityScore()}}>
        <label id="personality-createNewScore" className="g-cursor-pointer g-soft-text" style={{fontSize:"13px"}}>Create new score</label>
    </div>
}