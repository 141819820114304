const firebaseConfig = {
  apiKey: "AIzaSyCctvAa7OUGefoS6oxhBl2k8U6gSygyHQ4",
  authDomain: "dating-globally.firebaseapp.com",
  databaseURL: "https://dating-globally.firebaseio.com",
  projectId: "dating-globally",
  storageBucket: "",
  messagingSenderId: "628072168363",
  appId: "1:628072168363:web:26f35ad28669de89"
};

export const debugFirebaseConfig = {
  apiKey: "AIzaSyBj_eRLRlPrTghQovla5BSwRn9w8UIC3Ac",
  authDomain: "ohmyperson-test.firebaseapp.com",
  databaseURL: "https://ohmyperson-test.firebaseio.com",
  projectId: "ohmyperson-test",
  storageBucket: "ohmyperson-test.appspot.com",
  messagingSenderId: "749855351567",
  appId: "1:749855351567:web:345cb624537056bd2b547a"
}

export default firebaseConfig;